import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import BildirimDetay from "../pages/bildirimler/bildirimDetay";
import Popup from "../pages/bildirimler/popup";
import { getList } from "./axiosComp";
import RolsControl, { Hata } from "./rolsControl";

const NavMenu = () => {
  const history = useHistory();

  const [detay, setDetay] = useState();
  const [bData, setbData] = useState([]);

  const [POPData, setPOPData] = useState([]);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const onDetay = (id) => {
    getList("/uybs/uyeBildirimDetay/" + id)
      .then((a) => {
        setDetay(a.data);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "error",
          },
        });

        return;
      });
  };

  const onSubmit = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("rols");
    localStorage.clear();
    history.push("/login");
  };

  useEffect(() => {
    !localStorage.getItem("user") && history.push("/login");
  }, [localStorage.getItem("rols")]);

  useEffect(() => {
    getList("uybs/uyeBildirimler")
      .then((a) => {
        console.log("--aa--");
        console.log(a.data);
        console.log("--aa--");
        if (a.data.length > 0) {
          var _b = a.data.filter((a) => a.tip === 3);
          var _p = a.data.filter((a) => a.tip === 2);

          console.log(_b);
          console.log(_p);

          setbData(_b);
          setPOPData(_p);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <header id="page-topbar">
      <Popup detay={POPData} setDetay={setPOPData}></Popup>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="images/logo-sm.png" alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img src="images/logo-dark.png" alt="" height="70" />
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="images/logo-sm.png" alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img src="images/logo-light.png" alt="" height="70" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm me-2 font-size-24 d-lg-none header-item waves-effect waves-light"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <i className="mdi mdi-menu"></i>
          </button>
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ion ion-md-notifications"></i>
              <span className="badge bg-danger rounded-pill">
                {bData && bData.length}
              </span>
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 left-banner-spr"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="m-0 font-size-16">
                      Bekleyen Bildirim ({bData && bData.length})
                    </h5>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{ maxHeight: "230px" }}>
                {bData &&
                  bData.map((a) => (
                    <Link
                      to={"#"}
                      className="text-reset notification-item"
                      onClick={() => onDetay(a.id)}
                    >
                      <div className="media d-flex">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-success rounded-circle font-size-16">
                            <i className="mdi mdi-cart-outline"></i>
                          </span>
                        </div>

                        <div className="flex-1">
                          <h6 className="mt-0 font-size-15 mb-1">{a.baslik}</h6>

                          {/* <div className="font-size-12 text-muted">
                            <p className="mb-1">
                            {a.baslik}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>

              <div className="p-2 border-top">
                <Link
                  className="btn btn-sm btn-link font-size-14 w-100 text-center"
                  to="bildirimler"
                >
                  Tümünü Gör
                </Link>
              </div>
            </div>
          </div>
          {/* <Dropdown
            className="d-inline-block"
            autoClose="outside"
            align={{ lg: "start" }}
          >
            <Dropdown.Toggle
              variant="none"
              className="btn header-item noti-icon left-bar-toggle waves-effect"
            >
              {" "}
              <i className="mdi mdi-spin mdi-cog"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item tag={Link} to="Profilim" href="Profilim">
                {" "}
                <i className="dripicons-user font-size-16 align-middle me-2"></i>
                Profilim
              </Dropdown.Item>
              <Dropdown.Item
                tag={Link}
                className="dropdown-item d-block"
                href="SifreGuncelle"
              >
                {" "}
                <i className="dripicons-gear font-size-16 align-middle me-2"></i>{" "}
                Şifre Güncelle
              </Dropdown.Item>
              <Dropdown.Item tag={Link} to="#" onClick={() => onSubmit()}>
                {" "}
                <i className="dripicons-exit font-size-16 align-middle me-2"></i>
                Çıkış
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon left-bar-toggle waves-effect"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="mdi mdi-spin mdi-cog"></i>
            </button>

            <div
              className="dropdown-menu  left-banner-spr"
              aria-labelledby="dropdownMenuButton"
            >
              <Link className="dropdown-item" to="Profilim">
                <i className="dripicons-user font-size-16 align-middle me-2"></i>
                Profilim
              </Link>
              <Link className="dropdown-item d-block" to="SifreGuncelle">
                <i className="dripicons-gear font-size-16 align-middle me-2"></i>
                Şifre Güncelle
              </Link>

              <div className="dropdown-divider"></div>

              <Link className="dropdown-item" to="#" onClick={() => onSubmit()}>
                <i className="dripicons-exit font-size-16 align-middle me-2"></i>
                Çıkış
              </Link>
            </div>
          </div>{" "}
          {/**/}
        </div>
      </div>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <i className="dripicons-device-desktop me-2"></i> Ana Ekran
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to={"doktorlarlist"}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="dripicons-suitcase me-2"></i> Doktorlar
                  </Link>
                </li>

                {RolsControl("randevuPuan") && (
                  <>
                    {" "}
                    <li className="nav-item dropdown">
                      <div className="dropdown">
                        <a className="nav-link dropdown-toggle arrow-none"
                          href="#"
                          id="topnav-rand"
                          role="button"
                        >
                          Randevu Listeleri <div className="arrow-down"></div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="topnav-rand"
                        >
                          <Link className="dropdown-item" to="/sira-randevu">
                            <i className="fa fa-table" aria-hidden="true"></i> Randevu Sırası
                          </Link>
                          <Link className="dropdown-item" to="/list-randevu">
                            <i className="fa fa-list"></i> Randevular
                          </Link>
                          <Link className="dropdown-item" to="/randevu-Dokumu">
                          <i class="fa fa-print" aria-hidden="true"></i> Randevu Dökümü
                          </Link>
                          <Link className="dropdown-item" to="/randevu-for-excell">
                          <i class="fa fa-file-excel" aria-hidden="true"></i> Randevu Dökümünü İçe Aktar(Excel)
                          </Link>
                        </div>
                      </div>{" "}
                    </li>
                  </>
                )}

                <li className="nav-item dropdown">
                  <a 
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-layout"
                    role="button"
                  >
                    <i className="dripicons-inbox me-2"></i>Tanımlamalar
                    <div className="arrow-down"></div>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-layout"
                  >
                    <div className="dropdown">
                      <a
                        className="dropdown-item dropdown-toggle arrow-none"
                        href="#"
                        id="topnav-email"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Genel Tanımlamar <div className="arrow-down"></div>
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-email"
                      >
                        <Link
                          to={"/tanimlar-genel-terapi"}
                          className="dropdown-item"
                        >
                          Terapiler
                        </Link>
                        <Link
                          to={"/tanimlar-genel-testler"}
                          className="dropdown-item"
                        >
                          Testler
                        </Link>
                        <Link
                          to={"/tanimlar-genel-ilgialanlari"}
                          className="dropdown-item"
                        >
                          İlgi Alanları
                        </Link>
                      </div>
                    </div>

                    {RolsControl("ozelTanim") && (
                      <>
                        {" "}
                        <div className="dropdown">
                          <a
                            className="dropdown-item dropdown-toggle arrow-none"
                            href="#"
                            id="topnav-email"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Özel Tanımlamalar <div className="arrow-down"></div>
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="topnav-email"
                          >
                            <Link
                              to="/tanimlar-ozel-klinik"
                              className="dropdown-item"
                            >
                              Klinik
                            </Link>
                            <Link
                              to="/tanimlar-ozel-gurup"
                              className="dropdown-item"
                            >
                              Bölümler
                            </Link>
                            <Link
                              to="/tanimlar-ozel-drtip"
                              className="dropdown-item"
                            >
                              Doktor Tip
                            </Link>
                            <Link
                              to="/tanimlar-ozel-bakim"
                              className="dropdown-item"
                            >
                              Randevu Türü
                            </Link>
                            <Link
                              to="/tanimlar-ozel-bolum"
                              className="dropdown-item"
                            >
                              Doktor Meddata Bölümler
                            </Link>{" "}
                            <Link
                              to="/tanimlar-yaslar"
                              className="dropdown-item"
                            >
                              Yaş Tanımları
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </li>

                {RolsControl("mesajlar") && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-pages"
                      role="button"
                    >
                      <i className="dripicons-copy mdi-drop me-2"></i>Mesajlar
                      ve Sayfalar
                      <div className="arrow-down"></div>
                    </a>
                    <div
                      className="dropdown-menu "
                      aria-labelledby="topnav-pages"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div>
                            <Link to="yenimesaj" className="dropdown-item">
                              + Yeni
                            </Link>
                            <Link to="mesajlist" className="dropdown-item">
                              Tüm Mesajlar ve Sayfalar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                {RolsControl("uyegiris") && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="uyeler">
                        <i className="fas fa-user-cog"></i> Üyeler
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="roller">
                        <i className="fas fa-list"></i> Roller
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="page-title-box">
        <div className="container-fluid">
          <div className="page-title-content">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4>Randevu Performans Takip & Uzman Bilgi Sistemi </h4>
              </div>

              {/* <div className="col-sm-6">
                <div className="float-end d-none d-md-block">
                  <form className="app-search ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <span className="fa fa-search"></span>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>{" "}
      <BildirimDetay detay={detay} setDetay={setDetay}></BildirimDetay>
    </header>
  );
};

export default NavMenu;
