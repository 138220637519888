import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import Insert from "../../../components/tanimlar/gurup/insert";
import Update from "../../../components/tanimlar/gurup/update";
import Delete from "../../../components/tanimlar/gurup/delete";
import { getList } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { Hata } from "../../../components/rolsControl";

const Gurup = () => {
  const [refresh, setrefresh] = useState([]);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [uid, setuid] = useState();
  const [did, setdid] = useState();
  const [dname, setdname] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
    getList("/ubs/gurupList")
      .then((a) => {
        setData(a.data);
        setLoading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setData([]);
        setLoading(false);
        return;
      });
  }, []);

  useEffect(() => {
    if (refresh.length > 0) {
      setData(refresh);
      setLoading(false);
    }
  }, [refresh]);

  const handleShow = () => setShow(true);

  const handleuShow = (id) => {
    setuid(id);
  };

  const handledShow = (id, name) => {
    setdname(name);
    setdid(id);
  };

  return (
    <div className="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Insert
        show={show}
        setShow={setShow}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Update
        uid={uid}
        setuid={setuid}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Delete
        did={did}
        setdid={setdid}
        name={dname}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <div className="card">
        <div className="card-body">
          <h1>Bölüm ayarları </h1>
          <p>Dr/Psk Bölüm ayarları</p>

          <div className="text-end">
            <Button onClick={handleShow} className="btn btn-info">
              <i className="fas fa-plus"></i> Bölüm Oluştur
            </Button>
          </div>

          {loading && <SnipperLoading />}

          {!loading && (
            <>
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Gurup adı</th>
                      <th>Yaş </th>
                      <th>Açıklama </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((a, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{a.grupAd}</td>
                          <td>
                            {a.altY === 0 ? "Limitsiz" : a.altY} -
                            {a.ustY === 0 ? "Limitsiz" : a.ustY}
                          </td>
                          <td>{a.aciklama}</td>
                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => handleuShow(a.grId)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>{" "}
                            <button
                              className="btn btn-danger  btn-sm"
                              onClick={() => handledShow(a.grId, a.grupAd)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gurup;
