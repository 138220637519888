import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, dell } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { Hata } from "../../rolsControl";

const DoktorBakimsil = ({ show, setshow, setrefresh }) => {
  const [buttonOk, setbuttonOk] = useState(false);
  const [model, setmodel] = useState(false);

  const [detay, setdetay] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:""  },
  });

  useEffect(() => {
    console.log(show);
    if (show) {
      getList("/drbs/doktorBakimUcretiDetails/" + show)
        .then((a) => {
          console.log(a.data);

          setdetay(a.data);
          setmodel(true);
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });

          setmodel(false);
          setdetay();
          return;
        });
    }
  }, [show]);

  const handleClose = () => {
    setshow();
    setmodel(false);
    setdetay();
  };

  const onSubmit = () => {
    if (show) {
      console.log(show);
      setbuttonOk(true);

      var form_data = new FormData();
      
      dell("/drbs/doktorBakimUcretiSil/" + show)
        .then((a) => {
          setrefresh(a.data);
          console.log(a.data);
          setshow();
          setbuttonOk(false);
          setmodel(false);
          setdetay();
          seterrorS({
            uyari: { error: true, message: "Doktor Tip bilgisi silindi." ,tip: "success"  },
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);

          setshow();
          setbuttonOk(false);
          setmodel(false);
          setdetay();
          return;
        });
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {show && detay && (
        <Modal
          size="sm"
          show={model}
          onHide={() => handleClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Ücretlendirme Sil
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {detay.bakim.bakimAd} {detay.bakimDetay.ucret} ₺' ücreti silinsin
            mi?
          </Modal.Body>{" "}
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="danger" onClick={() => onSubmit()}>
                  Sil
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default DoktorBakimsil;
