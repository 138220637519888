import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dell, getList } from "../axiosComp";
import Hatamodal from "../hatamodal";
import SnipperLoading from "../snipperLoading";

const Filtreler = ({ show, setshow, setSecfiltre }) => {
  const [data, setdata] = useState();
  const [sil, setsil] = useState();
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("/drbs/hizliFiltreler")
      .then((a) => {
        setdata(a.data);
      })
      .catch(function (error) {
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
      });
  }, [show]);

  const onSil = () => {
    if (sil) {
      dell("/drbs/hizliFiltreKaldir/" + sil)
        .then((a) => {
          setsil();
          setbuttonOk(false);
          setdata(a.data);
          seterrorS({
            uyari: {
              error: true,
              message: " Seçilen filtre silindi.",
              tip: "success",
            },
          });
        })
        .catch(function (error) {
          setbuttonOk(false);
          setsil();
          seterrorS({
            uyari: {
              error: true,
              message: error.response.data.message,
              tip: "danger",
            },
          });
        });
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal
        show={show}
        onHide={() => setshow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Hızlı Filtreler
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!data && <SnipperLoading />}
          {data && (
            <>
              <div className="table-responsive">
                <table className="table mt-4 mb-0 table-centered table-nowrap ">
                  <tbody>
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={3}> Mevcut filtreniz bulunmuyor. </td>
                      </tr>
                    )}
                    {data.length > 0 &&
                      data.map((item) => (
                        <tr>
                          <td>
                            <Link to={"#"}>{item.baslik}</Link>
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setSecfiltre(item.dizi);
                                setshow(false);
                              }}
                            >
                              Getir
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => setsil(item.id)}
                            >
                              <i class="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="secondary" onClick={() => setshow(false)}>
              Kapat
            </Button>
          </>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        show={sil ? true : false}
        onHide={() => setsil()}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Filtre Sil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Filtre silinsin mi?</Modal.Body>{" "}
        <Modal.Footer>
          {!buttonOk && (
            <>
              <Button variant="secondary" onClick={() => setsil()}>
                Vazgeç
              </Button>
              <Button variant="danger" onClick={() => onSil()}>
                Sil
              </Button>
            </>
          )}
          {buttonOk && <SnipperLoading />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Filtreler;
