import React, { useState, useEffect } from "react";
import { getList, post } from "../../components/axiosComp";
import SnipperLoading from "../../components/snipperLoading";
import RolsControl, { Hata } from "../../components/rolsControl";
import Hatamodal from "../../components/hatamodal";
import { Link } from "react-router-dom";
import {
  randevuMudehale,
  RandevuMudehaleSelect,
} from "../../components/randevuMudehale";
import DateTimePicker from "react-datetime-picker";

const RandevuSirasi = () => {
  function oneDays(numOfMonths, date = new Date()) {
    date.setDate(numOfMonths);
    return date;
  }

  const [buttonOk, setbuttonOk] = useState(false);

  const [data, setdata] = useState([]);
  const [dtBakim, setdtBakim] = useState([]);
  const [icerik, seticerik] = useState();

  const [loading, setloading] = useState(true);
  const [tarihAraligi, setTarihAraligi] = useState({
    bas: oneDays(1),
    bit: new Date(),
  });

  const [varsayilanKlinik, setvarsayilanKlinik] = useState({
    klinik: 0,
  });

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("/rand/icerik")
      .then((a) => {
        seticerik(a.data);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
        return;
      });
  }, []);

  useEffect(() => {
    setloading(true);
    if (varsayilanKlinik.klinik === 0) {
      setdata([]);
      setloading(false);
    } else {
      listRefresh();
    }
  }, [varsayilanKlinik]);

  // useEffect(() => {
  //   listRefresh();
  // }, [tarihAraligi]);

  function listRefresh() {
    console.log(tarihAraligi);

    getList("/rand/dokum/" + varsayilanKlinik.klinik, tarihAraligi)
      .then((a) => {
        setdata(a.data.liste);
        setdtBakim(a.data.bakim);
        setloading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setdata([]);
        setloading(false);
        return;
      });
  }
  // const  = (event) => {
  //   console.log(event);
  // };

  //   const handleChange = (event) => {
  //     setsekmeler({
  //       ...sekmeler,
  //       [event.target.name]: event.target.checked,
  //     });
  //   };

  const logsay = (grId) => {
    if (data) {
      var filtre = data.filter((a) => a.gruplar.some((b) => b.grId === grId));

      return filtre;
    } else return [];
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {/* className="container-fluid" */}
      <div>
        <div className={"card"}>
          <div className="card-body">
            <div className="row">
              {" "}
              <div className="col-md-12">
                <h4 className="text-center">Randevu Dökümü</h4>
              </div>
              <div className="col-md-12">
                {" "}
                <div className="form-group">
                  {/* <select
                    className="form-control"
                    name="klinik"
                    onChange={(event) =>
                      setvarsayilanKlinik({
                        ...varsayilanKlinik,
                        [event.target.name]: event.target.value,
                      })
                    }
                    defaultValue={varsayilanKlinik.klinik}
                  >
                    <option value={0}>Seçiniz</option>
                    {icerik &&
                      icerik.kliniks.map((a, index) => (
                        <option value={a.klid} key={index} className={index}>
                          {a.sehir} - {a.klinik.toUpperCase()}
                        </option>
                      ))}
                  </select> */}

                  <div className="row">
                    {icerik &&
                      icerik.kliniks.map((a, index) => (
                        <div
                          class={
                            "col-md-" +
                            (icerik.kliniks.length % 4 === 0
                              ? "3"
                              : icerik.kliniks.length % 3 === 0
                              ? "4"
                              : "3")
                          }
                        >
                          <Link
                            to="#"
                            onClick={() =>
                              setvarsayilanKlinik({
                                klinik: a.klid,
                              })
                            }
                            className={
                              varsayilanKlinik.klinik === a.klid && "secilenKat"
                            }
                          >
                            <h6 className="text-center klinikFiltrele">
                              {a.sehir.toUpperCase()} - {a.klinik.toUpperCase()}
                            </h6>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mw100">
          {icerik && varsayilanKlinik.klinik !== 0 && (
            <>
              <div class="card">
                <div class="card-body">
                  <div className="col-md-12">
                    <div class="mb3 row p-3 row ">
                      <div class="col-md-4 row">
                        <div className="col-md-6">
                          <label class="form-label ">
                            Başlangıç Tarihi <span className="req">*</span>{" "}
                          </label>
                        </div>

                        <div className="col-md-6">
                          <DateTimePicker
                            format="dd.MM.yyyy"
                            name="basTarihi"
                            class="form-control"
                            disableClock={true}
                            value={tarihAraligi.bas}
                            onChange={(date) => {
                              setTarihAraligi({
                                ...tarihAraligi,
                                bas: date,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div class="col-md-4 row">
                        <div className="col-md-6">
                          <label class="form-label ">
                            Bitiş Tarihi <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <DateTimePicker
                            format="dd.MM.yyyy"
                            minDate={tarihAraligi.bas}
                            name="bitisTarihi"
                            class="form-control"
                            disableClock={true}
                            value={tarihAraligi.bit}
                            onChange={(date) => {
                              setTarihAraligi({
                                ...tarihAraligi,
                                bit: date,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => listRefresh()}
                        >
                          Filtrele
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {icerik.grups.map((ic) => (
                <div className="col-lg-12" key={ic.grId}>
                  {varsayilanKlinik.klinik !== 0 ? (
                    <>
                      <div className={"card "}>
                        <div className="card-body">
                          <h6>{ic.grupAd}</h6>
                          <div className="table-responsive b-0 fixed-solution randsiralama">
                            {data && (
                              <DetayListe
                                icerik={icerik}
                                doktorList={logsay(ic.grId)}
                                dlist={dtBakim}
                                setdata={setdata}
                                grupad={ic.grupAd}
                                icgrId={ic.grId}
                              ></DetayListe>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-lg-12">
                      <div className={"card "}>
                        <div className="card-body">
                          <h6>{ic.grupAd}</h6>
                          {!loading &&
                            data.length === 0 &&
                            varsayilanKlinik.klinik !== 0 && (
                              <h6 className="text-center">
                                Kayıt Bulunmuyor...
                              </h6>
                            )}
                          {!loading &&
                            data.length === 0 &&
                            varsayilanKlinik.klinik === 0 && (
                              <h6 className="text-center">Klinik seçilmedi.</h6>
                            )}
                          {loading && <SnipperLoading></SnipperLoading>}
                        </div>{" "}
                      </div>{" "}
                    </div>
                  )}
                </div>
              ))}{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const DetayListe = ({ icerik, doktorList, dlist, setdata, grupad, icgrId }) => {
  const [puan, setpuan] = useState(0);

  const [randevuChance, setRandevuChance] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    if (randevuChance) {
      // [doktorList.filter((a) => a.drId === randevuChance.dr)[0].rand.gelenRandevu]:1888,
      // { name: name, rand: text, dr: dr }

      var filtre = doktorList.filter((a) => a.drId === randevuChance.dr)[0];

      console.log(filtre);
      console.log(randevuChance);

      if (filtre.rand === null) {
        filtre.rand = {
          gelenRandevu: 0,
          kayitliRandevu: 0,
          mudehale: 0,
          puan: 0,
          randevu: 0,
          aciklama: "",
        };
      }

      console.log("vs");
      if (randevuChance.name === "gelenRandevu") {
        filtre.rand.gelenRandevu = parseInt(randevuChance.rand);
      } else if (randevuChance.name === "kayitliRandevu") {
        filtre.rand.kayitliRandevu = parseInt(randevuChance.rand);
      } else if (randevuChance.name === "mudehale") {
        filtre.rand.mudehale = parseInt(randevuChance.rand);
      } else if (randevuChance.name === "puan") {
        filtre.rand.puan = parseInt(randevuChance.rand);
      } else if (randevuChance.name === "randevu") {
        filtre.rand.randevu = parseInt(randevuChance.rand);
      } else if (randevuChance.name === "aciklama") {
        filtre.rand.aciklama = randevuChance.rand;
      }
      console.log(filtre);

      const newdata = {
        ...doktorList,
        [doktorList.filter((a) => a.drId === randevuChance.dr)[0]]: filtre,
      };

      var form_data = new FormData();

      form_data.append("name", randevuChance.name);
      form_data.append("icerik", randevuChance.rand);

      post("/drbs/drsirakaydet/" + randevuChance.dr, form_data)
        .then((a) => {})
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: "Hata oluştu.Kontrol edilemedi.",
            },
          });

          return;
        });

      setRandevuChance();

      if (doktorList) {
        puaniHesapla();
      }
    }
  }, [randevuChance]);

  useEffect(() => {
    if (doktorList) {
      puaniHesapla();
    }
  }, [doktorList]);

  function puaniHesapla() {
    var topl = 0;
    doktorList.forEach((element) => {
      if (element.rand) {
        topl = element.rand.puan + topl;
      }
    });

    setpuan(topl);
  }

  const bakimlariTopla = (drid) => {
    if (dlist) {
      var toplamR = dlist
        .filter((a) => a.drId === drid && a.grId === icgrId)
        .reduce((acc, curr) => {
          return acc + curr.yeni + curr.isme;
        }, 0);

      return toplamR;
    }
  };

  const bkDetayDondur = (bid, drid) => {
    if (dlist) {
      var filtreKayit = dlist.filter(
        (a) => a.bId === bid && a.drId === drid && a.grId === icgrId
      );

      if (filtreKayit && filtreKayit.length > 0) {
        return (
          <>
            <td>{filtreKayit[0].yeni}</td>
            <td>{filtreKayit[0].isme}</td>
          </>
        );
      } else {
        return (
          <>
            <td className="pasifsira"></td>
            <td className="pasifsira"></td>
          </>
        );
      }
    } else {
      return (
        <>
          <td className="pasifsira"></td>
          <td className="pasifsira"></td>
        </>
      );
    }
  };

  function yuzdeDon(deg1, deg2) {
    return ((deg2 * 100) / deg1).toFixed(1) + "%";
  }

  function doluluk(gpuan) {
    let deger = gpuan / (puan / doktorList.length);

    if (deger > 1.1) {
      return "80.0%";
    } else if (deger > 1) {
      return "75.0%";
    } else if (deger > 0.9) {
      return "70.0%";
    } else if (deger > 0.8) {
      return "65.0%";
    } else if (deger > 0.7) {
      return "60.0%";
    } else if (deger > 0.6) {
      return "55.0%";
    } else {
      return "50.0%";
    }
  }

  function farkyuzde(gpuan, deg1, deg2) {
    let deger = gpuan / (puan / doktorList.length);
    let y1 = 0;
    if (deger > 1.1) {
      y1 = 80;
    } else if (deger > 1) {
      y1 = 75;
    } else if (deger > 0.9) {
      y1 = 70;
    } else if (deger > 0.8) {
      y1 = 65;
    } else if (deger > 0.7) {
      y1 = 60;
    } else if (deger > 0.6) {
      y1 = 55;
    } else if (deger > 0.5) {
      y1 = 50;
    }

    return (y1 - (deg2 * 100) / deg1).toFixed(1) + "%";
  }

  function grkrDoluluk(rand, gpuan) {
    let deger = gpuan / (puan / doktorList.length);
    let y1 = 0;

    if (deger > 1.1) {
      y1 = 80;
    } else if (deger > 1) {
      y1 = 75;
    } else if (deger > 0.9) {
      y1 = 70;
    } else if (deger > 0.8) {
      y1 = 65;
    } else if (deger > 0.7) {
      y1 = 60;
    } else if (deger > 0.6) {
      y1 = 55;
    } else if (deger > 0.5) {
      y1 = 50;
    } else {
      y1 = 50;
    }

    return (
      (
        ((rand.kayitliRandevu + rand.gelenRandevu) / (rand.randevu * y1)) *
        10000
      ).toFixed(2) + "%"
    );
  }

  function yuzdeoranı(rand, gpuan) {
    let deger = gpuan / (puan / doktorList.length);
    let y1 = 0;
    if (deger > 1.1) {
      y1 = 0.8;
    } else if (deger > 1) {
      y1 = 0.75;
    } else if (deger > 0.9) {
      y1 = 0.7;
    } else if (deger > 0.8) {
      y1 = 0.65;
    } else if (deger > 0.7) {
      y1 = 0.6;
    } else if (deger > 0.6) {
      y1 = 0.55;
    } else if (deger > 0.5) {
      y1 = 0.5;
    } else {
      y1 = 0;
    }

    return (-rand.randevu * y1 + rand.gelenRandevu).toFixed();
  }

  function notF(gpuan) {
    let deger = gpuan / (puan / doktorList.length);

    if (deger >= 1.1) {
      return (
        <span className="badge bg-success font12  text-white">
          DOLU TUTULACAK
        </span>
      );
    } else if (deger >= 0.9) {
      return (
        <span className="badge bg-warning font12 text-white">
          ÜSTÜNE ÇIKABİLİR
        </span>
      );
    } else if (deger < 0.7) {
      return (
        <span className="badge bg-danger font12 text-white">
          FAZLASI VERİLMESİN
        </span>
      );
    } else {
      return <span>- </span>;
    }
  }

  return (
    <>
      {doktorList && doktorList.length > 0 ? (
        <>
          <table className="table table-striped table-bordered ">
            <tbody>
              <tr className="siralamah1 table-success">
                <td></td>
                <td
                  colSpan={
                    icerik && icerik.bkmList && icerik.bkmList.length * 2 + 2
                  }
                >
                  {grupad}
                </td>
                <td>GEREKLİ</td>
                <td>GR+KR</td>
                <td>Top.Slot</td>
                <td colSpan={11}> </td>
                {/* <td> Toplam</td>
  <td> Yeni</td>
  <td> Yeni</td> */}
              </tr>
              <tr className="siralamah2  table-warning">
                <td>
                  <span>{(puan / doktorList.length).toFixed()}</span>
                </td>
                <td> Puan</td>
                <td>Doktor </td>

                {icerik &&
                  icerik.bkmList &&
                  icerik.bkmList.map((bkm, index) => (
                    <>
                      <td key={bkm.bId}>{bkm.bakimAd}</td>
                      <td>İsmine</td>
                    </>
                  ))}

                <td>Top. </td>
                <td>DOLULUK</td>
                <td> FARK YÜZDE</td>
                <td> Doluluk </td>
                <td>Tüm.Doluluk</td>
                <td> Not</td>
                <td> Yüzde Oranı</td>
                <td> Randevu</td>
                <td>Kayıtlı Randevu </td>
                <td>Gelen Randevu </td>
                <td>Gereken </td>
                <td>K SÜTUNA GÖRE</td>
                <td>MANULE MÜDAHALE</td>
                <td>AÇIKLAMA </td>
                {/* <td> Slot</td>
  <td> Hasta </td>
  <td>Oran</td> */}
              </tr>

              {doktorList && doktorList.length > 0 && (
                <>
                  {doktorList.map((item, index) => (
                    <tr key={index}>
                      <td>{icerik.grId}</td>
                      <td>
                        {
                          <RandevudetaySek
                            icerik={
                              item.rand
                                ? item.rand.puan
                                  ? item.rand.puan
                                  : 0
                                : 0
                            }
                            name={"puan"}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }
                      </td>
                      <td>
                        <Link to={"doktor-detay-" + item.drId}>
                          {item.tip + " " + item.adSoyad}
                        </Link>
                      </td>

                      {icerik &&
                        icerik.bkmList &&
                        icerik.bkmList.map((bkm, index2) => (
                          <>{bkDetayDondur(bkm.bId, item.drId)}</>
                        ))}

                      <td>{bakimlariTopla(item.drId)}</td>

                      <td>
                        {item.rand &&
                          doluluk(item.rand.puan ? item.rand.puan : 0)}
                      </td>
                      <td>
                        {item.rand &&
                          farkyuzde(
                            item.rand.puan,
                            item.rand.randevu ? item.rand.randevu : 0,
                            (item.rand.gelenRandevu
                              ? item.rand.gelenRandevu
                              : 0) +
                              (item.rand.kayitliRandevu
                                ? item.rand.kayitliRandevu
                                : 0)
                          )}
                      </td>
                      <td>
                        {item.rand &&
                          grkrDoluluk(
                            item.rand,
                            item.rand.puan ? item.rand.puan : 0
                          )}
                      </td>
                      <td>
                        {item.rand &&
                          yuzdeDon(
                            item.rand.randevu,
                            item.rand.gelenRandevu + item.rand.kayitliRandevu
                          )}
                      </td>
                      <td>
                        {item.rand && notF(item.rand.puan ? item.rand.puan : 0)}
                      </td>
                      <td>
                        {item.rand &&
                          yuzdeoranı(
                            item.rand,
                            item.rand.puan ? item.rand.puan : 0
                          )}
                      </td>
                      <td>
                        {" "}
                        {
                          <RandevudetaySek
                            icerik={item.rand ? item.rand.randevu : 0}
                            name={"randevu"}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }
                      </td>
                      <td>
                        {
                          <RandevudetaySek
                            icerik={
                              item.rand
                                ? item.rand.kayitliRandevu
                                  ? item.rand.kayitliRandevu
                                  : 0
                                : 0
                            }
                            name={"kayitliRandevu"}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }
                      </td>
                      <td>
                        {
                          <RandevudetaySek
                            icerik={
                              item.rand
                                ? item.rand.gelenRandevu
                                  ? item.rand.gelenRandevu
                                  : 0
                                : 0
                            }
                            name={"gelenRandevu"}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }
                      </td>
                      <td>0</td>
                      <td>
                        {item.rand ? randevuMudehale(item.rand.mudehale) : ""}
                      </td>
                      <td>
                        {
                          <ManuelMudehaleSek
                            icerik={item.rand ? item.rand.mudehale : 0}
                            aciklama={item.rand && item.rand.aciklama}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }{" "}
                      </td>

                      <td>
                        {
                          <RandevudetaySek
                            icerik={
                              item.rand
                                ? item.rand.aciklama
                                  ? item.rand.aciklama
                                  : "-"
                                : "-"
                            }
                            name={"aciklama"}
                            setrc={setRandevuChance}
                            dr={item.drId}
                          />
                        }
                      </td>
                      {/* <td> Slot</td>
      <td> Hasta </td>
      <td>Oran</td> */}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <tfoot>
              <tr className="siralamah2">
                <td> </td>
                <td>
                  <span>{puan}</span>
                </td>
                <td> </td>
                <td
                  colSpan={
                    icerik && icerik.bkmList && icerik.bkmList.length * 2 + 2
                  }
                >
                  {" "}
                </td>

                <td colSpan={13}> </td>
                {/* <td> Slot</td>
  <td> Hasta </td>
  <td>Oran</td> */}
              </tr>
            </tfoot>
          </table>
        </>
      ) : (
        <h5 className="text-center">...</h5>
      )}
    </>
  );
};

const RandevudetaySek = ({ icerik, name, setrc, dr }) => {
  const [durum, setdurum] = useState(true);
  const [text, settext] = useState(0);
  useEffect(() => {
    settext(icerik);
  }, [icerik]);

  const kaydetvekapat = () => {
    setrc({ name: name, rand: text, dr: dr });
    setdurum(true);
    return false;
  };

  return (
    <>
      {durum ? (
        <Link to={"#"} onClick={() => setdurum(false)}>
          {icerik}
        </Link>
      ) : (
        <div className="siratext">
          <input
            type="text"
            onChange={(e) => settext(e.target.value)}
            value={text}
          ></input>
          <br />
          <Link to={"#"} onClick={() => kaydetvekapat()}>
            <i class="fa fa-check  check"></i>
          </Link>
          <Link
            to={"#"}
            onClick={() => {
              settext(icerik);
              setdurum(true);
            }}
          >
            <i class="fa fa-times times"></i>
          </Link>
        </div>
      )}
    </>
  );
};

const ManuelMudehaleSek = ({ icerik, setrc, dr }) => {
  const [durum, setdurum] = useState(true);
  const [text, settext] = useState(0);
  useEffect(() => {
    settext(icerik);
  }, [icerik]);

  const kaydetvekapat = () => {
    setrc({ name: "mudehale", rand: text, dr: dr });
    setdurum(true);
    return false;
  };

  return (
    <>
      {durum ? (
        <Link to={"#"} onClick={() => setdurum(false)}>
          {icerik}
        </Link>
      ) : (
        <div className="siratext">
          <RandevuMudehaleSelect
            name={"mudehale"}
            value={text}
            setvalue={(e) => settext(e.target.value)}
          />

          <Link to={"#"} onClick={() => kaydetvekapat()}>
            <i class="fa fa-check  check"></i>
          </Link>
          <Link
            to={"#"}
            onClick={() => {
              settext(icerik);
              setdurum(true);
            }}
          >
            <i class="fa fa-times times"></i>
          </Link>
        </div>
      )}
    </>
  );
};

export default RandevuSirasi;
