import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";
import KlinikServisler from "./klinikServisler";

const Insert = ({ show, setShow, setrefresh, setLoadingUst }) => {
  const handleClose = () => setShow(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [loading, setLoading] = useState(true);
  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getList("/ubs/iller").then((a) => {
      setData(a.data);
      setLoading(false);
    });
  }, []);

  const [formValue, setformValue] = React.useState({
    adres: "",
    il: 6,
    email: "",
    tel: "",
    klinik: "",
    durum: true,
    mdid: '',
    services: 0,
  });

  const onSubmit = () => {
    if (formValue.klinik.length < 3) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      put("/ubs/klinikAdd", form_data)
        .then((a) => {
          setrefresh(a.data);
          setShow(false);
          setbuttonOk(false);
          seterrorS({
            uyari: {
              error: true,
              message: formValue.klinik + " eklendi",
              tip: "success",
            },
          });

          setformValue({
            adres: "",
            il: 6,
            email: "",
            tel: "",
            klinik: "",
            durum: true,
            mdid: null,
            services: 0,
          });
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
    console.log(formValue);
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Klinik Oluştur</Modal.Title>
        </Modal.Header>
        {loading && <SnipperLoading />}

        {!loading && (
          <>
            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <KlinikServisler
                  handleChange={handleChange}
                  defaultValue={formValue.services}
                ></KlinikServisler>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Klinik Id</label>
                  <div class="col-sm-10">
                    <input
                      name="mdid"
                      type="text"
                      class="form-control"
                      value={formValue.mdid}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Klinik <span className="req">*</span>{" "}
                  </label>
                  <div class="col-sm-10">
                    <input
                      name="klinik"
                      type="text"
                      required
                      class="form-control"
                      data-parsley-minlength="3"
                      value={formValue.klinik}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Tel</label>
                  <div class="col-sm-10">
                    <input
                      name="tel"
                      type="text"
                      class="form-control"
                      value={formValue.tel}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Email</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="email"
                      class="form-control"
                      value={formValue.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Şehir</label>
                  <div class="col-sm-10">
                    <select
                      className="form-control"
                      name="il"
                      onChange={handleChange}
                      defaultValue={formValue.il}
                    >
                      {data &&
                        data.map((a, index) => (
                          <option
                            value={a.kod}
                            key={index}
                            className={index + "dd"}
                          >
                            {a.sehir}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Adres</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="adres"
                      class="form-control"
                      value={formValue.adres}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                  <Button variant="success" onClick={() => onSubmit()}>
                    Kaydet
                  </Button>
                </>
              )}
              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
        )}
      </Modal>

      <SweetAlert
        showConfirm={false}
        showCancel={true}
        danger
        show={smShow}
        title="Uyarı"
        onCancel={() => setSmShow(false)}
      >
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Insert;
