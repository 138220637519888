import React, { useState, useEffect } from "react";
import { Modal, Button, FormCheck } from "react-bootstrap";
import SnipperLoading from "../snipperLoading";
import { getList, put } from "../axiosComp";

import { Hata } from "../rolsControl";
import Hatamodal from "../hatamodal";

import Calendar from "react-calendar";
import { Link } from "react-router-dom";
const RandevuOnEkran = ({ doktor, setdoktor, secRT, setSecRT }) => {
  const [buttonOk, setbuttonOk] = useState(false);
  const [yeniEskiHasta, setYeniEskiHasta] = useState(true);

  // const [slots, setslots] = useState(false);
  const [girilecekRandSayisi, setgirilecekRandSayisi] = useState(1);
  const [randSiraNoSorgu, setRandSiraNoSorgu] = useState(0);

  const [doktorDetay, setdoktorDetay] = useState({ klinik: [], bakim: [] });

  const [randevu, setrandevu] = useState({
    randNo: "",
    randAdSoyad: "",
    randSaat: "--:--",
    randNot: "",
  });

  const [formValue, setformValue] = useState({
    tarih: new Date(),
    klinik: 0,
    klinikDetay: null,
    yeni: true,
    bakim: 0,
    bakimDetay: null,
    gurup: 0,
    gurupDetay: null,
  });

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const [adim, setadim] = useState(1);

  useEffect(() => {
    console.clear();
    console.log(secRT);
    if (doktor) {
      setadim(1);

      getList("/rand/drRandevuBilgisiGetir/" + doktor.drid)
        .then((a) => {
          setdoktorDetay(a.data);

          const fr = JSON.parse(JSON.stringify(formValue));

          if (a.data.klinik && a.data.klinik.length === 1) {
            fr.klinik = a.data.klinik[0].klid;
          }

          if (a.data.gurup && a.data.gurup.length === 1) {
            fr.gurup = a.data.gurup[0].grId;
          }

          if (a.data.bakim && a.data.bakim.length === 1) {
            fr.bakim = a.data.bakim[0].id;
          } else if (secRT) {
            try {
              console.log(a.data.bakim);
              if (secRT.ucret) {
                var ls = a.data.bakim.filter(
                  (a) => a.bId === secRT.tip && a.ucret === secRT.ucret
                );
                if (ls.length > 0) {
                  fr.bakim = ls[0].id;
                }
              } else {
                var ls = a.data.bakim.filter((a) => a.bId === secRT.tip);
                if (ls.length > 0) {
                  fr.bakim = ls[0].id;
                }
              }
            } catch {}

            // BId
            // fr.bakim = secRT;
          }

          fr.tarih = new Date();

          setformValue(fr);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });

          return;
        });
    } else {
      setSecRT();
    }
  }, [doktor]);

  // useEffect(() => {
  //   console.log("formValue");
  //   console.log(formValue);
  //   console.log("formValue");
  // }, [formValue]);

  //   klinikDetay bakimDetay
  // useEffect(() => {
  //   if (doktor) {
  //     if (formValue.bakim !== 0) {
  //       var bakimLisT = doktorDetay.bakim;

  //       var bakimT = bakimLisT.filter(
  //         (a) => a.id === parseInt(formValue.bakim)
  //       );

  //       setformValue({
  //         ...formValue,
  //         bakimDetay: bakimT,
  //       });

  //       setrandevu({
  //         randNo: "",
  //         randAdSoyad: "",
  //         randSaat: "--:--",
  //         randNot: "",
  //       });
  //     } else {
  //       setformValue({
  //         ...formValue,
  //         bakimDetay: null,
  //       });
  //     }
  //   }
  // }, [formValue.bakim]);

  // useEffect(() => {
  //   if (doktor) {
  //     if (formValue.klinik !== 0) {
  //       var klinikLisT = doktorDetay.klinik;

  //       var klinikT = klinikLisT.filter(
  //         (a) => a.klid === parseInt(formValue.klinik)
  //       );

  //       setformValue({
  //         ...formValue,
  //         klinikDetay: klinikT,
  //       });

  //       setrandevu({
  //         randNo: "",
  //         randAdSoyad: "",
  //         randSaat: "--:--",
  //         randNot: "",
  //       });
  //     } else {
  //       setformValue({
  //         ...formValue,
  //         klinikDetay: null,
  //       });
  //     }
  //   }
  // }, [formValue.klinik]);

  const handleClose = () => {
    setdoktor();
    setadim(0);
    setbuttonOk(false);
    setRandSiraNoSorgu(0);
    setgirilecekRandSayisi(1);

    setrandevu({
      randNo: "",
      randAdSoyad: "",
      randSaat: "--:--",
      randNot: "",
    });
    setformValue({
      tarih: new Date(),
      klinik: 0,
      klinikDetay: null,
      yeni: true,
      bakim: 0,
      bakimDetay: null,
      gurup: 0,
      gurupDetay: null,
    });

    seterrorS({
      uyari: { error: false, message: "", tip: "" },
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "yeni") {
      setformValue({
        ...formValue,
        [event.target.name]: event.target.checked,
      });
    } else {
      setformValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
    }
  };

  const randHandleChange = (event) => {
    if (event.target.name === "randNo") {
      setRandSiraNoSorgu(0);
    }

    setrandevu({
      ...randevu,
      [event.target.name]: event.target.value,
    });
  };

  const randSorgula = () => {
    if (randevu.randNo.trim().length > 0) {
      setRandSiraNoSorgu(1);

      getList("/rand/setRandSiraNoSorgu/" + randevu.randNo)
        .then((a) => {
          if (a.data === "ok") {
            setRandSiraNoSorgu(2);
          } else {
            setRandSiraNoSorgu(3);
          }
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });

          setRandSiraNoSorgu(0);
          return;
        });
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    let onay = true;
    // console.log("---");
    // console.log(
    //   formValue.klinik +
    //     "-" +
    //     formValue.gurup +
    //     "-" +
    //     formValue.bakim +
    //     "-" +
    //     girilecekRandSayisi
    // );
    // console.log("---");
    if (
      formValue.klinik === 0 ||
      formValue.gurup === 0 ||
      formValue.bakim === 0 ||
      girilecekRandSayisi === 0
    ) {
      onay = false;
    }

    if (onay) {
      setbuttonOk(true);
      if (randevu && formValue) {
        var form_data = new FormData();

        form_data.append("klId", formValue.klinik);
        form_data.append("grId", formValue.gurup);
        form_data.append("bkId", formValue.bakim);
        form_data.append("drId", doktor.drid);
        form_data.append("isme", formValue.yeni ? false : true);
        form_data.append("tarih", formValue.tarih.toLocaleDateString());
        form_data.append("adSoyad", randevu.randAdSoyad);
        form_data.append("dosyaId", randevu.randNo);
        form_data.append("nott", randevu.randNot);
        form_data.append("saat", randevu.randSaat);

        form_data.append("girilecekRandSayisi", girilecekRandSayisi);
        put("/rand/kaydet", form_data)
          .then((a) => {
            setbuttonOk(false);

            handleClose();
            seterrorS({
              uyari: {
                tip: "success",
                error: true,
                message: "Randevu Oluşturuldu.",
              },
            });
          })
          .catch(function (error) {
            Hata(error);
            seterrorS({
              uyari: {
                tip: "error",
                error: true,
                message: "Hata oluştu.İşlem yapılamadı.",
              },
            });

            setbuttonOk(false);
            return;
          });
      } else {
        seterrorS({
          uyari: {
            tip: "error",
            error: true,
            message: "Hata oluştu.İşlem yapılamadı.",
          },
        });

        setbuttonOk(false);
      }
    } else {
      seterrorS({
        uyari: {
          tip: "danger",
          error: true,
          message:
            "Klinik,Bölüm veya  Randevu Türü alanlarını boş geçemezsiniz.",
        },
      });
    }
  };
  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {doktor && (
        <Modal
          show={doktor ? true : false}
          onHide={() => handleClose()}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{doktor.drAd} Randevu Oluştur</Modal.Title>
          </Modal.Header>

          <>
            <Modal.Body>
              {adim !== 4 && (
                <div className="text-center">
                  <h3>
                    Seçilen Tarih : {formValue.tarih.toLocaleDateString()}
                  </h3>
                </div>
              )}

              {/* {adim === 1 && (
                <div className="mb3 row text-center kapali">
                  <label className="form-label col-sm-12 ">Randevu Tarihi</label>
                  <div className="col-sm-12">
                    <Calendar
                      name="klinik"
                      className="form-control"
                      value={formValue.tarih}
                      onChange={(a) => {
                        setformValue({
                          ...formValue,
                          tarih: a,
                        });
                      }}
                    />
                  </div>
                </div>
              )} */}

              {adim === 1 && (
                <>
                  <div className="mb3 row">
                    <label className="form-label col-sm-3 ">Klinik </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="klinik"
                          onChange={handleChange}
                          defaultValue={formValue.klinik}
                        >
                          {doktorDetay && doktorDetay.klinik.length > 1 && (
                            <option selected={formValue.klinik === 0} value={0}>
                              Seçiniz
                            </option>
                          )}
                          {doktorDetay &&
                            doktorDetay.klinik.map((a, index) => (
                              <option
                                value={a.klid}
                                key={index}
                                className={index}
                                selected={formValue.klinik === a.klid}
                              >
                                {a.sehir} - {a.klinik}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mb3 row">
                    <label className="form-label col-sm-3 ">Bölüm </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="gurup"
                          onChange={handleChange}
                        >
                          {doktorDetay &&
                            doktorDetay.gurup &&
                            doktorDetay.gurup.length > 1 && (
                              <option
                                selected={formValue.gurup === 0}
                                value={0}
                              >
                                Seçiniz
                              </option>
                            )}
                          {doktorDetay &&
                            doktorDetay.gurup &&
                            doktorDetay.gurup.map((a, index) => (
                              <option
                                value={a.grId}
                                key={index}
                                className={index}
                                selected={formValue.gurup === a.grId}
                              >
                                {a.grupAd}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mb3 row">
                    <label className="form-label col-sm-3 ">
                      Randevu Türü{" "}
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="bakim"
                          onChange={handleChange}
                          defaultValue={formValue.bakim}
                        >
                          {doktorDetay && doktorDetay.bakim.length > 1 && (
                            <option value={0} selected={formValue.bakim === 0}>
                              Seçiniz
                            </option>
                          )}
                          {doktorDetay &&
                            doktorDetay.bakim.map((a, index) => (
                              <option
                                value={a.id}
                                key={index}
                                className={index}
                                selected={formValue.bakim === a.id}
                              >
                                {a.bakimAd}
                                {a.ucret &&
                                 ( a.ucret > 0) ?
                                  "  |  " + a.ucret.toFixed(2) + " ₺":''}{" "}
                                {a.aciklama && "  |  " + a.aciklama}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    {/* {formValue.bakimDetay && formValue.bakimDetay.length > 0 && (
                      <div className="mb3 row">
                        <label className="form-label col-sm-3 ">
                          Varsayılan Ücret{" "}
                        </label>
                        <div className="col-sm-9">
                          <div
                            className="form-group"
                            style={{ padding: "5px" }}
                          >
                            {formValue.bakimDetay[0].ucret} ₺
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>

                  {/* Radio Buttonlar */}
                  <div className="mb3 row">
                    <div className="col-sm-12 flexC">
                      <div className="margin-55">
                        <FormCheck
                          type="radio"
                          name="yeni"
                          className="blue"
                          onChange={(e) => {
                            setformValue({
                              ...formValue,
                              ["yeni"]: false,
                            });
                          }}
                          checked={!formValue.yeni ? true : false}
                        ></FormCheck>
                      </div>{" "}
                      <div className="blue"> İsime </div>
                      <div
                        style={{
                          borderLeft: "2px solid gray",
                          margin: "0 35px ",
                        }}
                      ></div>
                      <div className="orange"> Yeni </div>
                      <div className="margin-55">
                        <FormCheck
                          className="orange"
                          type="radio"
                          name="yeni"
                          onChange={(e) => {
                            setformValue({
                              ...formValue,
                              ["yeni"]: true,
                            });
                          }}
                          checked={formValue.yeni}
                        ></FormCheck>
                      </div>
                    </div>
                  </div>

                  <div className="mb3 row">
                    <div className="col-sm-12 flexC">
                      <div className="margin-55">
                        <FormCheck
                          type="radio"
                          name="yenieski"
                          className="blue"
                          onChange={(e) => {
                            setYeniEskiHasta(true);
                          }}
                          checked={yeniEskiHasta}
                        ></FormCheck>
                      </div>{" "}
                      <div
                        className="blue"
                        style={{ color: "gray", fontSize: "22px" }}
                      >
                        {" "}
                        Yeni hasta{" "}
                      </div>
                      <div
                        style={{
                          borderLeft: "2px solid gray",
                          margin: "0 35px ",
                        }}
                      ></div>
                      <div
                        className="orange"
                        style={{ color: "gray", fontSize: "22px" }}
                      >
                        {" "}
                        Eski hasta{" "}
                      </div>
                      <div className="margin-55">
                        <FormCheck
                          className="orange"
                          type="radio"
                          name="yenieski"
                          onChange={(e) => {
                            setYeniEskiHasta(false);
                          }}
                          checked={!yeniEskiHasta ? true : false}
                        ></FormCheck>
                      </div>
                    </div>
                  </div>

                  <div className="mb3 row">
                    <label className="form-label col-sm-3 ">
                      {yeniEskiHasta ? <>TC Kimlik No</> : <>Dosya Numarası</>}
                      <span className="req"> * </span>
                      {randSiraNoSorgu === 0 ? (
                        <Link onClick={() => randSorgula()} to="#">
                          <i className="fa fa-list"></i>
                        </Link>
                      ) : randSiraNoSorgu === 1 ? (
                        <SnipperLoading />
                      ) : randSiraNoSorgu === 2 ? (
                        <>
                          <i className="fa fa-check check"></i>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-times times"></i>
                        </>
                      )}
                    </label>

                    <div className="col-sm-9">
                      <div className="form-group">
                        <input
                          className="form-control"
                          onChange={randHandleChange}
                          value={randevu.randNo}
                          name="randNo"
                        ></input>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {adim === 4 && (
                <>
                  <div className="mb3 row">
                    {randevu &&
                    randevu.randNo.trim().length > 0 &&
                    randevu.randAdSoyad.trim().length > 0 &&
                    randevu.randSaat.trim() !== "--:--" ? (
                      <>
                        <div className="table-responsive">
                          <table className="table mt-4 mb-0 table-centered table-nowrap">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Randevu No</strong>
                                </td>
                                <td>{randevu.randNo}</td>
                              </tr>

                              <tr>
                                <td>
                                  <strong>Tarih</strong>
                                </td>
                                <td>{formValue.tarih.toLocaleDateString()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Saat</strong>
                                </td>
                                <td>{randevu.randSaat}</td>
                              </tr>

                              <tr>
                                <td>
                                  <strong>Hasta</strong>
                                </td>
                                <td>{randevu.randAdSoyad}</td>
                              </tr>

                              <tr>
                                <td>
                                  <strong>Doktor</strong>
                                </td>
                                <td>{doktor.drAd}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Randevu</strong>
                                </td>
                                <td>{formValue.yeni ? "Yeni" : "İsme"}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Klinik</strong>
                                </td>
                                <td> {formValue.klinikDetay[0].klinik}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Bakım</strong>
                                </td>
                                <td> {formValue.bakimDetay[0].bakimAd}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Not</strong>
                                </td>
                                <td>{randevu.randNot}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <div className="alert alert-warning" role="alert">
                            <strong>
                              Randevu bilgileri eksik yada hatalı.
                            </strong>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              <div className="mb3 row" style={{ display: "None" }}>
                <div className="form-group">
                  Randevu Sayısı <span className="req"> * </span>
                  <input
                    className="form-control"
                    onChange={(e) => setgirilecekRandSayisi(e.target.value)}
                    value={girilecekRandSayisi}
                    name="girilecekRandSayisi"
                    type="number"
                  ></input>
                </div>{" "}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk &&
                doktorDetay.klinik.length > 0 &&
                doktorDetay.gurup.length > 0 &&
                doktorDetay.bakim.length > 0 && (
                  <>
                    <Button variant="success" onClick={() => onSubmit()}>
                      Onayla ve Kaydet
                    </Button>
                  </>
                )}

              {!buttonOk &&
                (doktorDetay.klinik.length === 0 ||
                  doktorDetay.gurup.length === 0 ||
                  doktorDetay.bakim.length === 0) && (
                  <>Gerekli doktor ve randevu bilgileri bulunmuyor.</>
                )}

              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
        </Modal>
      )}
    </>
  );
};

export default RandevuOnEkran;
