import React, { useEffect, useState } from "react";
import { getList, post } from "../../components/axiosComp";
import { Hata } from "../../components/rolsControl";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";

const RandevuForExcell = () => {
  function oneDays(numOfMonths, date = new Date()) {
    date.setDate(numOfMonths);
    return date;
  }

  const [klinikSelected, setklinikSelected] = useState(0);

  const [data, setdata] = useState();
  const [file, setfile] = useState();
  const [buttonOk, setbuttonOk] = useState(false);

  const [liste, setliste] = useState();
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const [formValue, setformValue] = useState({
    yil: new Date().getFullYear(),
    ay: (new Date().getMonth() + 1).toString().padStart(2, "0"),
  });

  useEffect(() => {
    getList("/ubs/kliniklist")
      .then((a) => {
        setliste(a.data);
        console.log(a.data);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        return;
      });
  }, []);

  const GridDownload = () => {
    window.open("/drbs/doktorListExcel?id=" + klinikSelected);
    // getList("/drbs/doktorListExcel")
    // .then((a) => {
    //     return a.data;
    // })
    // .catch(function (error) {
    //   Hata(error);
    //   seterrorS({
    //     uyari: {
    //       error: true,
    //       message: error.response.data.message,
    //       tip: "danger",
    //     },
    //   });
    //   return;
    // });
  };

  const GridUpload = () => {
    setbuttonOk(true);

    if (file) {
      var form_data = new FormData();

      form_data.append("file", file);
      form_data.append("ay", formValue.ay);
      form_data.append("yil", formValue.yil);

      post("/drbs/doktorListExcelUpload/", form_data)
        .then((a) => {
          setbuttonOk(false);

          setdata(a.data);

          seterrorS({
            uyari: {
              error: true,
              message: "Bilgiler güncellendi",
              tip: "success",
            },
          });
        })
        .catch(function (error) {
          Hata(error);

          if (error.response.data && error.response.data.message) {
            seterrorS({
              uyari: { error: true, message: error.response.data.message },
            });
          }

          setbuttonOk(false);

          return;
        });
    } else {
      seterrorS({
        uyari: { error: true, message: "Dosya Seçiniz.", tip: "danger" },
      });
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div>
        {buttonOk ? (
          <>
            <SnipperLoading></SnipperLoading>
          </>
        ) : (
          <div className={"card"}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="text-center">
                    Randevu Dökümünü İçe Aktar(Excel)
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="col-md-12">
                      <div className="text-center">
                        <button
                          className="btn btn-info"
                          onClick={() => GridDownload()}
                        >
                          Örnek İndir
                        </button>
                      </div>{" "}
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Klinik</label>
                        <div class="row">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setklinikSelected(e.target.value);
                            }}
                          >
                            <option value={0}>Seçiniz</option>
                            {liste &&
                              liste.map((a, index) => (
                                <option value={a.klid}>
                                  {a.klinik.toUpperCase()}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="row m-3">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Ay</label>
                          <select
                            className="form-control"
                            defaultValue={new Date().getMonth()}
                            onChange={(e) =>
                              setformValue({ ...formValue, ay: e.target.value })
                            }
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Yıl</label>
                            <select
                              className="form-control"
                              defaultValue={new Date().getFullYear()}
                              onChange={(e) =>
                                setformValue({
                                  ...formValue,
                                  yil: e.target.value,
                                })
                              }
                            >
                              <option value={new Date().getFullYear() - 1}>
                                {new Date().getFullYear() - 1}
                              </option>
                              <option value={new Date().getFullYear()}>
                                {new Date().getFullYear()}
                              </option>
                              <option value={new Date().getFullYear() + 1}>
                                {new Date().getFullYear() + 1}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Dosya</label>
                          <div className="text-center">
                            <input
                              type="file"
                              name="file"
                              className="form-control"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={(e) =>
                                e.target.files.length > 0 &&
                                setfile(e.target.files[0])
                              }
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="text-center">
                        <button
                          className="btn btn-success"
                          onClick={() => GridUpload()}
                        >
                          Yükle
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div class="card">
              <div class="card-body">
                <div className="table-responsive b-0 fixed-solution">
                  {data && (
                    <>
                      <table className="table table-striped table-bordered randevusiratablosu">
                        <thead>
                          <tr className="">
                            <th rowSpan={3}>Güncellenen : Dr/Psk Ad Soyad</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {data.length > 0 ? (
                              <>
                                {data.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <span class="text-muted mb-0">
                                        {item}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td>
                                  <h6 className="text-center">
                                    Kayıt Yapılamadı.
                                  </h6>
                                </td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RandevuForExcell;
