import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../components/snipperLoading";
import { put } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import DateTimePicker from "react-datetime-picker";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../components/rolsControl";
const Yenimesaj = () => {
  const history = useHistory();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [formValue, setformValue] = useState({
    id: 0,
    baslik: "",
    bitisTarihi: addMonths(3),
    basTarihi: new Date(),
    tip: 0,
    metin: "",
    durum: true,
  });

  function addMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  }

  const onSubmit = () => {
    if (formValue.baslik.length < 2 && formValue.tip === 0) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in formValue) {
        console.log(key);
        if (key === "bitisTarihi") {
          form_data.append(key, formValue[key].toLocaleString("tr-TR"));
        } else if (key === "basTarihi") {
          form_data.append(key, formValue[key].toLocaleString("tr-TR"));
        } else {
          form_data.append(key, formValue[key]);
        }
      }

      let urll = "";

      urll = "uyariAdd";

      console.log(formValue);

      put("/ubs/" + urll, form_data)
        .then((a) => {
          history.push("mesajlist");
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
              tip: "success",
            },
          });
          setbuttonOk(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <div class="container-fluid">
        <div className="card">
          <div className="card-body">
            <h4>Yeni Mesaj </h4>
            <form onSubmit={() => onSubmit()}>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Durum</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.durum}
                    name="durum"
                  >
                    <option value={true}>Evet</option>
                    <option value={false}>Hayır</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Tip <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.tip}
                    name="tip"
                  >
                    <option value={0}>Beklemede</option>
                    <option value={1}>Ana Sayfa</option>
                    <option value={2}>Popup</option>
                    <option value={3}>Üst Bildirim</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Başlangıç Tarihi <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-4">
                  <DateTimePicker
                    format="dd.MM.yyyy"
                    minDate={new Date()}
                    name="basTarihi"
                    class="form-control"
                    disableClock={true}
                    value={formValue.basTarihi}
                    onChange={(date) => {
                      setformValue({
                        ...formValue,
                        basTarihi: date,
                      });
                    }}
                  />
                </div>

                <label class="form-label col-sm-2 ">
                  Bitiş Tarihi <span className="req">*</span>{" "}
                </label>

                <div class="col-sm-4">
                  <DateTimePicker
                    format="dd.MM.yyyy"
                    minDate={formValue.basTarihi}
                    name="bitisTarihi"
                    class="form-control"
                    disableClock={true}
                    value={formValue.bitisTarihi}
                    onChange={(date) => {
                      setformValue({
                        ...formValue,
                        bitisTarihi: date,
                      });
                    }}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Başlık <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="baslik"
                    type="text"
                    class="form-control"
                    value={formValue.baslik}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <div class="col-sm-12">
                  {/* <input
                    name="mesaj"
                    type="text"
                    class="form-control"
                    value={formValue.mesaj}
                    onChange={handleChange}
                  /> */}

                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setformValue({
                        ...formValue,
                        metin: data,
                      });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>

              {!buttonOk && (
                <div className="text-end">
                  <Button variant="success" onClick={() => onSubmit()}>
                    Kaydet
                  </Button>
                </div>
              )}

              {buttonOk && <SnipperLoading />}
            </form>
          </div>
        </div>
      </div>
      <SweetAlert
        showConfirm={false}
        showCancel={true}
        danger
        show={smShow}
        title="Uyarı"
        onCancel={() => setSmShow(false)}
      >
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Yenimesaj;
