import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { getList } from "../../components/axiosComp";

const Popup = ({ detay, setDetay }) => {
  const bildirimlerisifrla = () => {
    getList("/uybs/uyeBildirimOkundu");
  };

  return (
    <Modal
      size="xl"
      show={detay && detay.length > 0 ? true : false}
      onHide={() => {
        setDetay();
        bildirimlerisifrla();
      }}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Bildirimleriniz
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {detay &&
          detay.map((a) => (
            <div>
              <h6>{a.baslik}</h6>
              <div>{ReactHtmlParser(a.metin)}</div>
              <hr />
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant="secondary"
            onClick={() => {
              setDetay();
              bildirimlerisifrla();
            }}
          >
            Kapat
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
