import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, post } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { Hata } from "../../rolsControl";

const DoktorTest = ({ show, setshow, setrefresh }) => {
  const [modal, setmodal] = useState(false);

  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [liste, setliste] = useState();
  const [selectedCheckboxes, setselectedCheckboxes] = useState([]);
  // const selectedCheckboxes = [];
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });

 
  useEffect(() => {
    if (show) {
      getList("/drbs/doktorTestRef/" + show)
        .then((a) => {
          if (a.data.liste.length > 0) {
            setliste(a.data.liste);

            setselectedCheckboxes(a.data.grRef);

            setbuttonOk(false);
            setLoading(false);
          } else {
            seterrorS({
              uyari: {
                error: true,
                message: "Test  tanımlarını yapınız.",
              },
            });
            handleClose();
          }

          setmodal(true);
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoading(false);
          return;
        });
    }
  }, [show]);

  const onSubmit = () => {
    setbuttonOk(true);

    var form_data = new FormData();

    form_data.append("dizi", selectedCheckboxes);

    post("/drbs/doktorTestRefPost/" + show, form_data)
      .then((a) => {
        setbuttonOk(false);

        setrefresh(a.data);

        seterrorS({
          uyari: { error: true, message: "Bilgileriniz Güncellendi" ,tip:"success"},
        });
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);
        return;
      });
  };

  const handleClose = () => {
    setmodal(false);
    setshow();
  };

  const handleChange = (event, id) => {
    console.log(id + " " + event.target.checked);

    var durum = selectedCheckboxes.includes(id);

    // setArr([...arr, arr[arr.length - 1] + 1]

    if (!durum) {
      // selectedCheckboxes.push(id);
      setselectedCheckboxes((currentArray) => [...currentArray, id]);
    } else if (durum) {
      var index = selectedCheckboxes.indexOf(id);
      if (index > -1) {
        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        setselectedCheckboxes((currentArray) =>
          currentArray.filter((a) => a !== id)
        );
      }
    }
  };
  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Doktor Test Listesi</Modal.Title>
        </Modal.Header>
        {liste && (
          <>
            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <div class="row">
                  {liste.map((a, index) => (
                    <div className="col-lg-12  bgbanner">
                      <>
                        <label class="form-label  ">
                          <input
                            type="checkbox"
                            name="test"
                            value={a.id}
                            onChange={(e) => handleChange(e, a.id)}
                            checked={selectedCheckboxes.includes(a.id)}
                          ></input>{" "}
                        </label> 
                       <div className="drtanimgoster"> {a.baslik}</div>
                      </>{" "}
                    </div>
                  ))}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && !loading ? (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Kapat
                  </Button>

                  <Button variant="success" onClick={() => onSubmit()}>
                    Kaydet
                  </Button>
                </>
              ) : (
                <SnipperLoading></SnipperLoading>
              )}

              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default DoktorTest