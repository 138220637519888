import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";
import Insert from "../../components/tanimlar/uyeler/insert";
import Update from "../../components/tanimlar/uyeler/update";
import Delete from "../../components/tanimlar/uyeler/delete";
import { getFormatDatetime } from "../../components/tanimlar/funcColl";
import { Hata } from "../../components/rolsControl";
const Uyeindex = () => {
  const [refresh, setrefresh] = useState([]);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [uid, setuid] = useState();
  const [did, setdid] = useState();
  const [dname, setdname] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
    getList("/uybs/tumListe")
      .then((a) => {
        console.log(a.data);
        setData(a.data.liste);

        setLoading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setData([]);
        setLoading(false);
        return;
      });
  }, []);

  useEffect(() => {
    if (refresh.length > 0) {
      setData(refresh);
      setLoading(false);
    }
  }, [refresh]);

  const handleShow = () => setShow(true);

  const handleuShow = (id) => {
    setuid(id);
  };

  const handledShow = (id, name) => {
    console.log(id + " " + name);
    setdname(name);
    setdid(id);
  };

  return (
    <div className="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Insert
        show={show}
        setShow={setShow}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Update
        uid={uid}
        setuid={setuid}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Delete
        did={did}
        setdid={setdid}
        name={dname}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />
      <div className="card">
        <div className="card-body">
          <h1>Üyeler</h1>
          <p>Bu bölümden üyeleri ve rolleri yönetebilirsiniz.</p>

          <div className="text-end">
            <Button onClick={handleShow} className="btn btn-info">
              <i className="fas fa-plus"></i> Üye Oluştur
            </Button>
          </div>

          {loading && <SnipperLoading />}

          {!loading && (
            <>
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Rol</th>
                      <th>Görevi</th>
                      <th>Kad</th>
                      <th>Kullanıcı</th>
                      <th>Tel</th>
                      <th>Email</th>
                      <th>Oluşturma Tarihi</th>
                      <th>Güncelleme Tarihi</th>
                      <th>Durum</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((a, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{a.rol}</td>
                          <td>
                            {a.banko && (
                              <strong style={{ color: "red" }}>
                                Banko Görevlisi
                              </strong>
                            )}
                          </td>
                          <td>{a.kad}</td>
                          <td>{a.kullanici}</td>
                          <td>{a.tel}</td>
                          <td>{a.email}</td>
                          <td>{getFormatDatetime(a.ctarih)}</td>
                          <td>{a.utarih}</td>
                          <td>
                            {a.durum ? (
                              <i className="fa fa-check check"></i>
                            ) : (
                              <i className="fa fa-times times"></i>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => handleuShow(a.id)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>

                            <button
                              className="btn btn-danger  btn-sm"
                              onClick={() => handledShow(a.id, a.kad)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Uyeindex;
