import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";

const Insert = ({ show, setShow, setrefresh, setLoadingUst }) => {
  const handleClose = () => setShow(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [formValue, setformValue] = React.useState({
    rolAdi: "",
    aciklama: "",
    kod: "",
    ozelTanim: false,
    genelTanimEkle: false,
    genelTanimGunc: false,
    genelTanimSil: false,
    mesajlar: false,
    uyegiris: false,
    doktorEkle: false,
    doktorGunc: false,
    doktorFoto: false,
    doktorSil: false,
    doktorUcret: false,
    doktorTanimlar: false,
    doktorKlGr: false,
    randevuOlustur: false,
    randevuGecmisi: false,
    randevuKaldir: false,
    yonlendir: false,
    izinDilekcesi: false,
    randevuPuan: false,
    durum: true,
  });

  const onSubmit = () => {
    if (formValue.rolAdi.length < 3 || formValue.kod.length < 1) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }
      

      put("/uybs/rolEkle", form_data)
        .then((a) => {
          setrefresh(a.data);
          setShow(false);
          setbuttonOk(false);
          seterrorS({
            uyari: {
              error: true,
              message: formValue.kod + "-" + formValue.rolAdi + " eklendi",
              tip: "success",
            },
          });

          setformValue({
            rolAdi: "",
            aciklama: "",
            kod: "",
            ozelTanim: false,
            genelTanimEkle: false,
            genelTanimGunc: false,
            genelTanimSil: false,
            mesajlar: false,
            uyegiris: false,
            doktorEkle: false,
            doktorGunc: false,
            doktorFoto: false,
            doktorSil: false,
            doktorUcret: false,
            doktorTanimlar: false,
            doktorKlGr: false,
            randevuOlustur: false,
            randevuGecmisi: false,
            randevuKaldir: false,
            yonlendir: false,
            izinDilekcesi: false,
            randevuPuan: false,
            durum: true,
          });
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Rol Oluştur</Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <form onSubmit={() => onSubmit()}>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Rol Adı: <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="rolAdi"
                    type="text"
                    required
                    class="form-control"
                    placeholder="Min 3 Karakter"
                    value={formValue.rolAdi}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Kod: <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="kod"
                    placeholder="Min 1 Karakter"
                    type="text"
                    required
                    class="form-control"
                    value={formValue.kod}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Açıklama</label>
                <div class="col-sm-10">
                  <input
                    name="aciklama"
                    type="text"
                    class="form-control"
                    value={formValue.aciklama}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Randevu ve Puanlama İşlemleri Tam Yetki
                </label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.randevuPuan}
                    name="randevuPuan"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Özel Tanım</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.ozelTanim}
                    name="ozelTanim"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Genel Tanım Ekle</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.genelTanimEkle}
                    name="genelTanimEkle"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Genel Tanım Güncelle</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.genelTanimGunc}
                    name="genelTanimGunc"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Genel Tanım Sil</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.genelTanimSil}
                    name="genelTanimSil"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Mesajlar</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.mesajlar}
                    name="mesajlar"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Üye Giriş</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.uyegiris}
                    name="uyegiris"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Ekle</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorEkle}
                    name="doktorEkle"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Güncelle</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorGunc}
                    name="doktorGunc"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Foto</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorFoto}
                    name="doktorFoto"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Sil</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorSil}
                    name="doktorSil"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Ücret</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorUcret}
                    name="doktorUcret"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Tanımlar</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorTanimlar}
                    name="doktorTanimlar"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Doktor Klinik ve Gurup İşlemleri
                </label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.doktorKlGr}
                    name="doktorKlGr"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Randevu Olustur</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.randevuOlustur}
                    name="randevuOlustur"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Randevu Geçmişi</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.randevuGecmisi}
                    name="randevuGecmisi"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Randevu Kaldır</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.randevuKaldir}
                    name="randevuKaldir"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Yönlendir</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.yonlendir}
                    name="yonlendir"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">İzin Dilekçesi</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.izinDilekcesi}
                    name="izinDilekcesi"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Kaydet
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </>
      </Modal>

      <SweetAlert
        showConfirm={false}
        showCancel={true}
        danger
        show={smShow}
        title="Uyarı"
        onCancel={() => setSmShow(false)}
      >
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Insert;
