import React from "react";

const KlinikServisler = ({ handleChange, defaultValue }) => {
  return (
    <div class="mb3 row">
      <label class="form-label col-sm-2 "></label>
      <div class="col-sm-10">
        <select
          onChange={(event) => handleChange(event)}
          className="form-control"
          defaultValue={defaultValue}
          name="services"
        >
          <option value={0}>Servis Yok</option>
          <option value={1}>Ankara Meddata</option>
          <option value={2}>İstanbul Meddata</option>
        </select>
      </div>
    </div>
  );
};

export default KlinikServisler;
