import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import Insert from "../../../components/tanimlar/terapi/insert";
import Update from "../../../components/tanimlar/terapi/update";
import Delete from "../../../components/tanimlar/terapi/delete";
import { getList } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import RolsControl , { Hata } from "../../../components/rolsControl";

const Terapi = () => {
  const [refresh, setrefresh] = useState([]);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "" ,tip:null},
  });
  const [uid, setuid] = useState();
  const [did, setdid] = useState();
  const [dname, setdname] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
    getList("/ubs/terapiList")
      .then((a) => {
        setData(a.data);
        setLoading(false);
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setData([]);
        setLoading(false);
        return;
      });
  }, []);

  useEffect(() => {
    if (refresh.length > 0) {
      setData(refresh);
      setLoading(false);
    }
  }, [refresh]);

  const handleShow = () => setShow(true);

  const handleuShow = (id) => {
    setuid(id);
  };

  const handledShow = (id, name) => {
    setdname(name);
    setdid(id);
  };

  return (
    <div class="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Insert
        show={show}
        setShow={setShow}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Update
        uid={uid}
        setuid={setuid}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Delete
        did={did}
        setdid={setdid}
        name={dname}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />
      <div className="card">
        <div className="card-body">
          <h1>Terapiler</h1>
          <p>Bu bölümden terapi alanlarını yönetebilirsiniz.</p>
          {RolsControl("genelTanimEkle") && (
            <div className="text-end">
              <Button onClick={handleShow} className="btn btn-info">
                <i className="fas fa-plus"></i> Terapi Alanı Ekle
              </Button>
            </div>
          )}

          {loading && <SnipperLoading />}

          {!loading && (
            <>
              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Başlık</th>{" "}
                      {RolsControl("genelTanimGunc") && <th></th>}
                      {RolsControl("genelTanimGunc") &&
                        RolsControl("genelTanimSil") && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((a, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{a.baslik}</td>
                          {RolsControl("genelTanimGunc") && (
                            <td>
                              {a.durum ? (
                                <i className="fa fa-check check"></i>
                              ) : (
                                <i className="fa fa-times times"></i>
                              )}
                            </td>
                          )}

                          {RolsControl("genelTanimGunc") &&
                            RolsControl("genelTanimSil") && (
                              <td>
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={() => handleuShow(a.id)}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>  {RolsControl("genelTanimSil") && (
                                <button
                                  className="btn btn-danger  btn-sm"
                                  onClick={() => handledShow(a.id, a.baslik)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>)}
                              </td>
                            )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Terapi;
