import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import "bootstrap/dist/js/bootstrap.js";

import Index from "./pages";
import NoPage from "./pages/NoPage";

import TanimlarRoute from "./pages/tanimlar/tanimlarRoute";
import DoktorIndex from "./pages/doktorlar/doktorIndex";
import Uyeindex from "./pages/uyeler/uyeindex";
import Yenimesaj from "./pages/mesajlar/yenimesaj";
import Mesajlist from "./pages/mesajlar/mesajlist";
import Profil from "./pages/profil/profil";
import SifreGuncelle from "./pages/profil/sifreGuncelle";
import DoktorDetay from "./pages/doktorlar/doktorDetay";
import Rolindex from "./pages/roller/rolindex";
import Login from "./pages/login";
import { PrivateRoute } from "./components/login/components/PrivateRoute";
import RandevuList from "./pages/randevular/randevuList";
import RandevuDetay from "./components/randevular/randevuDetay";
import DoktorRandevu from "./pages/doktorlar/doktorRandevu";
import RandevuSirasi from "./pages/randevular/randevuSirasi";
import Bliste from "./pages/bildirimler/bliste";
import RandevuDokumu from "./pages/randevular/randevuDokumu";
import RandevuForExcell from "./pages/randevular/randevuForExcell";

export default class App extends Component {
  static displayName = App.name;

  componentDidMount() {
    // if (!localStorage.getItem("user")) {
    //   getList("authe/index")
    //     .then((a) => {
    //       console.log("login");
    //       localStorage.setItem(a.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  }

  render() {
    return (
      <>
        <Switch>
          <PrivateRoute exact path="/">
            <Layout>
              <Index />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/uyeler">
            <Layout>
              <Uyeindex />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/roller">
            <Layout>
              <Rolindex />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/doktorlarlist">
            <Layout>
              <DoktorIndex />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/doktor-detay-:id">
            <Layout>
              <DoktorDetay />
            </Layout>
          </PrivateRoute>{" "}
          <PrivateRoute exact path="/doktor-randevu-:id">
            <Layout>
              <DoktorRandevu />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/tanimlar-:pages">
            <Layout>
              <TanimlarRoute />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/yenimesaj">
            <Layout>
              <Yenimesaj />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/mesajlist">
            <Layout>
              <Mesajlist />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/profilim">
            <Layout>
              <Profil />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/sifreguncelle">
            <Layout>
              <SifreGuncelle />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/list-randevu">
            <Layout>
              <RandevuList />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/sira-randevu">
            <Layout>
              <RandevuSirasi />
            </Layout>
          </PrivateRoute>
          
          <PrivateRoute exact path="/randevu-Dokumu">
            <Layout>
              <RandevuDokumu />
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path="/randevu-for-excell">
            <Layout>
              <RandevuForExcell />
            </Layout>
          </PrivateRoute>
          
          <PrivateRoute exact path="/randevuDetay-:id">
            <Layout>
              <RandevuDetay />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path="/bildirimler">
            <Layout>
              <Bliste />
            </Layout>
          </PrivateRoute>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="*">
            <NoPage></NoPage>
          </Route>
        </Switch>

        {/*         


        <Route exact path="/" component={Index} />
            <Route exact path="/uyeler" component={Uyeindex} />
            <Route exact path="/roller" component={Rolindex} />
            <Route exact path="/doktorlarlist" component={DoktorIndex} />
            <Route exact path="/doktor-detay-:id" component={DoktorDetay} />
            <Route exact path="/tanimlar-:pages" component={TanimlarRoute} />

            <Route exact path="/yenimesaj" component={Yenimesaj} />
            <Route exact path="/mesajlist" component={Mesajlist} />

            <Route exact path="/profilim" component={Profil} />
            <Route exact path="/sifreguncelle" component={SifreGuncelle} />

            <Route exact path="/login" component={Login} />
            <Route path="*" component={NoPage} />
 */}
      </>
    );
  }
}
