import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { put } from "../axiosComp";
import Hatamodal from "../hatamodal";
import SnipperLoading from "../snipperLoading";

const YeniFiltre = ({ ekle, setekle, filtreListesi }) => {
  const [buttonOk, setbuttonOk] = useState(false);
  const [baslik, setbaslik] = useState("");

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const onSubmit = () => {
    if (baslik.length < 1) {
      seterrorS({
        uyari: {
          error: true,
          message: "Geçerli bir filtre başlığı ekleyin",
          tip: "warning",
        },
      });
    } else {
      var form_data = new FormData();
      form_data.append("dizi", JSON.stringify(filtreListesi));
      form_data.append("baslik", baslik);

      put("/drbs/filtreKaydet", form_data)
        .then((a) => {
          setbuttonOk(false);
          setekle(false);
          setbaslik("");
          seterrorS({
            uyari: {
              error: true,
              message: baslik + " ' kaydedildi.",
              tip: "success",
            },
          });
        })
        .catch(function (error) {
          setbuttonOk(false);
          setekle(false);
          seterrorS({
            uyari: {
              error: true,
              message: error.response.data.message,
              tip: "danger",
            },
          });
        });
    }
  };
  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal
        show={ekle}
        onHide={() => setekle(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Hızlı Filtre Ekle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => onSubmit()}>
            <div class="mb3 row">
              <label class="form-label col-sm-12 col-md-12 ">
                Filtre Başlıgı <span className="req">*</span>{" "}
              </label>
              <div class="col-sm-12  col-md-12 ">
                <input
                  name="bakimAd"
                  type="text"
                  required
                  class="form-control"
                  data-parsley-minlength="3"
                  value={baslik}
                  onChange={(e) => setbaslik(e.target.value)}
                />
              </div>
            </div>
          </form>
        </Modal.Body>{" "}
        <Modal.Footer>
          {!buttonOk && (
            <>
              <Button variant="secondary" onClick={() => setekle(false)}>
                Vazgeç
              </Button>
              <Button variant="success" onClick={() => onSubmit()}>
                Kaydet
              </Button>
            </>
          )}
          {buttonOk && <SnipperLoading />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default YeniFiltre;
