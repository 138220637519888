import React, { useState, useEffect } from "react";
import { getList, post } from "../../components/axiosComp";
import SnipperLoading from "../../components/snipperLoading";
import RolsControl, { Hata } from "../../components/rolsControl";
import Hatamodal from "../../components/hatamodal";
import { Link } from "react-router-dom";

import DateTimePicker from "react-datetime-picker";
import { getFormatFullDatetime } from "../../components/tanimlar/funcColl";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

const RandevuDokumu = () => {
  function oneDays(numOfMonths, date = new Date()) {
    date.setDate(numOfMonths);
    return date;
  }

  const [refreshButton, setRefreshButton] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [data, setdata] = useState([]);
  const [dtBakim, setdtBakim] = useState([]);
  const [icerik, seticerik] = useState();

  const [loading, setloading] = useState(true);
  const [tarihAraligi, setTarihAraligi] = useState({
    bas: oneDays(1),
    bit: new Date(),
  });

  const [varsayilanKlinik, setvarsayilanKlinik] = useState({
    klinik: 0,
  });

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("/rand/icerik")
      .then((a) => {
        console.log(a.data);
        seticerik(a.data);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
        return;
      });
  }, []);

  useEffect(() => {
    setloading(true);
    if (varsayilanKlinik.klinik === 0) {
      setdata([]);
      setloading(false);
    } else {
      listRefresh();
    }
  }, [varsayilanKlinik]);

  // useEffect(() => {
  //   listRefresh();
  // }, [tarihAraligi]);

  function listRefresh() {
    getList("/rand/BolumRandevuDokumu/" + varsayilanKlinik.klinik, tarihAraligi)
      .then((a) => {
        console.log(a.data);
        setdata(a.data);
        setloading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setdata([]);
        setloading(false);
        return;
      });
  }

  const Print = () => {
    //console.log('print');
    try {
      let printContents = document.getElementById("printablediv").innerHTML;
      // let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      setRefreshButton(true);
      // window.print();
      // document.body.innerHTML = originalContents;
    } catch {}
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {/* className="container-fluid" */}
      <div>
        <div className={"card"}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h4 className="text-center">Randevu Sıralama</h4>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <select
                    className="form-control"
                    name="klinik"
                    onChange={(event) =>
                      setvarsayilanKlinik({
                        ...varsayilanKlinik,
                        [event.target.name]: event.target.value,
                      })
                    }
                    defaultValue={varsayilanKlinik.klinik}
                  >
                    <option value={0}>Seçiniz</option>
                    {icerik &&
                      icerik.kliniks.map((a, index) => (
                        <option value={a.klid} key={index} className={index}>
                          {a.sehir} - {a.klinik.toUpperCase()}
                        </option>
                      ))}
                  </select> */}

                  <div className="row">
                    {icerik &&
                      icerik.kliniks.map((a, index) => (
                        <div
                          class={
                            "col-md-" +
                            (icerik.kliniks.length % 4 === 0
                              ? "3"
                              : icerik.kliniks.length % 3 === 0
                              ? "4"
                              : "3")
                          }
                        >
                          <Link
                            to="#"
                            onClick={() =>
                              setvarsayilanKlinik({
                                klinik: a.klid,
                              })
                            }
                            className={
                              varsayilanKlinik.klinik === a.klid && "secilenKat"
                            }
                          >
                            <h6 className="text-center klinikFiltrele">
                              {a.sehir.toUpperCase()} - {a.klinik.toUpperCase()}
                            </h6>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mw100">
          {icerik && varsayilanKlinik.klinik !== 0 && (
            <>
              <div class="card">
                <div class="card-body">
                  <div className="col-md-12">
                    <div class="mb3 row p-3 row ">
                      <div class="col-md-4 row">
                        <div className="col-md-6">
                          <label class="form-label ">
                            Başlangıç Tarihi <span className="req">*</span>{" "}
                          </label>
                        </div>

                        <div className="col-md-6">
                          <DateTimePicker
                            format="dd.MM.yyyy"
                            name="basTarihi"
                            class="form-control"
                            disableClock={true}
                            value={tarihAraligi.bas}
                            onChange={(date) => {
                              setTarihAraligi({
                                ...tarihAraligi,
                                bas: date,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div class="col-md-4 row">
                        <div className="col-md-6">
                          <label class="form-label ">
                            Bitiş Tarihi <span className="req">*</span>{" "}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <DateTimePicker
                            format="dd.MM.yyyy"
                            minDate={tarihAraligi.bas}
                            name="bitisTarihi"
                            class="form-control"
                            disableClock={true}
                            value={tarihAraligi.bit}
                            onChange={(date) => {
                              setTarihAraligi({
                                ...tarihAraligi,
                                bit: date,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => listRefresh()}
                        >
                          Filtrele
                        </button>

                        <button
                          className="btn btn-info  m-2"
                          onClick={() => Print()}
                        >
                          <i class="fa fa-print" aria-hidden="true"></i>
                        </button>

                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button btn btn-info"
                          table="table-to-xls"
                          filename="tablexls"
                          sheet="tablexls"
                          buttonText="Excel İndir"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="printablediv">
                {refreshButton && "refreshButton"}
                <br></br>
                <Link to="#" onClick={() => window.location.reload()}>
                  Yenile
                </Link>

                <div className={"card "}>
                  <div className="card-body">
                    <div className="table-responsive b-0 fixed-solution randsiralama">
                      <>
                        <>
                          <table
                            className="table table-striped table-bordered "
                            id="table-to-xls"
                          >
                            <thead>
                              <tr className="">
                                <th># </th>
                                <th>Tarih </th>
                                <th>Doktor</th>
                                <th>Dosya Id</th>
                                <th>Tipi Türü</th>
                                <th>Bakım Türü</th>
                                <th>Ücret Türü</th>
                                <th>Not</th>
                                <th>Ekleyen</th>
                              </tr>
                            </thead>{" "}
                            {icerik.grups.map((ic) => (
                              <tbody className="col-lg-12" key={ic.grId}>
                                {varsayilanKlinik.klinik !== 0 ? (
                                  <>
                                    <tr>
                                      <td colSpan={9}>
                                        <h6 style={{ textAlign: "center" }}>
                                          {ic.grupAd}
                                        </h6>
                                      </td>
                                    </tr>
                                    {data && data.length > 0 ? (
                                      data
                                        .filter((a) => a.grId === ic.grId)
                                        .map((a, i) => (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {getFormatFullDatetime(a.tarih)}
                                            </td>
                                            <td>{a.doktor}</td>
                                            <td>{a.dosyaId}</td>
                                            <td>{a.isme ? "İsme" : "Yeni"}</td>
                                            <td>{a.bakimAd}</td>
                                            <td>{a.ucret}</td>
                                            <td>{a.nott}</td>
                                            <td>{a.kullanici}</td>
                                          </tr>
                                        ))
                                    ) : (
                                      <tr>
                                        <td colSpan={9}>
                                          <h5 className="text-center">...</h5>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan={9}>
                                      <div className="col-lg-12">
                                        <SnipperLoading></SnipperLoading>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            ))}
                          </table>
                        </>
                      </>
                    </div>
                  </div>
                </div>

                {/* {icerik.grups.map((ic) => (
                  <div className="col-lg-12" key={ic.grId}>
                    {varsayilanKlinik.klinik !== 0 ? (
                      <>
                        <div className={"card "}>
                          <div className="card-body">
                            <h6>{ic.grupAd}</h6>
                            <div className="table-responsive b-0 fixed-solution randsiralama">
                              <>
                                <>
                                  <table
                                    className="table table-striped table-bordered "
                                    id="table-to-xls"
                                  >
                                    <thead>
                                      <tr className="">
                                        <th># </th>
                                        <th>Tarih </th>
                                        <th>Doktor</th>
                                        <th>Dosya Id</th>
                                        <th>Tipi Türü</th>
                                        <th>Bakım Türü</th>
                                        <th>Ücret Türü</th>
                                        <th>Not</th>
                                        <th>Ekleyen</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data && data.length > 0 ? (
                                        data
                                          .filter((a) => a.grId === ic.grId)
                                          .map((a, i) => (
                                            <tr>
                                              <td>{i + 1}</td>
                                              <td>
                                                {getFormatFullDatetime(a.tarih)}
                                              </td>
                                              <td>{a.doktor}</td>
                                              <td>{a.dosyaId}</td>
                                              <td>
                                                {a.isme ? "İsme" : "Yeni"}
                                              </td>
                                              <td>{a.bakimAd}</td>
                                              <td>{a.ucret}</td>
                                              <td>{a.nott}</td>
                                              <td>{a.kullanici}</td>
                                            </tr>
                                          ))
                                      ) : (
                                        <tr>
                                          <td colSpan={10}>
                                            <h5 className="text-center">...</h5>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-lg-12">
                        <div className={"card "}>
                          <div className="card-body">
                            <h6>{ic.grupAd}</h6>
                            {!loading &&
                              data.length === 0 &&
                              varsayilanKlinik.klinik !== 0 && (
                                <h6 className="text-center">
                                  Kayıt Bulunmuyor...
                                </h6>
                              )}
                            {!loading &&
                              data.length === 0 &&
                              varsayilanKlinik.klinik === 0 && (
                                <h6 className="text-center">
                                  Klinik seçilmedi.
                                </h6>
                              )}
                            {loading && <SnipperLoading></SnipperLoading>}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    )}
                  </div>
                ))} */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RandevuDokumu;
