import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";
import Insert from "../../components/tanimlar/roller/insert";
import Update from "../../components/tanimlar/roller/update";
import Delete from "../../components/tanimlar/roller/delete";
import { Hata } from "../../components/rolsControl";

const Rolindex = () => {
  const [refresh, setrefresh] = useState([]);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [uid, setuid] = useState();
  const [did, setdid] = useState();
  const [dname, setdname] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
    getList("/uybs/rolList")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
        setLoading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setData([]);
        setLoading(false);
        return;
      });

      
  }, []);

  useEffect(() => {
    if (refresh.length > 0 || did  ) {
      setData(refresh);
      setLoading(false);
    }
  }, [refresh]);

  const handleShow = () => setShow(true);

  const handleuShow = (id) => {
    setuid(id);
  };

  const handledShow = (id, name) => {
    console.log(id + " " + name);
    setdname(name);
    setdid(id);
  };

  return (
    <div className="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Insert
        show={show}
        setShow={setShow}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Update
        uid={uid}
        setuid={setuid}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />

      <Delete
        did={did}
        setdid={setdid}
        name={dname}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      />
      <div className="card">
        <div className="card-body">
          <h1>Roller</h1>
          <p>Bu bölümden rolleri ve yetkilerini yönetebilirsiniz.</p>

          <div className="text-end">
            <Button onClick={handleShow} className="btn btn-info">
              <i className="fas fa-plus"></i> Rol Oluştur
            </Button>
          </div>

          {loading && <SnipperLoading />}

          {!loading && (
            <>
              <div className="table-responsive">
                <table className="table table-striped mb-0 roller">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Durum</th>
                      <th>Kod</th>
                      <th>Rol Adı</th>
                      <th>Açıklama</th>
                      <th>Randevu ve Puanlama İşlemleri</th>
                      <th>Özel Tanımlar</th>
                      <th>Genel Tanımlar</th>
                      <th>Mesajlar</th>
                      <th>Üye İşlemleri</th>
                      <th>Doktor Crud</th>
                      <th>Doktor Kln. ve Gr.</th>
                      <th>Doktor Ücret</th>
                      <th>Doktor Tanımları</th>
                      <th>Randevu Oluştur</th>
                      <th>Randevu kaldır</th>
                      <th>Yönlendir</th>
                      <th>Randevu Dökümü</th>

                      <th>İzin Dilekçesi</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((a, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>

                          <td>
                            {a.durum ? (
                              <i className="fa fa-check check"></i>
                            ) : (
                              <i className="fa fa-times times"></i>
                            )}
                          </td>
                          <td>{a.kod.toUpperCase()}</td>
                          <td>{a.rolAdi.toUpperCase()}</td>
                          <td>{a.aciklama}</td>

                          <td>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  Randevu ve Puanlama İşlemleri Tam Yetki
                                </Tooltip>
                              }
                            >
                              {a.randevuPuan ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            {a.ozelTanim ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">Ekle</Tooltip>
                              }
                            >
                              {a.genelTanimEkle ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  Güncelle
                                </Tooltip>
                              }
                            >
                              {a.genelTanimGunc ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">Sil</Tooltip>
                              }
                            >
                              {a.genelTanimSil ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>
                          </td>

                          <td>
                            {a.mesajlar ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            {a.uyegiris ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>

                          <td>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">Ekle</Tooltip>
                              }
                            >
                              {a.doktorEkle ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  Güncelle
                                </Tooltip>
                              }
                            >
                              {a.doktorGunc ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  Foto Güncelle
                                </Tooltip>
                              }
                            >
                              {a.doktorFoto ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip-2">Sil</Tooltip>
                              }
                            >
                              {a.doktorSil ? (
                                <span className="badge bg-success">
                                  <i className="fa fa-check check"></i>
                                </span>
                              ) : (
                                <span className="badge bg-danger">
                                  <i className="fa fa-times times"></i>
                                </span>
                              )}
                            </OverlayTrigger>
                          </td>

                          <td>
                            {a.doktorKlGr ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>

                          <td>
                            {a.doktorUcret ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            {a.doktorTanimlar ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            {a.randevuOlustur ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>

                          <td>
                            {a.randevuKaldir ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            {a.yonlendir ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>
                          <td>
                            {a.randevuGecmisi ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>

                          <td>
                            {a.izinDilekcesi ? (
                              <span className="badge bg-success">
                                <i className="fa fa-check check"></i>
                              </span>
                            ) : (
                              <span className="badge bg-danger">
                                <i className="fa fa-times times"></i>
                              </span>
                            )}
                          </td>

                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => handleuShow(a.id)}
                            >
                              <i className="fa fa-edit"></i>
                            </button>

                            <button
                              className="btn btn-danger  btn-sm"
                              onClick={() =>
                                handledShow(a.id, a.kod + "-" + a.rolAdi)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rolindex;
