import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, post } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { Hata } from "../../rolsControl";

const DoktorBakimGunc = ({ show, setshow, setrefresh }) => {
  const [modal, setmodal] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [formValue, setformValue] = useState();

  const [bakimListesi, setbakimListesi] = useState({});

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:""  },
  });

  useEffect(() => {
    if (show) {
      getList("/drbs/doktorbakimListT/" + show)
        .then((a) => {
          console.log(a.data);
          setbakimListesi(a.data.bakimlist);

          setformValue(a.data.detay);

          setmodal(true);
          setLoading(false);
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setmodal(false);
          setbuttonOk(false);
          setLoading(true);
          return;
        });
    }
  }, [show]);

  const handleClose = () => {
    setshow();
    setmodal(false);
  };

  const onSubmit = () => {
    if ((formValue.bkId === 0) & (formValue.ucret > 3)) {
      seterrorS({
        uyari: { error: true, message: "* işaretli alanları bok bırakmayın." },
      });
    } else {
      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      post("/drbs/doktorBakimUcretiGunc/" + show, form_data)
        .then((a) => {
          setshow();
          setrefresh(a.data);
          setbuttonOk(false);
          handleClose();
          seterrorS({
            uyari: { error: true, message: "Bilgiler güncellendi" ,tip:"success"},
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);

          return;
        });
    }
  };

  const handleChange = (event) => {
    console.log(event.target.name);
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });

    console.log(formValue);
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {show && (
        <>
          <Modal show={modal} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
              <Modal.Title>Doktor Ücret Detay</Modal.Title>
            </Modal.Header>
            {loading && <SnipperLoading />}

            {!loading && formValue && (
              <>
                <Modal.Body>
                  <form onSubmit={() => onSubmit()}>
                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">Durum</label>
                      <div class="col-sm-10">
                        <select
                          onChange={handleChange}
                          className="form-control"
                          defaultValue={formValue.durum}
                          name="durum"
                        >
                          <option value={true}>Aktif</option>
                          <option value={false}>Pasif</option>
                        </select>
                      </div>
                    </div>

                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">
                        Tip <span className="req">*</span>
                      </label>

                      <div class="col-sm-10">
                        <select
                          className="form-control"
                          name="bkId"
                          onChange={handleChange}
                          defaultValue={formValue.bkId}
                        >
                          <option value={0}>Seçiniz</option>
                          {bakimListesi &&
                            bakimListesi.map((a, index) => (
                              <option value={a.bId} key={index}>
                                {a.bakimAd}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">
                        Ücret <span className="req">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input
                          name="ucret"
                          type="text"
                          required
                          class="form-control"
                          data-parsley-minlength="3"
                          value={formValue.ucret ? formValue.ucret : 0}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    
                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">
                        Açıklama <span className="req">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input
                          name="aciklama"
                          type="text"
                          required
                          class="form-control"
                          value={formValue.aciklama}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  {!buttonOk && (
                    <>
                      <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                      </Button>
                      <Button variant="success" onClick={() => onSubmit()}>
                        Kaydet
                      </Button>
                    </>
                  )}
                  {buttonOk && <SnipperLoading />}
                </Modal.Footer>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default DoktorBakimGunc;
