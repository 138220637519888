import React, { useState, useEffect } from "react";
import { getList, post } from "../../components/axiosComp";
import SnipperLoading from "../../components/snipperLoading";
import RolsControl, { Hata } from "../../components/rolsControl";
import Hatamodal from "../../components/hatamodal";
import { Link } from "react-router-dom";
import {
  randevuMudehale,
  RandevuMudehaleSelect,
} from "../../components/randevuMudehale";
import DateTimePicker from "react-datetime-picker";

const RandevuList = () => {
  function oneDays(numOfMonths, date = new Date()) {
    date.setDate(numOfMonths);

    return date;
  }

  const [buttonOk, setbuttonOk] = useState(false);

  const [data, setdata] = useState();
  const [icerik, seticerik] = useState();

  const [tarihAraligi, setTarihAraligi] = useState({
    bas: oneDays(1),
    bit: new Date(),
  });

  const [loading, setloading] = useState(true);

  const [varsayilanKlinik, setvarsayilanKlinik] = useState({
    klinik: 0,
  });

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    console.clear();

    getList("/ubs/klinikList")
      .then((a) => {
        seticerik(a.data);

        var form_data = new FormData();
        form_data.append("bas", tarihAraligi.bas.toLocaleString());
        form_data.append("bit", tarihAraligi.bit.toLocaleString());

        post("/rand/uyeRandevuDokumu", form_data)
          .then((a) => {
            setdata(a.data);

            console.log(a.data);
          })
          .catch(function (error) {
            Hata(error);
            seterrorS({
              uyari: {
                error: true,
                message: error.response.data.message,
                tip: "danger",
              },
            });
            return;
          });
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
        return;
      });
  }, []);

  useEffect(() => {
    setloading(true);
    if (varsayilanKlinik.klinik === 0) {
      setdata();
      setloading(false);
    } else {
      listRefresh();
    }
  }, [varsayilanKlinik]);

  const onFiltrele = () => {
    setloading(true);
    if (varsayilanKlinik.klinik === 0) {
      setdata();
      setloading(false);
    } else {
      listRefresh();
    }
  };

  // useEffect(() => {

  // }, [tarihAraligi]);

  function listRefresh() {
    var form_data = new FormData();
    form_data.append("bas", tarihAraligi.bas.toLocaleString());
    form_data.append("bit", tarihAraligi.bit.toLocaleString());

    post("/rand/uyeRandevuDokumu/" + varsayilanKlinik.klinik, form_data)
      .then((a) => {
        console.clear();
        console.log("--");
        console.log(a.data);
        console.log("--");

        setdata(a.data);

        setloading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setdata();
        setloading(false);
        return;
      });
  }

  const UyeDrbkmSorg = (drid, uyeid, bk) => {
    if (data.randevuList) {
      var degisken = data.randevuList.filter(
        (a) =>
          a.drId === parseInt(drid) &&
          a.anaBkId === parseInt(bk) &&
          a.uyeid === parseInt(uyeid)
      );

      if (degisken && degisken.length > 0) {
        return (
          <>
            <td>{degisken[0].yeni}</td>
            <td>{degisken[0].isme}</td>
          </>
        );
      } else {
        return (
          <>
            <td className="pasifsira"></td>
            <td className="pasifsira"></td>
          </>
        );
      }
    }
    return "";
  };
  // {ituye.id} -{item.drId}

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {/* className="container-fluid" */}
      <div className="">
        <div className={"card "}>
          <div className="card-body">
            <div className="row">
              {icerik &&
                icerik.map((a, index) => (
                  <div
                    class={
                      "col-md-" +
                      (icerik.length % 4 === 0
                        ? "3"
                        : icerik.length % 3 === 0
                        ? "4"
                        : "3")
                    }
                  >
                    <Link
                      to="#"
                      onClick={() =>
                        setvarsayilanKlinik({
                          klinik: a.klid,
                        })
                      }
                      className={
                        varsayilanKlinik.klinik === a.klid && "secilenKat"
                      }
                    >
                      <h6 className="text-center klinikFiltrele">
                        {a.sehir.toUpperCase()} - {a.klinik.toUpperCase()}
                      </h6>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {varsayilanKlinik && varsayilanKlinik.klinik !== 0 && (
          <>
            <div class="card">
              <div class="card-body">
                <div className="col-md-12">
                  <div class="mb3 row p-3 ">
                    <label class="form-label col-md-2 ">
                      Başlangıç Tarihi <span className="req">*</span>{" "}
                    </label>

                    <div class="col-md-3">
                      <DateTimePicker
                        format="dd.MM.yyyy"
                        name="basTarihi"
                        class="form-control"
                        disableClock={true}
                        value={tarihAraligi.bas}
                        onChange={(date) => {
                          setTarihAraligi({
                            ...tarihAraligi,
                            bas: date,
                          });
                        }}
                      />
                    </div>

                    <label class="form-label col-md-2 ">
                      Bitiş Tarihi <span className="req">*</span>{" "}
                    </label>

                    <div class="col-md-3">
                      <DateTimePicker
                        format="dd.MM.yyyy"
                        minDate={tarihAraligi.bas}
                        name="bitisTarihi"
                        class="form-control"
                        disableClock={true}
                        value={tarihAraligi.bit}
                        onChange={(date) => {
                          setTarihAraligi({
                            ...tarihAraligi,
                            bit: date,
                          });
                        }}
                      />
                    </div>

                    <div class="col-md-2">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => onFiltrele()}
                      >
                        Filtrele
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div className="table-responsive b-0 fixed-solution">
                      {icerik && data && data.randevuList.length > 0 ? (
                        <>
                          <table className="table table-striped table-bordered randevusiratablosu">
                            <thead>
                              <tr className="">
                                <th rowSpan={3}>Dr/Psk Ad Soyad</th>
                                {data.uyeList &&
                                  data.uyeList.map((item, index) => (
                                    <th
                                      data-het={data.bakimList.length}
                                      key={item.id}
                                      colSpan={data.bakimList.length * 2}
                                    >
                                      {item.kullanici}
                                    </th>
                                  ))}
                              </tr>

                              <tr className="">
                                {data.uyeList &&
                                  data.uyeList.map((item, index) => (
                                    <>
                                      {data.bakimList &&
                                        data.bakimList.map((bkm, index) => (
                                          <>
                                            <td key={bkm.bId} colSpan={2}>
                                              {bkm.bakimAd}{" "}
                                            </td>
                                          </>
                                        ))}
                                    </>
                                  ))}
                              </tr>

                              <tr className="">
                                {data.uyeList &&
                                  data.uyeList.map((item, index) => (
                                    <>
                                      {data.bakimList &&
                                        data.bakimList.map((bkm, index) => (
                                          <>
                                            <td key={bkm.bId + "y"}>Yeni</td>
                                            <td key={bkm.bId + "i"}>İsime</td>
                                          </>
                                        ))}
                                    </>
                                  ))}
                              </tr>

                              {/* <tr className="">
                          {data.uyeList &&
                            data.uyeList.map((item, index) => (
                              <th key={item.id}>{item.kullanici}</th>
                            ))}
                        </tr> */}

                              {/* {data.bakimList &&
                          data.bakimList.map((bkm, index) => (
                            <>
                              <td key={bkm.bId}>{bkm.bakimAd}</td>
                              <td>İsmine</td>
                            </>
                          ))} */}
                            </thead>
                            <tbody>
                              {/* <tr className="siralamah1 table-success">
                          <td>Dr/Psk Ad Soyad</td>
                          <td
                            colSpan={
                              data.bakimList && data.bakimList.length * 2 + 2
                            }
                          >
                            POLİKLİNİK YETİŞKİN DOKTOR
                          </td>
                          <td>GEREKLİ</td>
                          <td>GR+KR</td>
                          <td>Top.Slot</td>
                          <td colSpan={10}> </td>
                        </tr> */}
                              {/* <tr className="siralamah2  table-warning">
                          <td>
                            <span> </span>
                          </td>
                          <td> Puan</td>
                          <td>Doktor </td>

                          {data.bakimList &&
                            data.bakimList.map((bkm, index) => (
                              <>
                                <td key={bkm.bId}>{bkm.bakimAd}</td>
                                <td>İsmine</td>
                              </>
                            ))}

                          <td>Top. </td>
                          <td>DOLULUK</td>
                          <td> FARK YÜZDE</td>
                          <td> Doluluk </td>
                          <td>Tüm.Doluluk</td>
                          <td> Not</td>
                          <td> Yüzde Oranı</td>
                          <td> Randevu</td>
                          <td>Kayıtlı Randevu </td>
                          <td>Gelen Randevu </td>
                          <td>Gereken </td>
                          <td>K SÜTUNA GÖRE</td>
                          <td>MANULE MÜDAHALE</td>
                        </tr> */}

                              {data &&
                                data.drList &&
                                data.drList.length > 0 && (
                                  <>
                                    {data.drList.map((item, index) => (
                                      <tr key={index}>
                                        <td>
                                          <Link
                                            to={"doktor-detay-" + item.drId}
                                          >
                                            <span class="text-muted mb-0">
                                              {item.tip}
                                            </span>{" "}
                                            {item.adSoyad}
                                          </Link>
                                        </td>
                                        {data.uyeList &&
                                          data.uyeList.map(
                                            (ituye, index) =>
                                              data.bakimList &&
                                              data.bakimList.map(
                                                (bkm, index) => (
                                                  <>
                                                    {UyeDrbkmSorg(
                                                      item.drId,
                                                      ituye.id,
                                                      bkm.bId
                                                    )}
                                                  </>
                                                )
                                              )
                                          )}
                                      </tr>
                                    ))}
                                  </>
                                )}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <h6 className="text-center">
                          Geçerli randevu kaydı bulunmuyor.
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RandevuList;
