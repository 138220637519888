import React from "react";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const BildirimDetay = ({detay,setDetay }) => {
  return (
    <Modal
      size="xl"
      show={detay != null ? true : false}
      onHide={() => setDetay()}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {" "}
          {detay && detay.mesaj.baslik}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> {detay && ReactHtmlParser(detay.mesaj.metin)}</Modal.Body>
    </Modal>
  );
};

export default BildirimDetay;
