import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { Hata } from "../../rolsControl";

const TestDetay = ({show, setshow}) => {
  const [modal, setmodal] = useState(false);

  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [detay, setdetay] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });

  useEffect(() => {
 
    if (show) {
   
      getList("/ubs/testDetails/" + show)
        .then((a) => {
          console.log(a);
          setdetay(a.data);

          setbuttonOk(false);
          setLoading(false);

          setmodal(true);
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoading(false);
          return;
        });
    }
  }, [show]);

  const handleClose = () => {
    setmodal(false);
    setshow();
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Test Detayı</Modal.Title>
        </Modal.Header>
        {detay ? (
          <>
            <Modal.Body>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Başlık</label>
                <div class="col-sm-10">{detay.baslik}</div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Kimlere uygulanabilir
                </label>
                <div class="col-sm-10">{detay.kimler}</div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Ölçtüğü davranış nitelik
                </label>
                <div class="col-sm-10">{detay.oDavNit}</div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Türü</label>
                <div class="col-sm-10">{detay.turu}</div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Süre</label>
                <div class="col-sm-10">{detay.sure}</div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Kapsamı</label>
                <div class="col-sm-10">{detay.kapsam}</div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Materyal</label>
                <div class="col-sm-10">{detay.materyal}</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && !loading ? (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Kapat
                  </Button>
                </>
              ) : (
                <SnipperLoading></SnipperLoading>
              )}

              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
        ) : (
          <SnipperLoading></SnipperLoading>
        )}
      </Modal>
    </>
  );
};

export default TestDetay;
