import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { dell } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SnipperLoading from "../../snipperLoading";
import { Hata } from "../../rolsControl";

const Delete = ({ did, setdid, name, setrefresh, setLoadingUst }) => {
  const [show, setShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });

  useEffect(() => {
    if (did) {
      setShow(true);
      setbuttonOk(false);
    }
  }, [did]);
  const handleClose = () => {setShow(false);setdid();};

  const onSubmit = () => {
    if (did) {
      console.log(did);


      setbuttonOk(true);
      setLoadingUst(true);
      var form_data = new FormData();

       

      dell("/ubs/terapiDelete/" + did)
        .then((a) => {
          setrefresh(a.data);
          console.log(a.data);
          setShow(false);
          setbuttonOk(false);
          setLoadingUst(false);
          seterrorS({
            uyari: { error: true, message: "Terapi bilgisi silindi.",tip: "success" },
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {did && (
        <Modal
          size="sm"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Terapi Sil
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{name}' terapi silinsin mi?</Modal.Body>{" "}
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Vazgeç
                </Button>
                <Button variant="danger" onClick={() => onSubmit()}>
                  Sil
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Delete;
