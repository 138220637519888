import React from "react";

const KlinikServisList = ({servis}) => {
  return (
    <div>
      {
        {
          0: '-',
          1: 'Ankara Meddata',
          2: 'İstanbul Meddata',
        }[servis]
      }
    </div>
  );
};

export default KlinikServisList;
