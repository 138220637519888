import React, { useEffect, useState } from "react";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import { Hata } from "../../components/rolsControl";

const Profil = () => {
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [profil, setProfil] = useState();

  useEffect(() => {
    getList("uybs/uyeProfilDetay")
      .then((a) => {
        setProfil(a.data);
        console.log(a.data)
      })
      .catch((error) => {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
      });
  }, []);

  return (
    <>
      {" "}
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form action="#" class="custom-validation" novalidate="">
                  <h1>Profilim </h1>

                  {profil && (
                    <div>
                      <div class="mb-3 position-relative">
                        <label class="form-label">Kullanıcı</label>  {profil.kullanici}
                        <div></div>
                      </div>

                      <div class="mb-3 position-relative">
                        <label class="form-label">Kad</label>  {profil.kad}
                        <div></div>
                      </div>

                      <div class="mb-3 position-relative">
                        <label class="form-label">Email</label>  {profil.email}
                      </div>

                      <div class="mb-3 position-relative">
                        <label class="form-label">Tel</label> {profil.tel}
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profil;
