import axios from "axios";

const instance = axios.create({});

const getList = (url, forms) => {
  if (forms) { 
    return instance.get(url, {
      params: forms,
      headers: { Authorization: "Bearer " + localStorage.getItem("user") },
    });
  } else {
    return instance.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("user") },
    });
  }
};

const post = (url, formData) => {
  return instance.post(url, formData, {
    headers: { Authorization: "Bearer " + localStorage.getItem("user") },
  });
};

const put = (url, formData) => {
  return instance.post(url, formData, {
    headers: { Authorization: "Bearer " + localStorage.getItem("user") },
  });
};

const dell = (url) => {
  return instance.get(url, {
    headers: { Authorization: "Bearer " + localStorage.getItem("user") },
  });
};

const postL = (url, formData) => {
  return instance.post(url, formData);
};

export { getList, post, put, dell, postL };
