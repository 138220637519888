export default function RolsControl(event) {
  try {
    const uscer = localStorage.getItem("rols");

    const deger = JSON.parse(uscer);

    return deger[event];
  } catch {
    return false;
  }
}
export function Hata(event) {
  try {
    console.log(event.response.status);
    if (event.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
     else if (event.response.status === 404) {
      console.log("404");
      console.log(event.response.data);
      console.log("404");
      if ((event.response.data.message = "rols")) {
        localStorage.setItem(
          "rols",
          JSON.stringify(event.response.data.roller)
        );
        window.location.reload();
      }
    }
  } catch {
    return null;
  }

  return null;
}
