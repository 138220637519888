import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";
import DoktorGrup from "../../components/tanimlar/doktor/doktorGrup";
import DoktorGunc from "../../components/tanimlar/doktor/doktorGunc";
import DoktorKlinik from "../../components/tanimlar/doktor/doktorKlinik";
import DoktorTerapi from "../../components/tanimlar/doktor/doktorTerapi";
import DoktorTest from "../../components/tanimlar/doktor/doktorTest";
import DoktorIlgi from "../../components/tanimlar/doktor/doktorIlgi";
import TestDetay from "../../components/tanimlar/testler/testDetay";
import Doktorfoto from "../../components/tanimlar/doktor/doktorFoto";
import DoktorBakimEkle from "../../components/tanimlar/doktor/doktorBakimEkle";
import DoktorBakimGunc from "../../components/tanimlar/doktor/doktorBakimGunc";
import DoktorBakimsil from "../../components/tanimlar/doktor/doktorBakimsil";
import DoktorSil from "../../components/tanimlar/doktor/doktorSil";

import RolsControl, { Hata } from "../../components/rolsControl";
import DoktorYaslar from "../../components/tanimlar/doktor/doktorYaslar";
const DoktorDetay = () => {
  let { id } = useParams();
  const history = useHistory();
  const [doktor, setdoktor] = useState();
  const [refresh, setrefresh] = useState();
  const [drsil, setdrsil] = useState();
  const [drgunc, setdrgunc] = useState();
  const [drgurup, setdrgurup] = useState();
  const [drfoto, setdrfoto] = useState();
  const [drklinik, setdrklinik] = useState();
  const [dryaslar, setdryaslar] = useState();

  const [drtest, setdrtest] = useState();
  const [drilgi, setdrilgi] = useState();
  const [drterapi, setdrterapi] = useState();

  const [testid, settestid] = useState();

  const [bakime, setbakime] = useState();
  const [bakimg, setbakimg] = useState();
  const [bakims, setbakims] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("/drbs/doktorDetails/" + id)
      .then((a) => {
        if (a.data) {
          setdoktor(a.data);
        } else {
          history.push("doktorlarlist");
        }
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            tip: "error",
            error: true,
            message: error.response.data.message,
          },
        });
      });
  }, []);

  useEffect(() => {
    if (refresh) {
      setdoktor(refresh);
      setrefresh();
    }
  }, [refresh]);

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      {doktor ? (
        <div class="container-fluid">
          {RolsControl("doktorGunc") && (
            <>
              <DoktorGunc
                show={drgunc}
                setshow={setdrgunc}
                setrefresh={setrefresh}
              ></DoktorGunc>
            </>
          )}
          {RolsControl("doktorKlGr") && (
            <>
              <DoktorGrup
                show={drgurup}
                setshow={setdrgurup}
                setrefresh={setrefresh}
              />
              <DoktorKlinik
                show={drklinik}
                setshow={setdrklinik}
                setrefresh={setrefresh}
              />
 
              <DoktorYaslar
                show={dryaslar}
                setshow={setdryaslar}
                setrefresh={setrefresh}
              />
            </>
          )}
          {RolsControl("doktorTanimlar") && (
            <>
              <DoktorTerapi
                show={drterapi}
                setshow={setdrterapi}
                setrefresh={setrefresh}
              />

              <DoktorTest
                show={drtest}
                setshow={setdrtest}
                setrefresh={setrefresh}
              />

              <DoktorIlgi
                show={drilgi}
                setshow={setdrilgi}
                setrefresh={setrefresh}
              />
            </>
          )}

          {RolsControl("doktorUcret") && (
            <>
              <DoktorBakimEkle
                show={bakime}
                setshow={setbakime}
                setrefresh={setrefresh}
              />

              <DoktorBakimGunc
                show={bakimg}
                setshow={setbakimg}
                setrefresh={setrefresh}
              />

              <DoktorBakimsil
                show={bakims}
                setshow={setbakims}
                setrefresh={setrefresh}
              />
            </>
          )}
          {RolsControl("doktorFoto") && (
            <>
              <Doktorfoto
                show={drfoto}
                setshow={setdrfoto}
                uid={id}
                foto={doktor.foto}
                setrefresh={setrefresh}
              />
            </>
          )}

          {RolsControl("doktorSil") && (
            <>
              <DoktorSil show={drsil} setshow={setdrsil} />
            </>
          )}

          <TestDetay show={testid} setshow={settestid} />

          <div class="row">
            <div class="col-lg-3">
              <div className="card">
                <div className="card-body drDetayPage">
                  {RolsControl("doktorSil") && (
                    <>
                      <div className="text-center altpadd">
                        <Link
                          to={"#"}
                          className="btn btn-danger"
                          onClick={() => setdrsil(id)}
                        >
                          <i className="fa fa-trash"></i> Sil
                        </Link>
                      </div>
                    </>
                  )}

                  <Link to={"#"} onClick={() => setdrfoto(true)}>
                    <div className="text-center">
                      {doktor.foto != null ? (
                        <img
                          className="me-3 rounded-circle avatarprofil"
                          src={"images/doktorlar/" + doktor.foto}
                          alt={doktor.adsoyad}
                        />
                      ) : (
                        <img
                          className="me-3 rounded-circle avatarprofil"
                          src={"images/profil.png"}
                          alt={doktor.doktor}
                        />
                      )}
                    </div>
                  </Link>
                  {!doktor.drDurum && (
                    <div
                      className="text-center"
                      style={{
                        paddin: "20px auto",
                        color: "red",
                        fontSize: "25px",
                      }}
                      title="Pasif"
                    >
                      <i className="ion ion-md-warning"></i>
                    </div>
                  )}
                  <div className="text-center">
                    <h4>
                      <Link to={"#"}>
                        <span className="text-muted mb-0">{doktor.tip} </span>
                        {doktor.doktor}
                      </Link>
                    </h4>
                    <Link to={"#"}>
                      <span className="text-muted mb-0">
                        {doktor.bolum && doktor.bolum.toUpperCase()}{" "}
                      </span>
                    </Link>{" "}
                    <br />
                    {doktor.drKodu && <> Dr Kod: {doktor.drKodu}</>}
                  </div>

                  <div>
                    <div className="drPadd">
                      <div>
                        <p>
                          <h6>
                            {RolsControl("doktorKlGr") && (
                              <>
                                <Link to={"#"} onClick={() => setdrklinik(id)}>
                                  <i className="fa fa-list"></i>
                                </Link>{" "}
                              </>
                            )}
                            Klinikler :
                          </h6>
                          {doktor.klinikler.length > 0 ? (
                            doktor.klinikler.map((a) => (
                              <>
                                {a.klinik} <br />
                              </>
                            ))
                          ) : (
                            <>Seçili bulunmuyor...</>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="drPadd">
                      <div>
                        <p>
                          <h6>
                            {RolsControl("doktorKlGr") && (
                              <>
                                <Link to={"#"} onClick={() => setdrgurup(id)}>
                                  <i className="fa fa-list"></i>
                                </Link>{" "}
                              </>
                            )}
                            Guruplar :{" "}
                          </h6>{" "}
                          <br />
                          {doktor.gruplar.length > 0 ? (
                            doktor.gruplar.map((a) => (
                              <>
                                {" "}
                                {a.grupAd} <br />
                              </>
                            ))
                          ) : (
                            <>Seçili bulunmuyor...</>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="drPadd">
                      <div>
                        <p>
                          <h6>
                            {RolsControl("doktorKlGr") && (
                              <>
                                <Link to={"#"} onClick={() => setdryaslar(id)}>
                                  <i className="fa fa-list"></i>
                                </Link>{" "}
                              </>
                            )}
                            Yaşlar :
                          </h6>
                          {doktor.yaslar.length > 0 ? (
                            doktor.yaslar.map((a) => (
                              <>
                                {a.yas} <br />
                              </>
                            ))
                          ) : (
                            <>Seçili bulunmuyor...</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9">
              <div className="row">
                <div className="col-md-12">
                  {" "}
                  <div className="card">
                    <div className="card-body">
                      <>
                        <div className="text-end">
                          {RolsControl("doktorGunc") && (
                            <Link to={"#"} onClick={(e) => setdrgunc(id)}>
                              <i class="mdi   mdi-cog"></i>
                            </Link>
                          )}
                        </div>
                      </>
                      <div className="text-center altpadd">
                        {RolsControl("randevuPuan") && (
                          <Link
                            to={"doktor-randevu-" + id}
                            className="btn btn-info"
                          >
                            <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
                            Randevu Slot ve Puanlar
                          </Link>
                        )}
                        {" | "}
                        {RolsControl("randevuPuan") && (
                          <Link
                            to={"doktor-randevu-" + id}
                            className="btn btn-secondary"
                          >
                            <i
                              class="fa fa-calendar-check"
                              aria-hidden="true"
                            ></i>{" "}
                            Randevu Dökümü
                          </Link>
                        )}
                      </div>

                      <div className="drdflex">
                        <div>
                          <p>
                            <strong>Tel: </strong>
                            {doktor.tel}
                          </p>
                        </div>
                        <div>
                          <p>
                            <strong>Email: </strong>
                            {doktor.email}
                          </p>
                        </div>
                        <div>
                          <p>
                            <strong>Uzmanlık: </strong>
                            {doktor.uzmanlik}
                          </p>
                        </div>
                        <div>
                          <p>
                            <strong>Lisans: </strong>
                            {doktor.lisans}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {RolsControl("doktorUcret") && (
                        <>
                          <div className="float-end">
                            <Link to={"#"} onClick={() => setbakime(id)}>
                              <i class="fa   fa-plus"></i>
                            </Link>
                          </div>
                        </>
                      )}

                      <h4 className="text-center">Ücret Listesi</h4>

                      <div className="text-center">
                        {doktor.bakimTipleri.length > 0 &&
                          doktor.bakimTipleri.map((a) => (
                            <span className="ucretbtn btn btn-secondary">
                              {a.bakimAd}
                            </span>
                          ))}
                      </div>

                      <div>
                        <div class="table-responsive">
                          <table class="table table-striped mb-0">
                            <tbody>
                              {doktor.bakimUcretlendirme.length > 0 ? (
                                doktor.bakimUcretlendirme.map((a, index) => (
                                  <>
                                    {RolsControl("doktorUcret") ? (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{a.bakim.bakimAd}</td>
                                        <td>
                                          {a.bakimDetay.aciklama.length > 0 ? (
                                            <>'{a.bakimDetay.aciklama}'</>
                                          ) : (
                                            <>-</>
                                          )}
                                        </td>
                                        <td>
                                          {a.bakimDetay.ucret
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}{" "}
                                          ₺
                                        </td>

                                        <td>
                                          {a.bakimDetay.durum ? (
                                            <i className="fa fa-check check"></i>
                                          ) : (
                                            <i className="fa fa-times times"></i>
                                          )}
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              setbakimg(a.bakimDetay.id)
                                            }
                                          >
                                            <i className="fa fa-edit"></i>
                                          </button>{" "}
                                          <button
                                            className="btn btn-danger  btn-sm"
                                            onClick={() =>
                                              setbakims(a.bakimDetay.id)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ) : (
                                      a.bakimDetay.durum && (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{a.bakim.bakimAd}</td>
                                          <td>
                                            {a.bakimDetay.aciklama.length >
                                            0 ? (
                                              <>'{a.bakimDetay.aciklama}'</>
                                            ) : (
                                              <>-</>
                                            )}
                                          </td>
                                          <td>
                                            {a.bakimDetay.ucret
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}{" "}
                                            ₺
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                ))
                              ) : (
                                <tr colspan="5">
                                  {" "}
                                  <h6 className="text-center">
                                    Ücret girişi yapılmadı.
                                  </h6>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row mt-5">
                        {" "}
                        <div className="col-lg-6 sutunlar">
                          <div>
                            <div>
                              {RolsControl("doktorTanimlar") && (
                                <>
                                  <div className="float-end">
                                    <Link
                                      to={"#"}
                                      onClick={() => setdrtest(id)}
                                    >
                                      <i class="mdi   mdi-cog"></i>
                                    </Link>
                                  </div>
                                </>
                              )}
                              <h4>Testler</h4>

                              {doktor.testler.length > 0 ? (
                                doktor.testler.map((a, index) => (
                                  <>
                                    <div className="drtanimgoster">
                                      {" "}
                                      {a.baslik}
                                    </div>{" "}
                                    <Link
                                      to={"#"}
                                      onClick={() => settestid(a.id)}
                                      className="text-end"
                                    >
                                      {" "}
                                      Detay
                                    </Link>
                                    <br />
                                  </>
                                ))
                              ) : (
                                <>Seçili bulunmuyor...</>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 sutunlar">
                          <div>
                            <div>
                              {RolsControl("doktorTanimlar") && (
                                <>
                                  <div className="float-end">
                                    <Link
                                      to={"#"}
                                      onClick={() => setdrterapi(id)}
                                    >
                                      <i class="mdi   mdi-cog"></i>
                                    </Link>
                                  </div>
                                </>
                              )}
                              <h4>Terapiler </h4>
                              {doktor.terapiler.length > 0 ? (
                                doktor.terapiler.map((a, index) => (
                                  <>
                                    <div className="drtanimgoster">
                                      {" "}
                                      {a.baslik}
                                    </div>{" "}
                                    <br />
                                  </>
                                ))
                              ) : (
                                <>Seçili bulunmuyor...</>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 sutunlar">
              <div className="card">
                <div className="card-body">
                  {RolsControl("doktorTanimlar") && (
                    <>
                      <div className="float-end">
                        <Link to={"#"} onClick={() => setdrilgi(id)}>
                          <i class="mdi   mdi-cog"></i>
                        </Link>
                      </div>
                    </>
                  )}
                  <h4 className="mb-4">İlgi Alanları</h4>
                  <div className="row ">
                    {doktor.ilgialanlari.length > 0 ? (
                      doktor.ilgialanlari.map((a, index) => (
                        <>
                          <div className="drtanimgoster col-md-6 m-2 mx-auto">
                            {a.baslik}
                          </div>{" "}
                          <br />
                        </>
                      ))
                    ) : (
                      <>Seçili bulunmuyor...</>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 sutunlar">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4">
                    Yönlendirme İstenmeyen İlgi Alanları{" "}
                  </h4>
                  <div className="row ">
                    {doktor.istemedigi.length > 0 ? (
                      doktor.istemedigi.map((a, index) => (
                        <>
                          <div className="drtanimgoster col-md-6 m-2 mx-auto red">
                            {a.baslik}
                          </div>{" "}
                          <br />
                        </>
                      ))
                    ) : (
                      <>Seçili bulunmuyor...</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SnipperLoading></SnipperLoading>
      )}
    </>
  );
};

export default DoktorDetay;
