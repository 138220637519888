import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { postL } from "../components/axiosComp";
import Hatamodal from "../components/hatamodal";
import SnipperLoading from "../components/snipperLoading";
const Login = () => {
  const [users, setusers] = useState({ kad: "", pass: "" });
  const history = useHistory();
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });
  const handleChange = (event) => {
    setusers({
      ...users,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    setbuttonOk(true);
    var form_data = new FormData();

    form_data.append("kad", users.kad);
    form_data.append("pass", users.pass);

    postL("/authe/giris", form_data)
      .then((a) => {
        console.log(a.data.roller);
        localStorage.setItem("user", a.data.token); 
        localStorage.setItem("rols", JSON.stringify(a.data.roller));

        history.push("/");
      })
    .catch(function (error) {
      
        seterrorS({
          uyari: {
            error: true,
            message: "Geçersiz kullanıcı adı veya şifre",
            tip: "error",
          },
        });
        setbuttonOk(false);
        return;
      });
  };
  return (
    <div class="account-pages my-5 pt-5">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            {" "}
            <div class="card">
              <div class="card-body">
                <div class="text-muted text-center mb-4">
                  <img src="images/logo-dark.png" alt="" height="70" />
                </div>
                <div class="p-3">
                  <h4 class="font-size-18 text-muted mt-2 text-center">
                    Hoşgeldiniz!
                  </h4>

                  <div class="mb-3">
                    <label class="form-label">Kad/Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="kad"
                      value={users.kad}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Şifre</label>
                    <input
                      value={users.pass}
                      onChange={handleChange}
                      type="password"
                      class="form-control"
                      name="pass"
                    />
                  </div>

                  <div class="mb-3 row mt-4">
                    {/* <div class="col-sm-6">
                      <div class="form-checkbox">
                        <input
                       
                          type="checkbox"
                          class="form-check-input me-1"
                          id="customControlInline"
                        />
                        <label
                          class="form-label form-check-label"
                          for="customControlInline"
                        >
                          Beni Hatırla
                        </label>
                      </div>
                    </div> */}
                    <div class="col-sm-12 text-end">
                      {buttonOk && <SnipperLoading></SnipperLoading>}
                      {!buttonOk && (
                        <button
                          onClick={() => onSubmit()}
                          class="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Giriş
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>Şifrenizi hatırlamıyorsanız sistem yöneticisine başvurun.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
