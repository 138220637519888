import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { post, dell } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";

import { Hata } from "../../rolsControl";
const Doktorfoto = ({ show, setshow, foto, uid, setrefresh }) => {
  const [modal, setmodal] = useState(false);
  const [modalsil, setmodalsil] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [gfoto, setgfoto] = useState();
  const [formValue, setformValue] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:""  },
  });

  useEffect(() => {
   

    if (show) {
      setmodalsil(false);
      setgfoto(foto);
      setmodal(true);
    }
  }, [show]);

  const handleClose = () => {
    setshow();
    setmodal(false);
  };

  const onSubmit = () => {
    setbuttonOk(true);

    var form_data = new FormData();

    form_data.append("file", formValue);

    post("/drbs/doktorDetailsGorsel/" + uid, form_data)
      .then((a) => {
        setrefresh(a.data);
        setbuttonOk(false);

        handleClose();
        seterrorS({
          uyari: { error: true, message: "Bilgiler güncellendi",tip:"success" },
        });
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);

        return;
      });
  };

  const onsil = () => {
    setbuttonOk(true);

    dell("/drbs/doktorDetailsGorselKaldir/" + uid)
      .then((a) => {
        setrefresh(a.data);
        setbuttonOk(false);
        setmodalsil(false);
        handleClose();
        seterrorS({
          uyari: { error: true, message: "Foto kaldırıldı",tip: "success"  },
        });
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);

        return;
      });
  };

  const handleChange = (event) => {
    let img = event.target.files[0];

    setformValue(img);

    console.log(formValue);
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {show && (
        <>
          <Modal show={modal} onHide={handleClose} size={"sm"}>
            <Modal.Header closeButton>
              <Modal.Title>Doktor Foto</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <div class="text-center">
                  {foto != null ? (
                    <img
                      className="me-3 rounded-circle avatarprofil"
                      src={"images/doktorlar/" + gfoto}
                      alt="Doktor Foto"
                    />
                  ) : (
                    <img
                      className="me-3 rounded-circle avatarprofil"
                      src={"images/profil.png"}
                      alt="Doktor Foto"
                    />
                  )}
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Foto</label>
                  <div class="col-sm-10">
                    <input
                      type="file"
                      name="file"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Kapat
                  </Button>
                  {foto && (
                    <Button variant="danger" onClick={() => setmodalsil(true)}>
                      <i className="fa fa-trash"> </i> Sil
                    </Button>
                  )}

                  <Button variant="success" onClick={() => onSubmit()}>
                    Güncelle
                  </Button>
                </>
              )}
              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </Modal>

          <Modal show={modalsil} onHide={() => setmodalsil(false)} size={"sm"}>
            <Modal.Header closeButton>
              <Modal.Title>Foto Kaldır</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>Fotograf kaldırılsın mı?</div>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setmodalsil(false)}
                  >
                    Vazgeç
                  </Button>

                  <Button variant="danger" onClick={() => onsil()}>
                    <i className="fa fa-trash"> </i> Sil
                  </Button>
                </>
              )}
              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Doktorfoto;
