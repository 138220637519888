import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, post } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import Calendar from "react-calendar";
import { Hata } from "../../rolsControl";
import DateTimePicker from "react-datetime-picker";

const DoktorGunc = ({ show, setshow, setrefresh }) => {
  const [modal, setmodal] = useState(false);

  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [drtip, setdrtip] = useState([]);
  const [bolumler, setbolumler] = useState([]);

  const [doktor, setdoktor] = useState({});

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: "" },
  });

  useEffect(() => {
    if (show) {
      getList("/drbs/doktorDetailsGuncGet/" + show)
        .then((a) => {
          console.log(a.data.doktor);
          setdrtip(a.data.drTipleriList);
          setbolumler(a.data.bolumler);

          a.data.doktor.dogumTarihi = new Date(a.data.doktor.dogumTarihi);

          setdoktor(a.data.doktor);

          setmodal(true);

          if (a.data.drTipleriList.length > 0) {
            setbuttonOk(false);
            setLoading(false);
          } else {
            seterrorS({
              uyari: {
                error: true,
                message: " Doktor Tipi' tanımlarını yapınız.",
              },
            });
          }
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoading(false);
          return;
        });
    }
  }, [show]);

  const onSubmit = () => {
    if (
      doktor.adSoyad.length < 3 ||
      doktor.lisans.length < 3 ||
      doktor.uzmanlik.length < 3
    ) {
      seterrorS({
        uyari: {
          error: true,
          message: "* seçili alanları doldurun.</br> .",
        },
      });
    } else {
      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in doktor) {
        // form_data.append(key, formValue[key]);
        if (key == "dogumTarihi") {
          form_data.append(key, doktor[key].toLocaleString("tr-TR"));
        } else {
          form_data.append(key, doktor[key]);
        }
      }

      post("/drbs/doktorDetailsUpdate/" + show, form_data)
        .then((a) => {
          setbuttonOk(false);
          console.log(a.data);
          setrefresh(a.data);
          seterrorS({
            uyari: {
              error: true,
              message: "Bilgileriniz Güncellendi",
              tip: "success",
            },
          });
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          return;
        });
    }
  };

  const handleClose = () => {
    setmodal(false);
    setshow();
  };

  const handleChange = (event) => {
    setdoktor({
      ...doktor,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <Modal show={modal} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Doktor Güncelle</Modal.Title>
        </Modal.Header>
        {doktor && (
          <>
            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Durum</label>
                  <div class="col-sm-10">
                    <select
                      onChange={handleChange}
                      className="form-control"
                      defaultValue={doktor.drDurum}
                      name="drDurum"
                    >
                      <option value={true}>Aktif</option>
                      <option value={false}>Pasif</option>
                    </select>
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Doktor Tip</label>
                  {loading && <SnipperLoading></SnipperLoading>}
                  {!loading && (
                    <div class="col-sm-10">
                      <select
                        className="form-control"
                        name="drTip"
                        onChange={handleChange}
                        defaultValue={doktor.drTip}
                      >
                        {drtip &&
                          drtip.map((a, index) => (
                            <option value={a.id} key={index} className={index}>
                              {a.tip}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Bölümler</label>
                  {loading && <SnipperLoading></SnipperLoading>}
                  {!loading && (
                    <div class="col-sm-10">
                      <select
                        className="form-control"
                        name="blId"
                        onChange={handleChange}
                        defaultValue={doktor.blId}
                      >
                        <option value={0}>Seçiniz</option>

                        {bolumler &&
                          bolumler.map((a, index) => (
                            <option value={a.id} key={index} className={index}>
                              {a.bolumAdi}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Doktor Kodu <span className="req">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input
                      name="doktorKodu"
                      type="text"
                      required
                      class="form-control"
                      data-parsley-minlength="3"
                      value={doktor.doktorKodu}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Ad Soyad <span className="req">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input
                      name="adSoyad"
                      type="text"
                      required
                      class="form-control"
                      data-parsley-minlength="3"
                      value={doktor.adSoyad}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Doğum Tarihi</label>
                  <div class="col-sm-10">
                    <DateTimePicker
                    format="dd.MM.yyyy"
                      name="dogumTarihi"
                      class="form-control"
                      value={doktor.dogumTarihi}
                      onChange={(date) => {
                        setdoktor({
                          ...doktor,
                          dogumTarihi: date,
                        });
                      }}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Tel</label>
                  <div class="col-sm-10">
                    <input
                      name="tel"
                      type="text"
                      class="form-control"
                      value={doktor.tel}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Email</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="email"
                      class="form-control"
                      value={doktor.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row  d-none">
                  <label class="form-label col-sm-2 "> Bakım Alt Yaş</label>
                  <div class="col-sm-10">
                    <input
                      type="number"
                      name="altYas"
                      class="form-control"
                      value={doktor.altYas}
                      onChange={handleChange}
                    />
                  </div>
                  <p class="m-0 text-muted">(Belirsiz ise 0 giriniz.)</p>
                </div>
                <div class="mb3 row  d-none">
                  <label class="form-label col-sm-2 ">Bakım Üst Yaş</label>
                  <div class="col-sm-10">
                    <input
                      type="number"
                      name="ustYas"
                      class="form-control"
                      value={doktor.ustYas}
                      onChange={handleChange}
                    />
                  </div>
                  <p class="m-0 text-muted">(Belirsiz ise 0 giriniz.)</p>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Uzmanlık<span className="req">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="uzmanlik"
                      class="form-control"
                      value={doktor.uzmanlik}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Lisans<span className="req">*</span>
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="lisans"
                      class="form-control"
                      value={doktor.lisans}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Açıklama</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="aciklama"
                      class="form-control"
                      value={doktor.aciklama}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && !loading ? (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Kapat
                  </Button>

                  <Button variant="success" onClick={() => onSubmit()}>
                    Kaydet
                  </Button>
                </>
              ) : (
                <SnipperLoading></SnipperLoading>
              )}

              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default DoktorGunc;
