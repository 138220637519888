import React, { useState, useEffect } from "react";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";
import { Hata } from "../../components/rolsControl";
import BildirimDetay from "./bildirimDetay";

const Bliste = () => {
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const [detay, setDetay] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  function listeDoldur() {
    getList("/uybs/tumBildirimler")
      .then((a) => {
        setData(a.data);
        setLoading(false);
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "error",
          },
        });

        setData([]);
        setLoading(false);
        return;
      });
  }

  
  useEffect(() => {
    listeDoldur();
  }, []);

  const onDetay = (id) => {
    getList("/uybs/uyeBildirimDetay/" + id)
      .then((a) => {
        setDetay(a.data);
        listeDoldur();
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "error",
          },
        });

        listeDoldur();
        return;
      });
  };

  return (
    <div className="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {/* <Insert
        show={show}
        setShow={setShow}
        setrefresh={setrefresh}
        setLoadingUst={setLoading}
      /> */}

      <div className="card">
        <div className="card-body">
          <h1>Bildirimlerim</h1>

          {loading && <SnipperLoading />}

          {!loading && (
            <>
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Başlık</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((a, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{a.mesaj.baslik}</td>
                          <td>
                            {a.icerik ? (
                              <span
                                className="btn btn-default"
                                onClick={() => onDetay(a.mesaj.id)}
                              >
                                <i className="fa fa-envelope-open"></i>{" "}
                              </span>
                            ) : (
                              <span
                                className="btn btn-info"
                                onClick={() => onDetay(a.mesaj.id)}
                              >
                                <i className="fa fa-envelope"></i>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      <BildirimDetay detay={detay} setDetay={setDetay}></BildirimDetay>
      {/* <Modal
        size="xl"
        show={detay != null ? true : false}
        onHide={() => setDetay()}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"> {detay&& detay.mesaj.baslik}</Modal.Title>
        </Modal.Header>
        <Modal.Body> {detay&& ReactHtmlParser(detay.mesaj.metin) }</Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Bliste;
