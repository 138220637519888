import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import Calendar from "react-calendar";
import { Hata } from "../../rolsControl";

const DoktorEkle = ({ show, setshow }) => {
  var history = useHistory();
  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [drtip, setdrtip] = useState();
  const [bolumler, setbolumler] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: "" },
  });

  const [formValue, setformValue] = useState({
    drTip: "",
    blId: 0,
    drDurum: true,
    adSoyad: "",
    dogumTarihi: new Date(),
    aciklama: "",
    lisans: "",
    uzmanlik: "",
    altYas: 0,
    ustYas: 0,
    tel: "",
    email: "",
    doktorkodu: "",
  });

  useEffect(() => {
    getList("/ubs/drOnList")
      .then((a) => {
        setdrtip(a.data.drTips);
        setbolumler(a.data.bolumler);

        if (a.data.drTips.length > 0 && a.data.bolumler.length > 0) {
          console.log(a.data.drTips[0].id);
          setformValue({
            ...formValue,
            drTip: a.data.drTips[0].id,
            blId: a.data.bolumler[0].id,
          });

          // setformValue({
          //   ...formValue,
          // });

          setbuttonOk(false);
          setLoading(false);
        } else {
          seterrorS({
            uyari: {
              error: true,
              message: "Bölümleri ve Doktor Tipi ' tanımlarını yapınız.",
            },
          });
        }
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);
        setLoading(false);
        return;
      });
  }, []);

  const onSubmit = () => {
    if (
      formValue.adSoyad.length < 3 ||
      formValue.lisans.length < 3 ||
      formValue.uzmanlik.length < 3
    ) {
      seterrorS({
        uyari: {
          error: true,
          message: "* seçili alanları doldurun.</br> .",
        },
      });
    } else {
      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in formValue) {
        // form_data.append(key, formValue[key]);
        if (key == "dogumTarihi") {
          form_data.append(key, formValue[key].toLocaleString("tr-TR"));
        } else {
          form_data.append(key, formValue[key]);
        }
      }

      put("/drbs/doktorAdd", form_data)
        .then((a) => {
          setbuttonOk(false);
          history.push("doktor-detay-" + a.data);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          return;
        });
    }
  };

  const handleClose = () => {
    setshow(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Doktor Oluştur</Modal.Title>
        </Modal.Header>
        <>
          <Modal.Body>
            {/* {formValue.drTip}-{formValue.blId} */}
            <form onSubmit={() => onSubmit()}>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Durum</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.durum}
                    name="drDurum"
                  >
                    <option value={true}>Aktif</option>
                    <option value={false}>Pasif</option>
                  </select>
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doktor Tip</label>
                {loading && <SnipperLoading></SnipperLoading>}
                {!loading && (
                  <div class="col-sm-10">
                    <select
                      className="form-control"
                      name="drTip"
                      onChange={handleChange}
                      defaultValue={formValue.drTip}
                    >
                      {drtip &&
                        drtip.map((a, index) => (
                          <option value={a.id} key={index} className={index}>
                            {a.tip}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Bölümü</label>
                {loading && <SnipperLoading></SnipperLoading>}
                {!loading && (
                  <div class="col-sm-10">
                    <select
                      className="form-control"
                      name="blId"
                      onChange={handleChange}
                      defaultValue={formValue.blId}
                    >
                      <option value={0}>Seçiniz</option>

                      {bolumler &&
                        bolumler.map((a, index) => (
                          <option value={a.id} key={index} className={index}>
                            {a.bolumAdi}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Doktor Kodu <span className="req">*</span>
                </label>
                <div class="col-sm-10">
                  <input
                    name="doktorkodu"
                    type="text"
                    required
                    class="form-control"
                    data-parsley-minlength="3"
                    value={formValue.doktorkodu}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Ad Soyad <span className="req">*</span>
                </label>
                <div class="col-sm-10">
                  <input
                    name="adSoyad"
                    type="text"
                    required
                    class="form-control"
                    data-parsley-minlength="3"
                    value={formValue.adSoyad}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Doğum Tarihi</label>
                <div class="col-sm-10">
                  <Calendar
                    name="dogumTarihi"
                    class="form-control"
                    value={formValue.dogumTarihi}
                    onChange={(date) => {
                      setformValue({
                        ...formValue,
                        dogumTarihi: date,
                      });
                    }}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Tel</label>
                <div class="col-sm-10">
                  <input
                    name="tel"
                    type="text"
                    class="form-control"
                    value={formValue.tel}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Email</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    value={formValue.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row d-none">
                <label class="form-label col-sm-2 "> Bakım Alt Yaş</label>
                <div class="col-sm-10">
                  <input
                    type="number"
                    name="altYas"
                    class="form-control"
                    value={formValue.altYas}
                    onChange={handleChange}
                  />
                </div>
                <p class="m-0 text-muted">(Belirsiz ise 0 giriniz.)</p>
              </div>
              <div class="mb3 row  d-none">
                <label class="form-label col-sm-2 ">Bakım Üst Yaş</label>
                <div class="col-sm-10">
                  <input
                    type="number"
                    name="ustYas"
                    class="form-control"
                    value={formValue.ustYas}
                    onChange={handleChange}
                  />
                </div>
                <p class="m-0 text-muted">(Belirsiz ise 0 giriniz.)</p>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Uzmanlık<span className="req">*</span>
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="uzmanlik"
                    class="form-control"
                    value={formValue.uzmanlik}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Lisans<span className="req">*</span>
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="lisans"
                    class="form-control"
                    value={formValue.lisans}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Açıklama</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="aciklama"
                    class="form-control"
                    value={formValue.aciklama}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button variant="secondary" onClick={handleClose}>
                Vazgeç
              </Button>
              {!buttonOk && !loading ? (
                <Button variant="success" onClick={() => onSubmit()}>
                  Kaydet
                </Button>
              ) : (
                <SnipperLoading></SnipperLoading>
              )}
            </>

            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default DoktorEkle;
