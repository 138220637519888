import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import {  put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";

import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";
const Insert = ({ show, setShow, setrefresh, setLoadingUst }) => {
  const handleClose = () => setShow(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });

  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [formValue, setformValue] = React.useState({
    tip: "",
    durum: true,
  });

  const onSubmit = () => {
    if (formValue.tip.length < 2) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      put("/ubs/drTipAdd", form_data)
        .then((a) => {
          setrefresh(a.data);
          setShow(false);
          setbuttonOk(false);
          seterrorS({
            uyari: { error: true, message: formValue.tip + " eklendi",tip:"success" },
          });
          


          setformValue({
            tip: "",
            durum: true,
          });


        
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Tip Oluştur</Modal.Title>
        </Modal.Header>
       
          <>
            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">
                    Tip <span className="req">*</span>{" "}
                  </label>
                  <div class="col-sm-10">
                    <input
                      name="tip"
                      type="text"
                      required
                      class="form-control"
                      data-parsley-minlength="3"
                      value={formValue.tip}
                      onChange={handleChange}
                    />
                  </div>
                </div>

              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                  <Button variant="success" onClick={() => onSubmit()}>
                    Kaydet
                  </Button>
                </>
              )}
              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </>
       
      </Modal>

      <SweetAlert showConfirm={false} showCancel={true} danger show={smShow} title="Uyarı"  onCancel={()=>setSmShow(false)}>
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Insert;
