import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";

import { getList, post } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";
const Update = ({ uid, setuid, setrefresh, setLoadingUst }) => {
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [formValue, setformValue] = React.useState({
    grupAd: "",
    aciklama: "",
    altY: 0,
    ustY: 0
  });

  useEffect(() => {
    if (uid) {
      getList("/ubs/gurupDetails/" + uid)
        .then((a) => {

        

          setformValue({
            grupAd: a.data.grupAd,
            aciklama: a.data.aciklama,
            altY: a.data.altY,
            ustY: a.data.ustY
          });
          setShow(true);
          setLoading(false);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  }, [uid]);

  const handleClose = () => {
    setShow(false);
    setuid();
  };

  const onSubmit = () => {
    if (formValue.grupAd.length < 3) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      post("/ubs/gurupDetailsUpdate/" + uid, form_data)
        .then((a) => {
          setrefresh(a.data);

          seterrorS({
            uyari: {
              error: true,
              message: "Bilgiler güncellendi",
              tip: "success",
            },
          });

          setShow(false);
          setbuttonOk(false);
          setLoadingUst(false);
          setuid();
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {uid && (
        <>
          <Modal show={show} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
              <Modal.Title>Bölüm Güncelle</Modal.Title>
            </Modal.Header>
            {loading && <SnipperLoading />}

            {!loading && (
              <>
                <Modal.Body>
                  <form onSubmit={() => onSubmit()}>

                  {/* <div class="mb3 row">
                      <label class="form-label col-sm-2 ">Durum</label>
                      <div class="col-sm-10">
                        <select
                          onChange={handleChange}
                          className="form-control"
                          defaultValue={formValue.durum}
                          name="durum"
                        >
                          <option value={true}>Evet</option>
                          <option value={false}>Hayır</option>
                        </select>
                      </div>
                    </div> */}


                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">
                        Bölüm Ad <span className="req">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input
                          name="grupAd"
                          type="text"
                          required
                          class="form-control"
                          data-parsley-minlength="3"
                          value={formValue.grupAd}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">Açıklama</label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="aciklama"
                          class="form-control"
                          value={formValue.aciklama}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">Alt Yaş </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="altY"
                          class="form-control"
                          value={formValue.altY}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">Üst Yaş </label>
                      <div class="col-sm-10">
                        <input
                          type="text"
                          name="ustY"
                          class="form-control"
                          value={formValue.ustY}
                          onChange={handleChange}
                        />
                      </div>
                    </div>



                  </form>
                </Modal.Body>
                <Modal.Footer>
                  {!buttonOk && (
                    <>
                      <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                      </Button>
                      <Button variant="success" onClick={() => onSubmit()}>
                        Kaydet
                      </Button>
                    </>
                  )}
                  {buttonOk && <SnipperLoading />}
                </Modal.Footer>
              </>
            )}
          </Modal>

          <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">Uyarı</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Lütfen <span className="req">*</span> işaretli alanları doldurun.
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Update;
