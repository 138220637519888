import React from "react";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div class="card">
      <div class="card-body">
        <div class="ex-page-content text-center">
          <h1 class="">Sayfa Bulunamadı</h1>
          <h4 class="">Aradığınız sayfa bulunamadı.</h4>
          <br />

          <Link class="btn btn-info mb-4 waves-effect waves-light" to="/">
            <i class="mdi mdi-home"></i>Ana Sayfaya Dön
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
