import React, { useState, useEffect } from "react";
import { Modal, Button, FormCheck } from "react-bootstrap";
import SnipperLoading from "../snipperLoading";
import { post } from "../axiosComp";
import { Link } from "react-router-dom";
import { Hata } from "../rolsControl";
import Hatamodal from "../hatamodal";
import { RandevuMudehaleSelect } from "../randevuMudehale";

const DoktorSlotvePuan = ({ drId, setdrId, setrefresh, icerik }) => {
  const [buttonOk, setbuttonOk] = useState(false);

  const [formValue, setformValue] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    if (drId) {
      console.clear();
      console.log(drId);
      setformValue(icerik);
    }
  }, [drId]);

  useEffect(() => {
    if (drId) {
      console.log(icerik);
    }
  }, [icerik]);

  const handleClose = () => {
    setdrId();

    setbuttonOk(false);

    setformValue();

    seterrorS({
      uyari: { error: false, message: "", tip: "" },
    });
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = () => {
    setbuttonOk(true);

    if (drId && formValue) {
      var form_data = new FormData();

      form_data.append("puan", formValue.puan);
      form_data.append("mudehale", formValue.mudehale);
      form_data.append("randevu", formValue.randevu);
      form_data.append("kayitliRandevu", formValue.kayitliRandevu);
      form_data.append("gelenRandevu", formValue.gelenRandevu);

      post("/drbs/drsiraDetayKaydet/" + drId, form_data)
        .then((a) => {
          setbuttonOk(false);
          handleClose();
          seterrorS({
            uyari: {
              tip: "success",
              error: true,
              message: "Güncelleme yapıldı.",
            },
          });

          setrefresh(a.data);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: "Hata oluştu.Kontrol edilemedi.",
            },
          });

          setbuttonOk(false);
          return;
        });
    } else {
      seterrorS({
        uyari: {
          tip: "error",
          error: true,
          message: "Hata oluştu.Kontrol edilemedi.",
        },
      });

      setbuttonOk(false);
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {drId && (
        <Modal
          show={drId ? true : false}
          onHide={() => handleClose()}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {new Date().getMonth() + 1 + "/" + new Date().getFullYear()} Sıra Bilgi Detay
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {formValue && (
              <>
                <div class="mb3 row">
                  <label class="form-label col-sm-4 ">
                  Müdehale <span className="req">*</span>
                  </label>
                  <div class="col-sm-8">
                    <RandevuMudehaleSelect
                      name="mudehale"
                      value={formValue.mudehale}
                      setvalue={handleChange}
                    ></RandevuMudehaleSelect>
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-4 ">
                    Puan <span className="req">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      name="puan"
                      type="number"
                      required
                      class="form-control"
                      value={formValue.puan}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-4 ">
                    Randevu <span className="req">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      name="randevu"
                      type="number"
                      required
                      class="form-control"
                      value={formValue.randevu}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-4 ">
                    Kayıtlı Randevu <span className="req">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      name="kayitliRandevu"
                      type="number"
                      required
                      class="form-control"
                      value={formValue.kayitliRandevu}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div class="mb3 row">
                  <label class="form-label col-sm-4 ">
                    Gelen Randevu <span className="req">*</span>
                  </label>
                  <div class="col-sm-8">
                    <input
                      name="gelenRandevu"
                      type="number"
                      required
                      class="form-control"
                      value={formValue.gelenRandevu}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Kaydet
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}

            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default DoktorSlotvePuan;
