import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { dell } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import { useHistory } from "react-router-dom";
import { Hata } from "../../rolsControl";

const DoktorSil = ({ show, setshow }) => {
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "" ,tip:null},
  });

  const [buttonOk, setbuttonOk] = useState(false);

  const history = useHistory();

  const onsil = () => {
    setbuttonOk(true);

    dell("/drbs/doktorDelete/" + show)
      .then((a) => {
        setbuttonOk(false);

        seterrorS({
          uyari: { error: true, message: "Doktor Silindi",tip: "success"  },
        });

        history.push("doktorlarlist");
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);

        return;
      });
  };
  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <Modal show={show} onHide={() => setshow()} size={"sm"}>
        <Modal.Header closeButton>
          <Modal.Title>Doktor Sil</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>Doktor kaldırılsın mı?</div>
        </Modal.Body>
        <Modal.Footer>
          {!buttonOk && (
            <>
              <Button variant="secondary" onClick={() => setshow()}>
                Vazgeç
              </Button>

              <Button variant="danger" onClick={() => onsil()}>
                <i className="fa fa-trash"> </i> Sil
              </Button>
            </>
          )}
          {buttonOk && <SnipperLoading />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DoktorSil;
