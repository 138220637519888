import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Hata } from "../../rolsControl";
import { getList } from "../../axiosComp";
import Hatamodal from "../../hatamodal";
import SnipperLoading from "../../snipperLoading";
import { Accordion } from "react-bootstrap";
import TestDetay from "../testler/testDetay";

const DoktorOnizle = ({ drid, setdrid }) => {
  const [doktor, setdoktor] = useState();
  const [testid, settestid] = useState();
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {
    if (drid) {
      getList("/drbs/doktorDetails/" + drid)
        .then((a) => {
          console.log(a.data);
          setdoktor(a.data);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
        });
    }
  }, [drid]);

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <TestDetay show={testid} setshow={settestid} />
      <Modal
        show={drid}
        onHide={() => setdrid()}
        aria-labelledby="example-modal-sizes-title-sm"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Doktor Önizleme
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!doktor && <SnipperLoading />}
          {doktor && (
            <>
              <div className="col-md-12 text-center">
                <Link to={"doktor-detay-" + drid}>
                  {doktor.foto != null ? (
                    <img
                      className="avatar-sm rounded-circle me-2"
                      src={"images/doktorlar/" + doktor.foto}
                      style={{ width: "100px", height: "100px" }}
                      alt={doktor.adSoyad}
                    />
                  ) : (
                    <img
                      className="avatar-sm rounded-circle me-2"
                      src={"images/profil.png"}
                      style={{ width: "100px", height: "100px" }}
                      alt={doktor.adSoyad}
                    />
                  )}
                </Link>{" "}
                <div>
                  {!doktor.drDurum && (
                    <div
                      className="text-center"
                      style={{
                        paddin: "20px auto",
                        color: "red",
                        fontSize: "25px",
                      }}
                      title="Pasif"
                    >
                      <i className="ion ion-md-warning"></i>
                    </div>
                  )}
                </div>
                <h6>
                  {" "}
                  <span className="text-muted mb-0">{doktor.tip} </span>
                  {doktor.doktor}{" "}
                </h6>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="drPadd col-md-6">
                    <div>
                      <p>
                        <h6>Klinikler :</h6>
                        {doktor.klinikler.length > 0 ? (
                          doktor.klinikler.map((a) => (
                            <>
                              {a.klinik} <br />
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="drPadd col-md-6">
                    <div>
                      <p>
                        <h6>Guruplar : </h6>
                        {doktor.gruplar.length > 0 ? (
                          doktor.gruplar.map((a) => (
                            <>
                              {a.grupAd} <br />
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="col-md-12">
                <h4 className="text-center">Ücret Listesi</h4>

                <div className="text-center">
                  {doktor.bakimTipleri.length > 0 &&
                    doktor.bakimTipleri.map((a) => (
                      <span className="ucretbtn btn btn-secondary">
                        {a.bakimAd}
                      </span>
                    ))}
                </div>

                <div>
                  <div class="table-responsive">
                    <table class="table table-striped mb-0">
                      <tbody>
                        {doktor.bakimUcretlendirme.length > 0 ? (
                          doktor.bakimUcretlendirme.map((a, index) => (
                            <>
                              {a.bakimDetay.durum && (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{a.bakim.bakimAd}</td>
                                  <td>
                                    {a.bakimDetay.aciklama.length > 0 ? (
                                      <>'{a.bakimDetay.aciklama}'</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {a.bakimDetay.ucret
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                    ₺
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        ) : (
                          <tr colspan="5">
                            {" "}
                            <h6 className="text-center">
                              Ücret girişi yapılmadı.
                            </h6>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr></hr>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <p>
                      <strong>Tel: </strong>
                      {doktor.tel}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <strong>Email: </strong>
                      {doktor.email}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <strong>Uzmanlık: </strong>
                      {doktor.uzmanlik}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p>
                      <strong>Lisans: </strong>
                      {doktor.lisans}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <Accordion>
                  <Accordion.Item eventKey={1}>
                    {" "}
                    <Accordion.Header>
                      <h6 className="mt-4 baslikk">Testler</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <div className="row margin">
                        {" "}
                        {doktor.testler.length > 0 ? (
                          doktor.testler.map((a, index) => (
                            <>
                              <div className="drtanimgoster col-md-12 col-md-6 col-lg-6">
                                {" "}
                                {a.baslik}
                                <Link
                                  to={"#"}
                                  onClick={() => settestid(a.id)}
                                  className="text-end"
                                >
                                  {" "}
                                  Detay
                                </Link>
                              </div>
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={2}>
                    {" "}
                    <Accordion.Header>
                      <h6 className="mt-4 baslikk">Terapiler</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      {" "}
                      <div className="row margin">
                        {" "}
                        {doktor.terapiler.length > 0 ? (
                          doktor.terapiler.map((a, index) => (
                            <>
                              <div className="drtanimgoster col-md-12 col-md-6 col-lg-6">
                                {" "}
                                {a.baslik}
                              </div>{" "}
                              <br />
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={3}>
                    <Accordion.Header>
                      <h6 className="mt-4 baslikk">İlgi Alanları</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row margin">
                        {" "}
                        {doktor.ilgialanlari.length > 0 ? (
                          doktor.ilgialanlari.map((a, index) => (
                            <>
                              <div className="drtanimgoster  col-md-12  col-md-6 col-lg-6">
                                {" "}
                                {a.baslik}
                              </div>{" "}
                              <br />
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={4}>
                    <Accordion.Header>
                      <h6 className="mt-4 baslikk">İstemediği Alanları</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row margin">
                        {" "}
                        {doktor.istemedigi.length > 0 ? (
                          doktor.istemedigi.map((a, index) => (
                            <>
                              <div className="drtanimgoster  col-md-12  col-md-6 col-lg-6 red">
                                {" "}
                                {a.baslik}
                              </div>{" "}
                              <br />
                            </>
                          ))
                        ) : (
                          <>Seçili bulunmuyor...</>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button variant="secondary" onClick={() => setdrid()}>
              Kapat
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DoktorOnizle;
