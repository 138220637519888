import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";

import { getList, post } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";

const Update = ({ uid, setuid, setrefresh, setLoadingUst }) => {
  const [show, setShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [formValue, setformValue] = React.useState({
    yas: "",
  });

  useEffect(() => {
    if (uid) {
      getList("/ubs/yaslarDetails/" + uid)
        .then((a) => {
          setformValue({
            yas: a.data.yas,
          });
          setShow(true);
          setLoading(false);
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  }, [uid]);

  const handleClose = () => {
    setShow(false);
    setuid();
  };

  const onSubmit = () => {
    if (formValue.yas.length < 3) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      post("/ubs/yaslarDetailsUpdate/" + uid, form_data)
        .then((a) => {
          setuid();

          setrefresh(a.data);

          setShow(false);
          setbuttonOk(false);
          setLoadingUst(false);
          seterrorS({
            uyari: {
              error: true,
              message: "Bilgiler güncellendi",
              tip: "success",
            },
          });
        })
        .catch(function (error) {
          Hata(error);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {uid && (
        <>
          <Modal show={show} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
              <Modal.Title>Yaş Tip Güncelle</Modal.Title>
            </Modal.Header>
            {loading && <SnipperLoading />}

            {!loading && (
              <>
                <Modal.Body>
                  <form onSubmit={() => onSubmit()}>
                    <div class="mb3 row">
                      <label class="form-label col-sm-2 ">
                        Yaş <span className="req">*</span>
                      </label>
                      <div class="col-sm-10">
                        <input
                          name="yas"
                          type="text"
                          required
                          class="form-control"
                          data-parsley-minlength="3"
                          value={formValue.yas}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  {!buttonOk && (
                    <>
                      <Button variant="secondary" onClick={handleClose}>
                        Vazgeç
                      </Button>
                      <Button variant="success" onClick={() => onSubmit()}>
                        Kaydet
                      </Button>
                    </>
                  )}
                  {buttonOk && <SnipperLoading />}
                </Modal.Footer>
              </>
            )}
          </Modal>

          <SweetAlert
            showConfirm={false}
            showCancel={true}
            danger
            show={smShow}
            title="Uyarı"
            onCancel={() => setSmShow(false)}
          >
            Lütfen <span className="req">*</span> işaretli alanları doldurun.
          </SweetAlert>
        </>
      )}
    </>
  );
};

export default Update;
