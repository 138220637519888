import React, { useState, useEffect, useRef } from "react";
import { getList, post } from "../../components/axiosComp";
import SnipperLoading from "../../components/snipperLoading";
import { Accordion, FormCheck } from "react-bootstrap";
import DoktorEkle from "../../components/tanimlar/doktor/doktorEkle";
import RolsControl, { Hata } from "../../components/rolsControl";
import RandevuOnEkran from "../../components/randevular/randevuOnEkran";
import Hatamodal from "../../components/hatamodal";
import { Link } from "react-router-dom";
import { randevuMudehale } from "../../components/randevuMudehale";
import YeniFiltre from "../../components/drfiltre/yenifiltre";
import Filtreler from "../../components/drfiltre/filtreler";
import DoktorOnizle from "../../components/tanimlar/doktor/doktorOnizle";

const DoktorIndex = () => {
  var yaslarListe = require("../../yaslar.json");

  const [drEkleShow, setdrEkleShow] = useState();
  const [buttonOk, setbuttonOk] = useState(false);
  const [randevuEkran, setrandevuEkran] = useState();

  const [data, setdata] = useState([]);
  const [filtre, setfiltre] = useState();

  const [loading, setloading] = useState(true);
  const [ad, setad] = useState("");
  const [listeS, setlisteS] = useState(1);
  const [aramaDetay, setaramaDetay] = useState();
  const [yasAraligi, setyasAraligi] = useState({ alt: "0", ust: "0" });

  const [yeniFiltre, setYeniFiltre] = useState(false);
  const [filtreTabSec, setFiltreTabSec] = useState(false);
  const [secfiltre, setSecfiltre] = useState();

  const [doktorOn, setDoktorOn] = useState();

  const [anlikfiltre, setAnlikfiltre] = useState(true);

  const scrollRef = useRef(null);

  function scrollToElement() {
    // scrollRef'in yüksekliği ve pozisyonunu alın
    const element = scrollRef.current;
    if (element) {
      const topOffset = element.getBoundingClientRect().top;

      // Pencereyi hedeflenen öğenin hizasına kaydırın
      window.scrollTo({
        top: window.scrollY + topOffset,
        behavior: "smooth", // İsteğe bağlı olarak animasyonlu kaydırma
      });
    }
  }

  const [secRT, setSecRT] = useState();
  const [sekmeler, setsekmeler] = useState({
    klinik: true,
    bakim: true,
    grup: true,
  });

  const [textFiltre, setTextFiltre] = useState({
    klinikler: "",
    guruplar: "",
    yaslar: "",
    ilgi: "",
    terapiler: "",
    testler: "",
  });

  // useEffect(() => {
  //   console.log(textFiltre);
  // }, [textFiltre]);

  const [ara, setAra] = useState({
    // bakim: [],
    // drTip: [],
    // iller: [],
    yaslar: [],
    klinikler: [],
    guruplar: [],
    ilgi: [],
    terapiler: [],
    testler: [],
  });

  const [sonKlinik, setsonKlinik] = useState([]);

  useEffect(() => {
    if (anlikfiltre === true) {
      onSubmit(true);
    }
  }, [yasAraligi]);

  useEffect(() => {
    if (ara && ara.klinikler) {
      if (ara.klinikler !== sonKlinik) {
        setsonKlinik(ara.klinikler);
        onSubmit(true);
      }
    }

    if (anlikfiltre === true) {
      onSubmit(true);
    }
  }, [ara]);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const aramalariSifirla = (e) => {
    setAra({
      // bakim: [],
      // drTip: [],
      // iller: [],
      yaslar: [],
      klinikler: [],
      guruplar: [],
      ilgi: [],
      terapiler: [],
      testler: [],
    });

    setyasAraligi({ alt: "0", ust: "0" });
    setaramaDetay();

    tumDoktorlar();
  };

  // bakim: [],
  // drTip: [],
  // iller: [],
  const secimleriTemizle = (e) => {
    setAra({
      yaslar: [],
      klinikler: [],
      guruplar: [],
      ilgi: [],
      terapiler: [],
      testler: [],
    });

    setyasAraligi({ alt: "0", ust: "0" });
  };

  function tumDoktorlar() {
    setaramaDetay();
    setloading(true);

    // getList("/drbs/doktorList")
    //   .then((a) => {
    //     setdata(a.data);
    //     setloading(false);
    //   })
    //   .catch(function (error) {
    //     Hata(error);

    //     setloading(false);
    //     seterrorS({
    //       uyari: {
    //         error: true,
    //         message: error.response.data.message,
    //         tip: "danger",
    //       },
    //     });
    //   });

    getList("/ubs/tumFiltreler")
      .then((te) => {
        setfiltre(te.data);
        scrollToElement();
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
      });
  }

  useEffect(() => {
    tumDoktorlar();
  }, []);

  const onSecim = (id, event) => {
    // klinikler

    var durum = ara[event].includes(id);
    var yeni;
    if (event === "klinikler") {
      if (
        ara &&
        ara.klinikler &&
        (ara.klinikler.length === 0 || ara.klinikler[0] !== id)
      ) {
        setAra({
          ...ara,
          [event]: [id],
        });
      }
    } else {
      if (!durum) {
        yeni = [...ara[event], id];

        setAra({
          ...ara,
          [event]: yeni,
        });
      } else if (durum) {
        yeni = ara[event].filter((a) => a !== id);

        setAra({
          ...ara,
          [event]: yeni,
        });
      }
    }
  };

  useEffect(() => {
    if (secfiltre) {
      var form_data = new FormData();

      form_data.append("dizi", secfiltre);

      post("/drbs/doktorListAra", form_data)
        .then((a) => {
          setloading(false);

          setdata(a.data.doktor);
          setbuttonOk(false);
          setaramaDetay(a.data.filtre);
          setSecfiltre();
          scrollToElement();
        })
        .catch(function (error) {
          Hata(error);
          tumDoktorlar();

          setSecfiltre();
          setbuttonOk(false);

          seterrorS({
            uyari: {
              error: true,
              message: error.response.data.message,
              tip: "danger",
            },
          });
        });
    }
  }, [secfiltre]);

  const onSubmit = (durum) => {
    setbuttonOk(true);
    setloading(true);
    setdata([]);
    var form_data = new FormData();

    var output = { ...ara, ...yasAraligi };

    form_data.append("dizi", JSON.stringify(output));

    post("/drbs/doktorListAra", form_data)
      .then((a) => {
        setloading(false);

        setdata(a.data.doktor);
        setbuttonOk(false);
        setaramaDetay(a.data.filtre);
        scrollToElement();
      })
      .catch(function (error) {
        Hata(error);
        tumDoktorlar();

        setbuttonOk(false);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
      });
  };

  const onIsimSubmit = () => {
    setbuttonOk(true);

    setloading(true);
    setdata([]);

    var form_data = new FormData();
    form_data.append("ad", ad);
    post("/drbs/doktorListisimAra", form_data)
      .then((a) => {
        setloading(false);

        setdata(a.data);
        setbuttonOk(false);
        setaramaDetay();
      })
      .catch(function (error) {
        Hata(error);
        tumDoktorlar();
        setbuttonOk(false);
        seterrorS({
          uyari: {
            error: true,
            message: error.response.data.message,
            tip: "danger",
          },
        });
      });
  };

  const handleChange = (event) => {
    setsekmeler({
      ...sekmeler,
      [event.target.name]: event.target.checked,
    });
  };

  const yasBolumFunc = (alt, ust) => {
    var YasAlt = parseInt(yasAraligi.alt),
      YasUst = parseInt(yasAraligi.ust),
      gAlt = parseInt(alt),
      gUst = parseInt(ust);

    if (YasAlt > 0 || YasUst > 0) {
      if (YasUst >= gUst && YasAlt <= gUst && gUst > 0) {
        return;
      } else if (YasUst >= gAlt && YasAlt <= gAlt && gAlt > 0) {
        return;
      } else if (
        YasAlt === 0 &&
        ((gAlt > 0 && gAlt < YasUst) || (gAlt === 0 && gUst > 0))
      ) {
        return;
      } else if (
        YasUst === 0 &&
        (YasAlt <= gUst || YasAlt <= gAlt || (gUst === 0 && gAlt > 0))
      ) {
        return;
      }
      return " kapali ";
    }
  };

  return (
    <>
      <YeniFiltre
        ekle={yeniFiltre}
        setekle={setYeniFiltre}
        filtreListesi={aramaDetay}
      ></YeniFiltre>

      <Filtreler
        show={filtreTabSec}
        setshow={setFiltreTabSec}
        setSecfiltre={setSecfiltre}
      ></Filtreler>

      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <DoktorEkle show={drEkleShow} setshow={setdrEkleShow}></DoktorEkle>
      <RandevuOnEkran
        doktor={randevuEkran}
        setdoktor={setrandevuEkran}
        secRT={secRT}
        setSecRT={setSecRT}
      ></RandevuOnEkran>

      <DoktorOnizle drid={doktorOn} setdrid={setDoktorOn} />
      {/* container-fluid */}
      <div className="">
        <div className="row">
          <div
            className={RolsControl("doktorEkle") ? "col-lg-10" : "col-lg-12"}
          >
            <div className="card">
              <div className="card-body">
                <form onSubmit={() => onIsimSubmit}>
                  <div className="d-flex">
                    <div className="flex-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Doktor arama..."
                        value={ad}
                        onChange={(e) => setad(e.target.value)}
                      ></input>
                    </div>

                    <div className="flex-2">
                      {!buttonOk && (
                        <>
                          <button
                            className="btn btn-info"
                            onClick={() => onIsimSubmit()}
                          >
                            <i className="fa fa-search"></i> Ara
                          </button>
                        </>
                      )}
                      {buttonOk && (
                        <>
                          <button disabled="disabled" className="btn btn-info">
                            <i className="fa fa-search"></i> Ara
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {RolsControl("doktorEkle") && (
            <div className="col-lg-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-1 text-center">
                      <button
                        className="btn btn-success m-1"
                        onClick={() => setdrEkleShow(true)}
                      >
                        <i className="fa fa-plus"></i> Doktor Oluştur
                      </button>
                      <button
                        title="Temizle"
                        className="filtreleriTemizle btn btn-danger m-1"
                        onClick={() => secimleriTemizle()}
                      >
                        <i class="mdi mdi-reload" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="card drcard">
          <div className="card-body">
            {/* <table className="table mt-4 mb-0 table-centered table-nowrap" style={{ display: "None" }}>
                    <tr>
                      <th>
                        <FormCheck
                          type="switch"
                          name="grup"
                          onChange={handleChange}
                          checked={sekmeler.grup}
                        ></FormCheck>
                        Grup
                      </th>
                      <th>
                        <FormCheck
                          type="switch"
                          name="bakim"
                          onChange={handleChange}
                          checked={sekmeler.bakim}
                        ></FormCheck>
                        Randevu Türü
                      </th>
                      <th>
                        <FormCheck
                          type="switch"
                          name="klinik"
                          onChange={handleChange}
                          checked={sekmeler.klinik}
                        ></FormCheck>
                        Klinik
                      </th>
                    </tr>
                  </table> */}

            {/* Klinik Filtre */}

            <div className="row">
              {filtre &&
                filtre.klinikler &&
                filtre.klinikler.map((a) => (
                  <div
                    class={
                      "col-md-" +
                      (filtre.klinikler.length % 4 === 0
                        ? "3"
                        : filtre.klinikler.length % 3 === 0
                        ? "4"
                        : "3")
                    }
                  >
                    <Link
                      to="#"
                      onClick={() => onSecim(a.id, "klinikler")}
                      className={
                        ara &&
                        ara.klinikler &&
                        ara.klinikler.length > 0 &&
                        ara.klinikler[0] === a.id &&
                        "secilenKat"
                      }
                    >
                      <h6 className="text-center klinikFiltrele">
                        {a.sehir.toUpperCase()} - {a.basl}
                      </h6>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div ref={scrollRef}>
          {((ara && ara.klinikler && ara.klinikler.length > 0) ||
            (ad && ad.length > 0)) && (
            <>
              <div className="col-lg-12">
                <div className="email-leftbar ml-3 ">
                  <Accordion defaultActiveKey={0}>
                    <div className="row">
                      <div className="col-md-12 d-flex justify-content-center">
                        <div className="text-center">
                          <button
                            className="filtreyiGetir btn btn-info margin"
                            title="Seç"
                            onClick={() => setFiltreTabSec(true)}
                          >
                            <i class="mdi mdi-server" aria-hidden="true"></i>
                          </button>
                        </div>

                        <div className="text-center  ilkH">
                          {!buttonOk && (
                            <>
                              <button
                                className="btn btn-danger rounded-pill btn-custom btn-block waves-effect waves-light"
                                onClick={() => onSubmit(true)}
                              >
                                Filtrele
                              </button>
                            </>
                          )}
                          {buttonOk && (
                            <>
                              <button
                                disabled="disabled"
                                className="btn btn-danger rounded-pill btn-custom btn-block waves-effect waves-light"
                              >
                                ...
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="filtre-box-sc">
                    <h6 className="mt-4 baslikk">Yaş Aralığı</h6>
                    <div className="filtre-box">
                      {" "}
                      {yaslarListe &&
                        yaslarListe.map((a) => (
                          <Link
                            to={"#"}
                            // className={ Object.entries(aitem)[1][1].toLocaleLowerCase().includes()}active
                            onClick={() => {
                              yasAraligi.alt === a.alt &&
                              yasAraligi.ust === a.ust
                                ? setyasAraligi({ alt: "0", ust: "0" })
                                : setyasAraligi({ alt: a.alt, ust: a.ust });
                            }}
                            className={
                              "ilkH filtitem " +
                              (yasAraligi.alt === a.alt &&
                                yasAraligi.ust === a.ust &&
                                "active")
                            }
                            // onClick={() => {
                            //   onSecim(
                            //     Object.entries(aitem)[0][1],
                            //     item[0]
                            //   );
                            // }}
                          >
                            {a.alt} - {a.ust} YAŞ
                          </Link>
                        ))}
                    </div>
                  </div> */}
                    {/* <h6 className="mt-4 baslikk text-center">Yaş Aralığı</h6>
                  <div className="text-center">
                    Alt-Üst<span className="text-muted"></span>{" "}
                  </div>
                  <div className="yassFiltre">
                    <input
                      type="number"
                      value={yasAraligi.alt}
                      onChange={(e) =>
                        setyasAraligi({ ...yasAraligi, alt: e.target.value })
                      }
                      className="form-control yasaraligi"
                    />{" "}
                    -
                    <input
                      type="number"
                      value={yasAraligi.ust}
                      onChange={(e) =>
                        setyasAraligi({ ...yasAraligi, ust: e.target.value })
                      }
                      className="form-control yasaraligi"
                    />
                  </div> */}

                    {filtre &&
                      Object.entries(filtre).map((item, index) => (
                        <>
                          {item[0] !== "klinikler" && (
                            <div className="filtre-box-sc">
                              <h6 className="mt-4 baslikk">
                                {item[0].toUpperCase() === "GURUPLAR"
                                  ? "BÖLÜM"
                                  : item[0]}
                              </h6>

                              <div className="">
                                <input
                                  type="text"
                                  className="form-control filtre-text-sc"
                                  onChange={(a) =>
                                    setTextFiltre({
                                      ...textFiltre,
                                      [item[0].toLocaleLowerCase()]:
                                        a.target.value,
                                    })
                                  }
                                  placeholder={
                                    (item[0] === "GURUPLAR"
                                      ? "BÖLÜM"
                                      : item[0]) + " ara..."
                                  }
                                ></input>
                              </div>
                              <div className="filtre-box">
                                {item[1].map((aitem) => (
                                  <Link
                                    to={"#"}
                                    // className={ Object.entries(aitem)[1][1].toLocaleLowerCase().includes()}

                                    className={
                                      "ilkH filtitem " +
                                      (ara[item[0]].includes(
                                        Number(Object.entries(aitem)[0][1])
                                      ) && " active ") +
                                      (!(
                                        Object.entries(aitem)[1][1]
                                          .toLowerCase()
                                          .includes(
                                            textFiltre[item[0].toLowerCase()]
                                          ) &&
                                        Object.entries(aitem)[1][1].length > 0
                                      ) && " kapali ") +
                                      (item[0].toUpperCase() === "GURUPLAR" &&
                                        yasBolumFunc(
                                          Object.entries(aitem)[2][1],
                                          Object.entries(aitem)[3][1]
                                        ))
                                    }
                                    onClick={() => {
                                      onSecim(
                                        Object.entries(aitem)[0][1],
                                        item[0]
                                      );
                                    }}
                                  >
                                    {Object.entries(aitem)[1][1].toLowerCase()}

                                    <>
                                      {" "}
                                      {item[0].toUpperCase() === "GURUPLAR" &&
                                        yasBolumFunc(
                                          Object.entries(aitem)[2][1],
                                          Object.entries(aitem)[3][1]
                                        )}
                                    </>

                                    {/* (yasAraligi.alt !== 0 &&
                                      Object.entries(aitem)[2][1] !== 0 &&
                                      Object.entries(aitem)[2][1] <=
                                        yasAraligi.alt
                                      ? " kapali "
                                      : " ")+(
                                      yasAraligi.ust !== 0 &&
                                        Object.entries(aitem)[3][1] !== 0 &&
                                        Object.entries(aitem)[3][1] <=
                                          yasAraligi.ust? " kapali "
                                        : " "
                                    ) */}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          )}
                        </>
                      ))}

                    {/* <div className="filtre-box-sc">
                      <h4></h4>
                      <div className="filtre-box"></div>
                    </div> */}

                    {/* {filtre &&
                      Object.entries(filtre).map((item, index) => (
                        <Accordion.Item eventKey={index}>
                          {item[0] !== "klinikler" && (
                            <>
                              <Accordion.Header>
                                <h6 className="mt-4 baslikk">
                                  {" "}
                                  {item[0].toUpperCase() === "GURUPLAR"
                                    ? "BÖLÜM"
                                    : item[0]}
                                </h6>
                              </Accordion.Header>
                              <Accordion.Body className="mail-list">
                                {item[1].map((aitem) => (
                                  <Link
                                    to={"#"}
                                    className={
                                      "ilkH filtitem " +
                                      (ara[item[0]].includes(
                                        Object.entries(aitem)[0][1]
                                      ) && "active")
                                    }
                                    onClick={() => {
                                      console.log(aitem);
                                      console.log(item);
                                      onSecim(
                                        Object.entries(aitem)[0][1],
                                        item[0]
                                      );
                                    }}
                                  >
                                    {Object.entries(aitem)[1][1]}
                                  </Link>
                                ))}
                              </Accordion.Body>
                            </>
                          )}
                        </Accordion.Item>
                      ))} */}
                  </Accordion>

                  <div className="text-center  ilkH">
                    {!buttonOk && (
                      <>
                        <button
                          className="btn btn-danger rounded-pill btn-custom btn-block waves-effect waves-light"
                          onClick={() => onSubmit(true)}
                        >
                          Filtrele
                        </button>
                      </>
                    )}
                    {buttonOk && (
                      <>
                        {" "}
                        <button
                          disabled="disabled"
                          className="btn btn-danger rounded-pill btn-custom btn-block waves-effect waves-light"
                        >
                          ...
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="email-rightbar mb-3 ">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          {aramaDetay && (
                            <>
                              {" "}
                              <button
                                className="filtreleriTemizle btn btn-danger"
                                onClick={() => aramalariSifirla()}
                              >
                                <i
                                  class="mdi mdi-reload"
                                  aria-hidden="true"
                                ></i>{" "}
                                Temizle
                              </button>
                              <button
                                onClick={() => setYeniFiltre(true)}
                                className="filtreyiKaydet btn btn-success margin"
                                title="Kaydet"
                              >
                                <i
                                  class="mdi mdi-floppy"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              {Object.entries(aramaDetay).map((item, index) => (
                                <>
                                  {item[0] === "alt" &&
                                    item[1] !== "" &&
                                    item[1] !== "0" && (
                                      <div
                                        to={"#"}
                                        className="btn btn-warning margin"
                                      >
                                        {" "}
                                        Min Yaş {item[1].trimStart(0)}
                                      </div>
                                    )}

                                  {item[0] === "ust" &&
                                    item[1] !== "" &&
                                    item[1] !== "0" && (
                                      <div
                                        to={"#"}
                                        className="btn btn-warning margin"
                                      >
                                        Max Yaş {item[1].trimStart(0)}
                                      </div>
                                    )}

                                  {item[0] !== "ust" &&
                                    item[0] !== "alt" &&
                                    item[1] &&
                                    item[1].length > 0 &&
                                    item[1].map((aitem) => (
                                      <div
                                        to={"#"}
                                        className="btn btn-warning margin"
                                      >
                                        {
                                          filtre[item[0]].filter(
                                            (a) => a.id === aitem
                                          )[0].basl
                                        }
                                      </div>
                                    ))}
                                </>
                              ))}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          {" "}
                          <div className="text-end">
                            <div className="liste-gorunum">
                              <div class="row anlikfiltre">
                                <label class="form-label "></label>
                                <div class=" form-group">
                                  {" "}
                                  <span className="m-2">Anlık Filtre</span>
                                  <span className="m-2">
                                    <input
                                      name="ucret"
                                      type="checkbox"
                                      checked={anlikfiltre}
                                      onChange={(e) => {
                                        setAnlikfiltre(e.target.checked);
                                        e.target.checked && onSubmit(true);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <Link
                                to={"#"}
                                onClick={() => setlisteS(0)}
                                className={
                                  "miniListic " + (listeS === 0 && " active")
                                }
                              >
                                <i className="fas fa-th mr-2"></i>
                              </Link>
                              <Link
                                to={"#"}
                                onClick={() => setlisteS(1)}
                                className={
                                  "miniListic " + (listeS === 1 && " active")
                                }
                              >
                                <i className="fas fa-th-list"></i>
                              </Link>
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="doktorlist">
                    <div className={"card " + (listeS === 0 && " kapali")}>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table mt-4 mb-0 table-centered table-nowrap">
                            <tbody>
                              {!loading &&
                                data.length > 0 &&
                                data.map((item) => (
                                  <tr
                                    className={!item.drDurum && "pasifcard"}
                                    title={!item.drDurum && "Hizmet vermiyor"}
                                  >
                                    <td>
                                      {" "}
                                      <div className="d-flex">
                                        <div className="flex-1 text-center">
                                          <button
                                            className="btn btn-success margin"
                                            onClick={() => {
                                              setrandevuEkran({
                                                drid: item.drId,
                                                drAd:
                                                  item.tip + " " + item.adSoyad,
                                              });
                                            }}
                                          >
                                            <i className="mdi mdi-book-plus-outline"></i>
                                          </button>

                                          <button
                                            className="btn btn-info  margin"
                                            onClick={() =>
                                              setDoktorOn(item.drId)
                                            }
                                          >
                                            <i
                                              class="fa fa-user-md"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{randevuMudehale(item.mm)}</td>
                                    <td>
                                      <Link to={"doktor-detay-" + item.drId}>
                                        {item.foto != null ? (
                                          <img
                                            className="avatar-sm rounded-circle me-2"
                                            src={
                                              "images/doktorlar/" + item.foto
                                            }
                                            alt={item.adSoyad}
                                          />
                                        ) : (
                                          <img
                                            className="avatar-sm rounded-circle me-2"
                                            src={"images/profil.png"}
                                            alt={item.adSoyad}
                                          />
                                        )}
                                      </Link>{" "}
                                      <span className="text-muted mb-0">
                                        {item.tip}
                                      </span>{" "}
                                      {item.adSoyad}
                                      <br />
                                    </td>
                                    {/* <td>
                                    {(item.altYas > 0 || item.ustYas > 0) && (
                                      <>
                                        {item.altYas > 0 && (
                                          <span>{item.altYas} Yaş Üstü</span>
                                        )}

                                        {item.altYas > 0 && item.ustYas > 0 && (
                                          <> - </>
                                        )}

                                        {item.ustYas > 0 && (
                                          <span>{item.ustYas} Yaş Altı</span>
                                        )}
                                      </>
                                    )}
                                  </td> */}
                                    {/* <td className={!sekmeler.grup && "kapali"}>
                                    <div className="text-center">
                                      {item.gurup.length > 0 &&
                                        item.gurup.map((a, index) => (
                                          <span
                                            key={index}
                                            className="ucretbtn btn btn-sm btn-warning"
                                          >
                                            {a}
                                          </span>
                                        ))}
                                    </div>
                                  </td> */}
                                    <td className={!sekmeler.bakim && "kapali"}>
                                      {item.bakim.length > 0 &&
                                        item.bakim.map((a, index) => (
                                          <button
                                            onClick={() => {
                                              setSecRT({
                                                tip: a.tip,
                                                ucret: a.ucret,
                                              });
                                              setrandevuEkran({
                                                drid: item.drId,
                                                drAd:
                                                  item.tip + " " + item.adSoyad,
                                              });
                                            }}
                                            key={index}
                                            className="ucretbtn btn btn-sm btn-info"
                                          >
                                            {a.bakimAd}
                                          </button>
                                        ))}
                                    </td>
                                    {/* <td className={!sekmeler.klinik && "kapali"}>
                                    {" "}
                                    {item.klinik.length > 0 &&
                                      item.klinik.map((a, index) => (
                                        <span
                                          key={index}
                                          className="ucretbtn btn btn-sm btn-secondary"
                                        >
                                          {a}
                                        </span>
                                      ))}
                                  </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className={"row " + (listeS === 1 && " kapali")}>
                      {!loading &&
                        data.length > 0 &&
                        data.map((item) => (
                          <div className="col-lg-4" key={item.drId}>
                            <div
                              className={
                                "card  stncard " +
                                (!item.drDurum && "pasifcard")
                              }
                              title={!item.drDurum && "Hizmet vermiyor"}
                            >
                              <div className="card-body">
                                <div className="d-flex">
                                  <Link to={"doktor-detay-" + item.drId}>
                                    {item.foto != null ? (
                                      <img
                                        className="me-3 rounded-circle avatar-md"
                                        src={"images/doktorlar/" + item.foto}
                                        alt={item.adSoyad}
                                      />
                                    ) : (
                                      <img
                                        className="me-3 rounded-circle avatar-md"
                                        src={"images/profil.png"}
                                        alt={item.adSoyad}
                                      />
                                    )}
                                  </Link>
                                  <div className="flex-1">
                                    <h5 className="font-size-16 mb-1">
                                      <span className="text-muted mb-0">
                                        {item.tip}
                                      </span>{" "}
                                      {item.adSoyad}
                                    </h5>
                                  </div>
                                </div>
                                <div
                                  className={
                                    "text-center " +
                                    (!sekmeler.bakim && "kapali")
                                  }
                                >
                                  {item.bakim.length > 0 &&
                                    item.bakim.map((a, index) => (
                                      <span
                                        key={index}
                                        className="ucretbtn btn btn-sm btn-info"
                                        onClick={() => {
                                          setSecRT({
                                            tip: a.tip,
                                            ucret: a.ucret,
                                          });
                                          setrandevuEkran({
                                            drid: item.drId,
                                            drAd: item.tip + " " + item.adSoyad,
                                          });
                                        }}
                                      >
                                        {a.bakimAd}
                                      </span>
                                    ))}
                                </div>
                                {/* <div
                                className={
                                  "text-center " + (!sekmeler.grup && "kapali")
                                }
                              >
                                {item.gurup.length > 0 &&
                                  item.gurup.map((a, index) => (
                                    <span
                                      key={index}
                                      className="ucretbtn btn btn-sm btn-warning"
                                    >
                                      {a}
                                    </span>
                                  ))}
                              </div> */}
                                {/* <div className="text-muted font-size-14 font-weight-medium font-secondary text-center">
                                {(item.altYas > 0 || item.ustYas > 0) && (
                                  <>
                                    <p>
                                      <strong>İlgilendiği Yaş</strong>{" "}
                                    </p>
                                    {item.altYas > 0 && (
                                      <span>{item.altYas} Yaş Üstü</span>
                                    )}

                                    {item.altYas > 0 && item.ustYas > 0 && (
                                      <> - </>
                                    )}

                                    {item.ustYas > 0 && (
                                      <span>{item.ustYas} Yaş Altı</span>
                                    )}
                                  </>
                                )}
                              </div> */}
                                <div className="row text-center mt-4">
                                  <div className="col-4">
                                    <Link to={"#"} className="btn">
                                      <h5 className="mb-0">{item.ilgiT}</h5>
                                      <p className="text-muted font-size-14">
                                        İlgi Alanı
                                      </p>
                                    </Link>
                                  </div>
                                  <div className="col-4">
                                    <Link to={"#"} className="btn">
                                      <h5 className="mb-0">{item.testT}</h5>
                                      <p className="text-muted font-size-14">
                                        Test
                                      </p>
                                    </Link>
                                  </div>
                                  <div className="col-4">
                                    <Link to={"#"} className="btn">
                                      <h5 className="mb-0">{item.terapiT}</h5>
                                      <p className="text-muted font-size-14">
                                        Terapi
                                      </p>
                                    </Link>
                                  </div>
                                </div>

                                {/* <div
                                className={
                                  "text-center " +
                                  (!sekmeler.klinik && "kapali")
                                }
                              >
                                {item.klinik.length > 0 &&
                                  item.klinik.map((a, index) => (
                                    <span
                                      key={index}
                                      className="ucretbtn btn btn-sm btn-secondary"
                                    >
                                      {a}
                                    </span>
                                  ))}
                              </div> */}

                                <div className="altRandBtnGurup">
                                  <hr />{" "}
                                  <div className="text-center">
                                    {randevuMudehale(item.mm)}
                                  </div>
                                  <br />
                                  <div className="d-flex">
                                    <div className="flex-1 text-center">
                                      <button
                                        className="btn btn-success"
                                        onClick={() => {
                                          setrandevuEkran({
                                            drid: item.drId,
                                            drAd: item.tip + " " + item.adSoyad,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-book-plus-outline"></i>{" "}
                                        Randevu Oluştur
                                      </button>

                                      <button
                                        className="btn btn-info  margin"
                                        onClick={() => setDoktorOn(item.drId)}
                                      >
                                        <i
                                          class="fa fa-user-md"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  {!loading && data.length === 0 && (
                    <h2 className="text-center">Doktor bulunamadı...</h2>
                  )}
                  {loading && <SnipperLoading></SnipperLoading>}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DoktorIndex;
