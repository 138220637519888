import React, { useEffect, useState } from "react";
import { getList } from "../components/axiosComp";
import ReactHtmlParser from "react-html-parser";
import SnipperLoading from "../components/snipperLoading";
import { Hata } from "../components/rolsControl";

const Index = () => {
  const [uyari, setUyari] = useState();

  const [klinik, setKlinik] = useState();

  useEffect(() => {
    getList("ubs/anaUyarilar").then((a) => {
      console.log(a.data);
      setUyari(a.data.uyari);
      setKlinik(a.data.klinik);
    }).catch((error)=> {Hata(error); console.log(error);}  );
  }, []); 
  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body">
          <h2>Merhabalar, RPT & UBS uygulamamıza hoş geldiniz.</h2>
          <p></p>

          {uyari && uyari.map((a) => <div> {ReactHtmlParser(a.metin)} </div>)}
        </div>
      </div>

      <h2>Klinikler</h2>
      <div className="card">
        <div className="card-body">
          {!klinik && <SnipperLoading />}
          {klinik && (
            <>
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Klinik</th>
                      <th>Tel </th>
                      <th>Email </th>
                      <th>Adres</th>
                      <th>İl</th>
                    </tr>
                  </thead>
                  <tbody>
                    {klinik.map((a, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.klinik.toUpperCase()}</td>
                        <td>{a.tel}</td>
                        <td>{a.email}</td>
                        <td>{a.adres}</td>
                        <td>{a.sehir}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
