import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import Ilgialanlari from "./genel/ilgialanlari";
import Terapi from "./genel/terapi";
import Testler from "./genel/testler";

import Gurup from "./ozel/gurup";
import Klinik from "./ozel/klinik";
import Bakim from "./ozel/bakim";
import Drtip from "./ozel/drtip";
import Bolumler from "./ozel/bolumler";
import Yaslar from "./ozel/yaslar";
const TanimlarRoute = () => {
  return (
    <>
      <Switch>
        <Route path="/tanimlar-genel-ilgialanlari" component={Ilgialanlari} />
        <Route path="/tanimlar-genel-terapi" component={Terapi} />
        <Route path="/tanimlar-genel-testler" component={Testler} />
        
        <Route path="/tanimlar-ozel-bakim" component={Bakim} />
        <Route path="/tanimlar-ozel-drtip" component={Drtip} />
        <Route path="/tanimlar-ozel-klinik" component={Klinik} />
        <Route path="/tanimlar-ozel-gurup" component={Gurup} />
        <Route path="/tanimlar-ozel-bolum" component={Bolumler} />
        <Route path="/tanimlar-yaslar" component={Yaslar} />
      </Switch>
    </>
  );
};

export default TanimlarRoute;
