import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getList } from "../../components/axiosComp";
import Hatamodal from "../../components/hatamodal";
import SnipperLoading from "../../components/snipperLoading";
import RolsControl, { Hata } from "../../components/rolsControl";

import { randevuMudehale } from "../../components/randevuMudehale";
import DoktorSlotvePuan from "../../components/randevular/doktorSlotvePuan";

const DoktorRandevu = () => {
  let { id } = useParams();

  const history = useHistory();
  const [doktor, setdoktor] = useState();
  const [doktorSlotvePuan, setdoktorSlotvePuan] = useState();
  const [randevuSirasi, setrandevuSirasi] = useState();

  const [refresh, setrefresh] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("/drbs/doktorDetails/" + id)
      .then((a) => {
        if (a.data) {
          setdoktor(a.data);

          getList("/drbs/doktorRandevusiraListesi/" + id)
            .then((a) => {
              setrandevuSirasi(a.data);
            })
            .catch(function (error) {
              Hata(error);
              seterrorS({
                uyari: {
                  tip: "error",
                  error: true,
                  message: error.response.data.message,
                },
              });
            });
        } else {
          history.push("doktorlarlist");
        }
      })
      .catch(function (error) {
        Hata(error);
        seterrorS({
          uyari: {
            tip: "error",
            error: true,
            message: error.response.data.message,
          },
        });
      });
  }, []);

  useEffect(() => {
    if (refresh) {
      setrandevuSirasi(refresh);
      setrefresh();
    }
  }, [refresh]);

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />

      <DoktorSlotvePuan
        drId={doktorSlotvePuan}
        setdrId={setdoktorSlotvePuan}
        icerik={randevuSirasi && randevuSirasi[0]}
        setrefresh={setrefresh}
      />

      {doktor ? (
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3">
              <div className="card">
                <div className="card-body drDetayPage">
                  <>
                    <div className="text-center altpadd">
                      <Link to={"doktor-detay-" + id} className="btn btn-info">
                        <i
                          class="fa fa-arrow-circle-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Doktor Detay
                      </Link>
                    </div>
                  </>

                  <div className="text-center">
                    {doktor.foto != null ? (
                      <img
                        className="me-3 rounded-circle avatarprofil"
                        src={"images/doktorlar/" + doktor.foto}
                        alt={doktor.adsoyad}
                      />
                    ) : (
                      <img
                        className="me-3 rounded-circle avatarprofil"
                        src={"images/profil.png"}
                        alt={doktor.doktor}
                      />
                    )}
                  </div>

                  {!doktor.drDurum && (
                    <div
                      className="text-center"
                      style={{
                        paddin: "20px auto",
                        color: "red",
                        fontSize: "25px",
                      }}
                      title="Pasif"
                    >
                      <i className="ion ion-md-warning"></i>
                    </div>
                  )}
                  <div className="text-center">
                    <h4>
                      <Link to={"#"}>
                        <span className="text-muted mb-0">{doktor.tip} </span>
                        {doktor.doktor}
                      </Link>
                    </h4>
                    <Link to={"#"}>
                      <span className="text-muted mb-0">
                        {doktor.bolum && doktor.bolum.toUpperCase()}{" "}
                      </span>
                    </Link>{" "}
                    <br />
                    Dr Kod: {doktor.drKodu}
                  </div>

                  <div>
                    <div className="drPadd">
                      <div>
                        <p>
                          <h6>Klinikler :</h6>
                          {doktor.klinikler.length > 0 ? (
                            doktor.klinikler.map((a) => (
                              <>
                                {a.klinik} <br />
                              </>
                            ))
                          ) : (
                            <>Seçili bulunmuyor...</>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="drPadd">
                      <div>
                        <p>
                          <h6>Guruplar : </h6> <br />
                          {doktor.gruplar.length > 0 ? (
                            doktor.gruplar.map((a) => (
                              <>
                                {" "}
                                {a.grupAd} <br />
                              </>
                            ))
                          ) : (
                            <>Seçili bulunmuyor...</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="text-center">
                        Aylık Randevu Slot Listesi
                        <br />
                        <div></div>
                      </h4>

                      <div>
                        <div class="table-responsive">
                          <table class="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Ay/Yıl</th>

                                <th>Puan</th>
                                <th>Randevu</th>
                                <th>Kayıtlı Randevu</th>
                                <th>Gelen Randevu</th>
                                <th>K SÜTUNA GÖRE</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {randevuSirasi ? (
                                randevuSirasi.map((a, index) => (
                                  <>
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{a.ay + "/" + a.yil}</td>

                                      <td>{a.puan}</td>
                                      <td>{a.randevu}</td>
                                      <td>{a.kayitliRandevu}</td>
                                      <td>{a.gelenRandevu}</td>
                                      <td>
                                        {randevuMudehale(parseInt(a.mudehale))}
                                      </td>

                                      <td>
                                        {new Date().getFullYear() === a.yil &&
                                        new Date().getMonth() + 1 === a.ay ? (
                                          <button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              setdoktorSlotvePuan(id)
                                            }
                                          >
                                            <i className="fa fa-edit"></i>
                                          </button>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              ) : (
                                <tr className="text-center">
                                  <td colspan="8">
                                    {" "}
                                    <SnipperLoading></SnipperLoading>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SnipperLoading></SnipperLoading>
      )}
    </>
  );
};

export default DoktorRandevu;
