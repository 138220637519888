import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";

import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";
const Insert = ({ show, setShow, setrefresh, setLoadingUst }) => {
  const handleClose = () => setShow(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "",tip:null },
  });

  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [roller, setRoller] = useState([]);
  const [formValue, setformValue] = React.useState({
    kad: "",
    kullanici: "",
    tel: "",
    email: "",
    sifre: "",
    banko: false,
    rol: "",
  });

  useEffect(() => {
    getList("/uybs/rolList/" + show)
      .then((a) => {
        setRoller(a.data);
        setbuttonOk(false);
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);
        return;
      });
  }, []);

  const onSubmit = () => {
    if (
      formValue.kad.length < 3 ||
      formValue.kullanici.length < 3 ||
      formValue.tel.length < 3 ||
      formValue.email.length < 3 ||
      formValue.sifre.length < 3
    ) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      put("/uybs/uyeEkle", form_data)
        .then((a) => {
          setrefresh(a.data);
          setShow(false);
          setbuttonOk(false);
          seterrorS({
            uyari: { error: true, message: formValue.kad + " eklendi",tip:"success" },
          });

          setformValue({
            kad: "",
            kullanici: "",
            tel: "",
            email: "",
            sifre: "",
            rol: "0",
            banko: false,
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Üye Oluştur</Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <form onSubmit={() => onSubmit()}>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Banko</label>
                <div class="col-sm-10">
                  <select
                    onChange={handleChange}
                    className="form-control"
                    defaultValue={formValue.banko}
                    name="banko"
                  >
                    <option value={false}>Hayır</option>
                    <option value={true}>Evet</option>
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Roller</label>
              
                <div class="col-sm-10">
                  <select
                    className="form-control"
                    name="rol"
                    onChange={handleChange}
                    defaultValue={formValue.rol}
                  >
                    <option value={0} key={0}>
                      -
                    </option>
                    {roller &&
                      roller.map((a, index) => (
                        <option value={a.id} key={index}>
                          {a.id} - {a.kod}-{a.rolAdi}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Kad <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="kad"
                    type="text"
                    required
                    class="form-control"
                    placeholder="Min 3 Karakter"
                    value={formValue.kad}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Kullanıcı <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="kullanici"
                    placeholder="Min 3 Karakter"
                    type="text"
                    required
                    class="form-control"
                    value={formValue.kullanici}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Tel <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="tel"
                    placeholder="Min 3 Karakter"
                    type="text"
                    required
                    class="form-control"
                    value={formValue.tel}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Email <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="email"
                    placeholder="Min 3 Karakter"
                    type="text"
                    required
                    class="form-control"
                    value={formValue.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Şifre <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="sifre"
                    placeholder="Min 3 Karakter"
                    type="text"
                    required
                    class="form-control"
                    value={formValue.sifre}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Kaydet
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </>
      </Modal>

      <SweetAlert showConfirm={false} showCancel={true} danger show={smShow} title="Uyarı"  onCancel={()=>setSmShow(false)}>
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Insert;
