import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
        
            <section className="copyright-45-sec-326">
              <Link to="https://www.supercode.com.tr" target="_blank">
                   © SuperCode çözümleriyle hazırlanmıştır. 
              </Link>
            </section>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
