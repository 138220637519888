export const getFormatDatetime = (date) => {
  try {
    const datee = new Date(date);
    return datee.toLocaleDateString();
  } catch (error) {
    return "Hatalı Tarih.";
  }
};

export const getFormatFullDatetime = (date) => {
  try {
    const datee = new Date(date);
    return datee.toLocaleString();
  } catch (error) {
    return "Hatalı Tarih.";
  }
};

