import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import SnipperLoading from "../../../components/snipperLoading";
import { getList, put } from "../../../components/axiosComp";
import Hatamodal from "../../../components/hatamodal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Hata } from "../../rolsControl";

const Insert = ({ show, setShow, setrefresh, setLoadingUst }) => {
  const handleClose = () => setShow(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "" ,tip:null},
  });

  const [smShow, setSmShow] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [formValue, setformValue] = React.useState({
    baslik: "",
    kimler: "",
    oDavNit: "",
    turu: "",
    sure: "",
    kapsam: "",
    materyal: "",
    durum: true,
  });

  const onSubmit = () => {
    if (formValue.baslik.length < 3) {
      setSmShow(true);
    } else {
      setbuttonOk(true);

      setLoadingUst(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      put("/ubs/testAdd", form_data)
        .then((a) => {
          setrefresh(a.data);
          setShow(false);
          setbuttonOk(false);
          seterrorS({
            uyari: { error: true, message: formValue.baslik + " eklendi",tip:"success" },
          });

          setformValue({
            baslik: "",
            kimler: "",
            oDavNit: "",
            turu: "",
            sure: "",
            kapsam: "",
            materyal: "",
            durum: true,
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          setLoadingUst(false);
          return;
        });
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>Test Oluştur</Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <form onSubmit={() => onSubmit()}>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Başlık <span className="req">*</span>{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    name="baslik"
                    type="text"
                    required
                    class="form-control"
                    data-parsley-minlength="3"
                    value={formValue.baslik}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Kimlere uygulanabilir
                </label>
                <div class="col-sm-10">
                  <input
                    name="kimler"
                    type="text"
                    class="form-control"
                    value={formValue.kimler}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">
                  Ölçtüğü davranış nitelik{" "}
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="oDavNit"
                    class="form-control"
                    value={formValue.oDavNit}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Türü</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="turu"
                    class="form-control"
                    value={formValue.turu}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Süre</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="sure"
                    class="form-control"
                    value={formValue.sure}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Kapsamı</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="kapsam"
                    class="form-control"
                    value={formValue.kapsam}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="mb3 row">
                <label class="form-label col-sm-2 ">Materyal</label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    name="materyal"
                    class="form-control"
                    value={formValue.materyal}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Kaydet
                </Button>
              </>
            )}
            {buttonOk && <SnipperLoading />}
          </Modal.Footer>
        </>
      </Modal>

      <SweetAlert showConfirm={false} showCancel={true} danger show={smShow} title="Uyarı"  onCancel={()=>setSmShow(false)}>
        Lütfen <span className="req">*</span> işaretli alanları doldurun.
      </SweetAlert>
    </>
  );
};

export default Insert;
