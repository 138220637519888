export function randevuMudehale(id) {
  var don =
    id >= 5 ? (
      <span className="badge bg-success font12  text-white">
        DOLU TUTULACAK
      </span>
    ) : id === 4 ? (
      <span className="badge bg-info font12 text-white">
        {" "}
        GÜNDE 5 YENİ HASTA
      </span>
    ) : id === 2 ? (
      <span className="badge bg-warning font12  text-white">
        GÜNDE 3 YENİ HASTA
      </span>
    ) : id === 1 ? (
      <span className="badge bg-danger font12 text-white">
        SADECE İSTEK VERELİM
      </span>
    ) : (
      ""
    );

  return id;
}

export const RandevuMudehaleSelect = ({ name, value, setvalue }) => {
  return (
    <>
      {/* // <select
    //   onChange={setvalue}
    //   className="form-control"
    //   defaultValue={value ? parseInt(value) : 3}
    //   name={name}
    // >
    //   <option value={3}> - </option>
    //   <option value={5}>DOLU TUTULACAK</option>
    //   <option value={4}>GÜNDE 5 YENİ HASTA</option>
    //   <option value={2}>GÜNDE 3 YENİ HASTA</option>
    //   <option value={1}> SADECE İSTEK VERELİM</option>
    // </select> */}

      <input
        type="number"
        onChange={setvalue}
        className="form-control"
        defaultValue={value ? parseInt(value) : 0}
        name={name}
      ></input> 
    </>
  );
};
