import React, { Component } from "react";

import NavMenu from "./NavMenu";

import Footer from "./footer";
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div id="layout-wrapper">
        <NavMenu />
        <div className="main-content">
          <div className="page-content"> {this.props.children}</div>
          <Footer />
        </div>
      </div>
    );
  }
}
