import React, { useState, useEffect } from "react";
import { dell, getList } from "../../components/axiosComp";
import SnipperLoading from "../../components/snipperLoading";
import Hatamodal from "../../components/hatamodal";

import { Modal, Button } from "react-bootstrap";
import MesajGunc from "./mesajGunc";
import { Hata } from "../../components/rolsControl";

const Mesajlist = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [buttonOk, setbuttonOk] = useState(false);

  const [did, setdid] = useState();
  const [dname, setdname] = useState("");
  const [showd, setShowd] = useState(false);

  const [mupdate, setmupdate] = useState();
  const [showu, setShowu] = useState(false);
  const [showuLocading, setShowuLocading] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "" ,tip:null},
  });

  function Guncelle() {
    getList("/ubs/uyariList")
      .then((a) => {
        console.clear();
        console.log(a.data);
        setData(a.data);
        setLoading(false);
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setData([]);
        setLoading(false);
        return;
      });
  }

  useEffect(() => {
    Guncelle();
  }, []);

  const handleuShow = (id) => {
    setShowu(true);
    setShowuLocading(true);

    getList("/ubs/uyariDetails/" + id)
      .then((a) => {
      
        setmupdate(a.data);
        setShowuLocading(false);
      })
    .catch(function (error) {
        Hata(error)
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });

        setShowuLocading(true);
      });
  };

  const handledShow = (id, name) => {
    setdname(name);
    setdid(id);
    setShowd(true);
  };

  const handledClose = () => {
    setShowd(false);
    setdid();
    setdname("");
  };

  //Sil komut
  const ondSubmit = () => {
    if (did) {
      console.log(did);

      setLoading(true);
      setbuttonOk(true);

      dell("/ubs/uyariDelete/" + did)
        .then((a) => {
          setLoading(false);
          setbuttonOk(false);
          setShowd(false);
          setData(a.data);

          seterrorS({
            uyari: { error: true, message: "Mesaj bilgisi silindi." ,tip: "success" },
          });
        })
      .catch(function (error) {
        Hata(error)
          seterrorS({
            uyari: { tip:"error",error: true, message: error.response.data.message },
          });

          setShowd(false);
          setLoading(false);
          setbuttonOk(false);

          return;
        });
    } else {
      setLoading(false);
      setbuttonOk(false);
      setShowd(false);
      seterrorS({
        uyari: {
          error: true,
          message: "Geçici bir hata oluştu.Tekrar deneyin.",
        },
      });
    }
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div class="container-fluid">
        <div className="card">
          <div className="card-body">
            <h1>Mesaj Listesi </h1>
            <p>Uyarılar, genel metin ve popup yönetim bölümü</p>

            {loading && <SnipperLoading />}

            {!loading && (
              <>
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <tbody>
                      {data.length > 0 &&
                        data.map((a, index) => (
                          <tr key={index}>
                            <td>
                              {a.tip}
                              <p class="m-0 text-muted">Yayını</p>
                            </td>
                            <td>{a.baslik}</td>

                            <td>
                              {a.basTarihi}
                              <p class="m-0 text-muted">Başlangıç</p>
                            </td>
                            <td>
                              {a.bitisTarihi}
                              <p class="m-0 text-muted">Bitiş</p>
                            </td>

                            <td>
                              {a.durum ? (
                                <i className="fa fa-check check"></i>
                              ) : (
                                <i className="fa fa-times times"></i>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() => handleuShow(a.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>{" "}
                              <button
                                className="btn btn-danger  btn-sm"
                                onClick={() => handledShow(a.id, a.baslik)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Güncelle Modal */}
      <>
        {mupdate && (
          <Modal
            size="lg"
            show={showu}
            onHide={() => {
              setShowu(false);
              Guncelle();
            }}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Mesaj Güncelle
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!showuLocading && <MesajGunc gelen={mupdate} />}
              {showuLocading && <SnipperLoading />}
            </Modal.Body>
          </Modal>
        )}
      </>

      {/* Sil Modal */}
      <>
        {did && (
          <Modal
            size="sm"
            show={showd}
            onHide={() => setShowd(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Mesaj Sil
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{dname}' silinsin mi?</Modal.Body>{" "}
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button variant="secondary" onClick={handledClose}>
                    Vazgeç
                  </Button>
                  <Button variant="danger" onClick={() => ondSubmit()}>
                    Sil
                  </Button>
                </>
              )}
              {buttonOk && <SnipperLoading />}
            </Modal.Footer>
          </Modal>
        )}
      </>
    </>
  );
};

export default Mesajlist;
